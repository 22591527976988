import { PreflightProvider } from "@mabi-ui/preflight";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { UserApi } from "certiblok-api-manager";
import { useSetAtom } from "jotai";
import { lazy, useCallback, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import NotFound from "./pages/404/NotFound";
import Dashboard from "./pages/Dashboard/Dashboard";
import EditRoomFolderHistoryContextProvider from "./pages/EditRooms/pages/EditRoom/components/EditRoomFolderHistoryContextProvider";
import FolderHistoryContextProvider from "./pages/Folders/components/FolderNavigationContextProvider";
import Login from "./pages/Login/Login";
import LoginInsertTwoFactor from "./pages/Login/pages/LoginInsertTwoFactor/LoginInsertTwoFactor";
import { theme } from "./theme";
import AuthScaffold from "./utils/components/AuthScaffold";
import ErrorBoundary from "./utils/components/ErrorBoundary";
import { ModalNavigatorProvider } from "./utils/components/ModalNavigator/ModalNavigator";
import NoAuthScaffold from "./utils/components/NoAuthScaffold/NoAuthScaffold";
import { CertiblokMLDialogProvider } from "./utils/components/poppers";
import { ABACRoute } from "./utils/components/routes/AbacRoute";
import SuspenseWrapper from "./utils/components/routes/components/SuspenseWrapper";
import Scaffold from "./utils/components/scaffold/Scaffold";
import SentryUserLogger from "./utils/components/SentryUserLogger";
import { useApi, useFetch } from "./utils/hooks/api";
import { AbilityAtomStorage, useAbility } from "./utils/hooks/useAbilityRules";
import useLanguageSwitcher from "./utils/hooks/useLanguageSwitcher";
import { UserContext } from "./utils/hooks/useMe";
import useSocket from "./utils/hooks/useSocket";
import { queryClient } from "./utils/reactQueryClient";
import "./utils/string.extensions";

import Invitation from "./pages/Invitation";
import PublicDocument from "./pages/QrCode/pages/PublicDocument";
import { lazyRetry } from "./utils/functions/lazyRetry";
import { useFeatureFlags } from "./utils/hooks/useFeatureFlags";
import { AuthOverrider } from "./utils/hooks/useOverrideAuth";
import Cookies from "js-cookie";

const ValidatingQrCode = lazy(() => import("./pages/QrCode/pages/ValidatingQrCode"));
const AccessError = lazy(() => import("./pages/QrCode/pages/AccessError"));

const AuditRoomsArchive = lazy(() =>
	lazyRetry(() => import("./pages/AuditRooms/pages/AuditRoomsArchive/AuditRoomsArchive"))
);
const EditCreateEvent = lazy(() => lazyRetry(() => import("./pages/Calendar/pages/EditCreateEvent/index")));
const CreateAuditRoom = lazy(() => lazyRetry(() => import("./pages/AuditRooms/pages/Create/CreateAuditRoom")));
const Favorites = lazy(() => lazyRetry(() => import("./pages/Favorites/Favorites")));
const History = lazy(() => lazyRetry(() => import("./pages/AuditRooms/pages/History/History")));
const Trashbin = lazy(() => lazyRetry(() => import("./pages/Trashbin/Trashbin")));
const OpenedAuditRooms = lazy(() =>
	lazyRetry(() => import("./pages/AuditRooms/pages/OpenedAuditRooms/OpenedAuditRooms"))
);
const SingleAuditRoom = lazy(() => lazyRetry(() => import("./pages/AuditRooms/pages/SingleAuditRoom/SingleAuditRoom")));
const Calendar = lazy(() => lazyRetry(() => import("./pages/Calendar/Calendar")));
const Contacts = lazy(() => lazyRetry(() => import("./pages/Contacts/Contacts")));
const SingleContact = lazy(() => lazyRetry(() => import("./pages/Contacts/pages/SingleContact")));
const Faq = lazy(() => lazyRetry(() => import("./pages/Faq/Faq")));
const Folders = lazy(() => lazyRetry(() => import("./pages/Folders/Folders")));
const SingleFolder = lazy(() => lazyRetry(() => import("./pages/Folders/pages/SingleFolder/SingleFolder")));
const ForgotPassword = lazy(() => lazyRetry(() => import("./pages/ForgotPassword/ForgotPassword")));
const Icons = lazy(() => lazyRetry(() => import("./pages/Icons/Icons")));
const Inbox = lazy(() => lazyRetry(() => import("./pages/Inbox/Inbox")));
const DocumentRequest = lazy(() => lazyRetry(() => import("./pages/DocumentRequest/DocumentRequest")));
const Profile = lazy(() => lazyRetry(() => import("./pages/Profile/pages/Profile")));
const Plans = lazy(() => lazyRetry(() => import("./pages/Profile/pages/Plans/pages/PlansPage/PlansPage")));
const AllPlans = lazy(() => lazyRetry(() => import("./pages/Profile/pages/Plans/pages/AllPlansPage/AllPlansPage")));
const Addons = lazy(() => lazyRetry(() => import("./pages/Profile/pages/Plans/pages/AddonsPage/AddonsPage")));
const Security = lazy(() => lazyRetry(() => import("./pages/Profile/pages/Security/Security")));
const Settings = lazy(() => lazyRetry(() => import("./pages/Profile/pages/Settings/Settings")));
const Team = lazy(() => lazyRetry(() => import("./pages/Profile/pages/Team/Team")));
const Update = lazy(() => lazyRetry(() => import("./pages/Profile/pages/Update/Update")));
const PickNewAdmin = lazy(() => lazyRetry(() => import("./pages/Profile/pages/UpdateAdmin/components/PickNewAdmin")));
const UpdateAdminSuccess = lazy(() => lazyRetry(() => import("./pages/Profile/pages/UpdateAdmin/components/Success")));
const UpdateAdmin = lazy(() => lazyRetry(() => import("./pages/Profile/pages/UpdateAdmin/UpdateAdmin")));
const DeletedProfileSuccessfully = lazy(() =>
	lazyRetry(() => import("./pages/Profile/pages/DeletedProfileSuccess/DeletedProfileSuccess"))
);
const CompanyPick = lazy(() => import("./pages/Register/company/CompanyPick"));
const AddCompany = lazy(() => import("./pages/Register/company/new/AddCompany"));
const RegisterSuccess = lazy(() => import("./pages/Register/components/Success"));
const UserSignUpForm = lazy(() => import("./pages/Register/components/UserSignUpForm"));
const AlreadyInUse = lazy(() => import("./pages/Register/AlreadyInUse"));
const Register = lazy(() => import("./pages/Register/Register"));
const EnableTwoFactor = lazy(() => import("./pages/Register/enable-two-factor/EnableTwoFactor"));
const Requests = lazy(() => import("./pages/Requests/Requests"));
const ResetPassword = lazy(() => import("./pages/ResetPassword/ResetPassword"));
const Search = lazy(() => import("./pages/Search/Search"));
const SharedDocuments = lazy(() => import("./pages/AllDocuments/AllDocuments"));
const SingleDocument = lazy(() => import("./pages/SingleDocument/SingleDocument"));
const FullScreenDxfPreview = lazy(() => lazyRetry(() => import("./pages/SingleDocument/pages/FullScreenDxfPreview")));
const FullScreenStlPreview = lazy(() => lazyRetry(() => import("./pages/SingleDocument/pages/FullScreenStlPreview")));
const TestPage = lazy(() => import("./pages/Test/TestPage"));
const PaymentSuccess = lazy(() => import("./pages/Profile/pages/UpdatePlan/pages/PaymentSuccess"));
const PaymentFailed = lazy(() => import("./pages/Profile/pages/UpdatePlan/pages/PaymentFailed"));
const UpdatePlan = lazy(() => import("./pages/Profile/pages/UpdatePlan/UpdatePlan"));
const VerifyEmail = lazy(() => import("./pages/Account/VerifyEmail/VerifyEmail"));
const ExpiredLink = lazy(() => import("./pages/Account/ExpiredLink/ExpiredLink"));
const InvalidQrCode = lazy(() => import("./pages/InvalidQrCode"));
const TwoFAConfigurationScaffold = lazy(
	() => import("./pages/Profile/pages/Security/pages/TwoFAConfiguration/TwoFAConfigurationScaffold")
);
const TwoFAInstallApp = lazy(() =>
	lazyRetry(
		() => import("./pages/Profile/pages/Security/pages/TwoFAConfiguration/pages/TwoFAInstallApp/TwoFAInstallApp")
	)
);
const ScanQrCode = lazy(() =>
	lazyRetry(() => import("./pages/Profile/pages/Security/pages/TwoFAConfiguration/pages/ScanQrCode/ScanQrCode"))
);
const InsertVerificationCode = lazy(() =>
	lazyRetry(
		() =>
			import("./pages/Profile/pages/Security/pages/TwoFAConfiguration/pages/InsertVerificationCode/InsertVerificationCode")
	)
);
const TwoFactorEnabled = lazy(() =>
	lazyRetry(
		() => import("./pages/Profile/pages/Security/pages/TwoFAConfiguration/pages/TwoFactorEnabled/TwoFactorEnabled")
	)
);
const EditRooms = lazy(() => import("./pages/EditRooms/pages/EditRooms/EditRooms"));
const EditRoom = lazy(() => import("./pages/EditRooms/pages/EditRoom"));
const AcceptInvitation = lazy(() => import("./pages/Invitation/pages/AcceptInvitation"));
const RejectInvitation = lazy(() => import("./pages/Invitation/pages/RejectInvitation"));
const WithdrawnInvitation = lazy(() => import("./pages/Invitation/pages/WithdrawnInvitation"));
const ExpiredInvitation = lazy(() => import("./pages/Invitation/pages/ExpiredInvitation"));
const RejectInvitationSuccess = lazy(
	() => import("./pages/Invitation/pages/RejectInvitation/pages/RejectInvitationSuccess")
);
const RegisterFromInvitation = lazy(
	() => import("./pages/Invitation/pages/AcceptInvitation/pages/RegisterFromInvitation")
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
	useLanguageSwitcher();

	const userApi = useApi(UserApi);
	const setAbility = useSetAtom(AbilityAtomStorage);

	const { ability } = useAbility();

	const { isFeatureEnabled } = useFeatureFlags();
	const isEditRoomEnabled = isFeatureEnabled("editRoom");

	const isAuthenticated = ability.can("readAll", "User");

	const { data: me, revalidate, loading } = useFetch(userApi.getMe, { skip: !isAuthenticated });

	useEffect(() => {
		if (me) {
			generateNewAbilities();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const generateNewAbilities = useCallback(() => {
		userApi
			.invalidCurrentAbilities()
			.then((resp) => {
				localStorage.clear();
				setAbility(resp.data?.abilityRules || []);
				//After we invalidate the current abilities the auth override token gets invalidated and we need to remove it in order to use the usual cookie based auth
				//After invalidating abilities a new CertiblokAccessToken cookie gets set so we are good to go
				Cookies.remove("CertiblokAuthOverride", { domain: "mabiloft.com" });
			})
			.catch((err) => console.log("NEW ABILITIES", err));
	}, [setAbility, userApi]);

	useSocket("invitationChanged", () => generateNewAbilities());
	useSocket("updateAbilities", () => generateNewAbilities());

	useSocket("logout", () => {
		userApi.logout().then(() => {
			localStorage.clear();
			window.location.reload();
		});
	});

	return (
		<ErrorBoundary>
			<PreflightProvider>
				<UserContext.Provider value={{ user: me, reload: revalidate, loading }}>
					<LocalizationProvider dateAdapter={AdapterLuxon}>
						<ThemeProvider theme={theme}>
							<BrowserRouter>
								<QueryClientProvider client={queryClient}>
									<ModalNavigatorProvider>
										<CertiblokMLDialogProvider>
											<AuthOverrider onAuthOverride={generateNewAbilities} />
											<SentryRoutes>
												<Route
													path="test"
													element={
														<SuspenseWrapper>
															<TestPage />
														</SuspenseWrapper>
													}
												/>
												<Route
													path="/"
													element={
														<ABACRoute redirectTo="/login" allowedIf={{ can: "readOne", model: "Company" }}>
															<Scaffold />
														</ABACRoute>
													}
												>
													<Route
														index
														element={
															<>
																<Dashboard />
															</>
														}
													/>
													<Route path="folders" element={<FolderHistoryContextProvider />}>
														<Route
															index
															element={
																<SuspenseWrapper>
																	<Folders />
																</SuspenseWrapper>
															}
														/>
														<Route path=":id">
															<Route
																index
																element={
																	<SuspenseWrapper>
																		<SingleFolder />
																	</SuspenseWrapper>
																}
															/>
															<Route
																path="document/:id"
																element={
																	<SuspenseWrapper>
																		<SingleDocument />
																	</SuspenseWrapper>
																}
															/>
														</Route>
													</Route>
													<Route
														path="favorites"
														element={
															<SuspenseWrapper>
																<Favorites />
															</SuspenseWrapper>
														}
													/>
													<Route path="all_documents">
														<Route
															index
															element={
																<SuspenseWrapper>
																	<SharedDocuments />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="document/:id"
															element={
																<ABACRoute allowedIf={{ can: "create", model: "Folder" }}>
																	<SuspenseWrapper>
																		<SingleDocument />
																	</SuspenseWrapper>
																</ABACRoute>
															}
														/>
													</Route>
													<Route path="inbox">
														<Route
															index
															element={
																<SuspenseWrapper>
																	<Inbox />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="document/:id"
															element={
																<SuspenseWrapper>
																	<SingleDocument />
																</SuspenseWrapper>
															}
														/>
													</Route>
													<Route path="calendar">
														<Route
															index
															element={
																<SuspenseWrapper>
																	<Calendar />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="event/create"
															element={
																<SuspenseWrapper>
																	<EditCreateEvent />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="event/:eventId"
															element={
																<SuspenseWrapper>
																	<EditCreateEvent />
																</SuspenseWrapper>
															}
														/>
													</Route>
													<Route
														path="faq"
														element={
															<SuspenseWrapper>
																<Faq />
															</SuspenseWrapper>
														}
													/>
													<Route path="audit_rooms">
														<Route
															index
															element={
																<SuspenseWrapper>
																	<OpenedAuditRooms />
																</SuspenseWrapper>
															}
														/>
														<Route path=":auditRoomId">
															<Route
																index
																element={
																	<SuspenseWrapper>
																		<SingleAuditRoom />
																	</SuspenseWrapper>
																}
															/>
															<Route
																path="history"
																element={
																	<SuspenseWrapper>
																		<History />
																	</SuspenseWrapper>
																}
															/>
															<Route
																path="document/:id"
																element={
																	<SuspenseWrapper>
																		<SingleDocument />
																	</SuspenseWrapper>
																}
															/>
														</Route>
														<Route path="archive">
															<Route
																index
																element={
																	<SuspenseWrapper>
																		<AuditRoomsArchive />
																	</SuspenseWrapper>
																}
															/>
															<Route path=":auditRoomId">
																<Route
																	index
																	element={
																		<SuspenseWrapper>
																			<SingleAuditRoom />
																		</SuspenseWrapper>
																	}
																/>
																<Route
																	path="history"
																	element={
																		<SuspenseWrapper>
																			<History />
																		</SuspenseWrapper>
																	}
																/>
																<Route
																	path="document/:id"
																	element={
																		<SuspenseWrapper>
																			<SingleDocument />
																		</SuspenseWrapper>
																	}
																/>
															</Route>
														</Route>
														<Route
															path="create"
															element={
																<ABACRoute allowedIf={{ can: "create", model: "Audit" }}>
																	<SuspenseWrapper>
																		<CreateAuditRoom />
																	</SuspenseWrapper>
																</ABACRoute>
															}
														/>
													</Route>
													{isEditRoomEnabled && (
														<Route path="edit_rooms">
															<Route
																index
																element={
																	<SuspenseWrapper>
																		<EditRooms />
																	</SuspenseWrapper>
																}
															/>
															<Route path=":editRoomId" element={<EditRoomFolderHistoryContextProvider />}>
																<Route
																	index
																	element={
																		<SuspenseWrapper>
																			<EditRoom />
																		</SuspenseWrapper>
																	}
																/>
																<Route
																	path="document/:id"
																	element={
																		<SuspenseWrapper>
																			<SingleDocument />
																		</SuspenseWrapper>
																	}
																/>
															</Route>
														</Route>
													)}
													<Route path="profile">
														<Route
															index
															element={
																<SuspenseWrapper>
																	<Profile />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="security"
															element={
																<SuspenseWrapper>
																	<Security />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="update"
															element={
																<SuspenseWrapper>
																	<Update />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="team"
															element={
																<ABACRoute allowedIf={{ can: "update", model: "Company" }}>
																	<SuspenseWrapper>
																		<Team />
																	</SuspenseWrapper>
																</ABACRoute>
															}
														/>
														<Route
															path="settings"
															element={
																<SuspenseWrapper>
																	<Settings />
																</SuspenseWrapper>
															}
														/>
														<Route path="plans">
															<Route
																index
																element={
																	<SuspenseWrapper>
																		<Plans />
																	</SuspenseWrapper>
																}
															/>
															<Route
																path="all"
																element={
																	<SuspenseWrapper>
																		<AllPlans />
																	</SuspenseWrapper>
																}
															/>
															<Route
																path="addons"
																element={
																	<SuspenseWrapper>
																		<Addons />
																	</SuspenseWrapper>
																}
															/>
														</Route>
													</Route>
													<Route path="requests">
														<Route
															index
															element={
																<SuspenseWrapper>
																	<Requests />
																</SuspenseWrapper>
															}
														/>
														<Route
															path=":requestId"
															element={
																<SuspenseWrapper>
																	<DocumentRequest />
																</SuspenseWrapper>
															}
														/>
													</Route>
													<Route path="contacts">
														<Route
															index
															element={
																<SuspenseWrapper>
																	<Contacts />
																</SuspenseWrapper>
															}
														/>
														<Route
															path=":id"
															element={
																<SuspenseWrapper>
																	<SingleContact />
																</SuspenseWrapper>
															}
														/>
													</Route>
													<Route
														path="search"
														element={
															<SuspenseWrapper>
																<Search />
															</SuspenseWrapper>
														}
													/>
													<Route
														path="bin"
														element={
															<SuspenseWrapper>
																<Trashbin />
															</SuspenseWrapper>
														}
													/>
												</Route>
												<Route
													path="/profile/admin/update"
													element={
														<SuspenseWrapper>
															<UpdateAdmin />
														</SuspenseWrapper>
													}
												>
													<Route
														index
														element={
															<SuspenseWrapper>
																<ABACRoute allowedIf={{ can: "update", model: "Company" }}>
																	<PickNewAdmin />
																</ABACRoute>
															</SuspenseWrapper>
														}
													/>
													<Route
														path="success"
														element={
															<SuspenseWrapper>
																<UpdateAdminSuccess />
															</SuspenseWrapper>
														}
													/>
												</Route>
												<Route
													path="profile/security/two-factor"
													element={
														<SuspenseWrapper>
															<ABACRoute allowedIf={{ can: "readOne", model: "User" }}>
																<TwoFAConfigurationScaffold />
															</ABACRoute>
														</SuspenseWrapper>
													}
												>
													<Route
														index
														element={
															<SuspenseWrapper>
																<TwoFAInstallApp />
															</SuspenseWrapper>
														}
													/>
													<Route
														path="qr-code"
														element={
															<SuspenseWrapper>
																<ScanQrCode />
															</SuspenseWrapper>
														}
													/>
													<Route
														path="insert-verification-code"
														element={
															<SuspenseWrapper>
																<InsertVerificationCode />
															</SuspenseWrapper>
														}
													/>
													<Route
														path="success"
														element={
															<SuspenseWrapper>
																<TwoFactorEnabled />
															</SuspenseWrapper>
														}
													/>
												</Route>
												<Route
													path="/profile/plan/update/:planId"
													element={
														<SuspenseWrapper>
															<ABACRoute allowedIf={{ can: "update", model: "Company" }}>
																<UpdatePlan />
															</ABACRoute>
														</SuspenseWrapper>
													}
												/>
												<Route
													path="/profile/plan/payment-success"
													element={
														<SuspenseWrapper>
															<PaymentSuccess />
														</SuspenseWrapper>
													}
												/>
												<Route
													path="/profile/plan/payment-failed"
													element={
														<SuspenseWrapper>
															<PaymentFailed />
														</SuspenseWrapper>
													}
												/>
												<Route
													path="/profile/deleted"
													element={
														<SuspenseWrapper>
															<DeletedProfileSuccessfully />
														</SuspenseWrapper>
													}
												/>

												<Route
													path="qr/public/:documentId"
													element={
														<SuspenseWrapper>
															<PublicDocument />
														</SuspenseWrapper>
													}
												/>
												<Route element={<AuthScaffold />}>
													<Route path="/login" element={<Login />} />
													<Route path="/login/two-fa" element={<LoginInsertTwoFactor />} />
													<Route
														path="qr/:qrCode"
														element={
															<SuspenseWrapper>
																<ValidatingQrCode />
															</SuspenseWrapper>
														}
													/>
													<Route
														path="qr/access-error"
														element={
															<SuspenseWrapper>
																<AccessError />
															</SuspenseWrapper>
														}
													/>

													<Route
														path="/register"
														element={
															<SuspenseWrapper>
																<Register />
															</SuspenseWrapper>
														}
													>
														<Route
															index
															element={
																<SuspenseWrapper>
																	<UserSignUpForm />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="enable-two-factor"
															element={
																<SuspenseWrapper>
																	<EnableTwoFactor />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="already-in-use"
															element={
																<SuspenseWrapper>
																	<AlreadyInUse />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="company"
															element={
																<SuspenseWrapper>
																	<ABACRoute allowedIf={{ can: "readAll", model: "Company" }}>
																		<CompanyPick />
																	</ABACRoute>
																</SuspenseWrapper>
															}
														/>
														<Route
															path="company/new"
															element={
																<SuspenseWrapper>
																	<AddCompany />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="success"
															element={
																<SuspenseWrapper>
																	<ABACRoute allowedIf={{ can: "readAll", model: "Company" }} children={<RegisterSuccess />} />
																</SuspenseWrapper>
															}
														/>
													</Route>
													<Route
														path="/forgot"
														element={
															<SuspenseWrapper>
																<ForgotPassword />
															</SuspenseWrapper>
														}
													/>
													<Route path="/account">
														<Route
															path="verify-email"
															element={
																<SuspenseWrapper>
																	<VerifyEmail />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="expired-link"
															element={
																<SuspenseWrapper>
																	<ExpiredLink />
																</SuspenseWrapper>
															}
														/>
													</Route>
													<Route
														path="/access/:userId/reset-password"
														element={
															<SuspenseWrapper>
																<ResetPassword />
															</SuspenseWrapper>
														}
													/>
													<Route
														path="invalid-qr-code"
														element={
															<SuspenseWrapper>
																<InvalidQrCode />
															</SuspenseWrapper>
														}
													/>
													<Route path="/invitation" element={<Invitation />}>
														<Route
															index
															element={
																<SuspenseWrapper>
																	<AcceptInvitation />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="register"
															element={
																<SuspenseWrapper>
																	<RegisterFromInvitation />
																</SuspenseWrapper>
															}
														/>
														<Route path="reject">
															<Route
																index
																element={
																	<SuspenseWrapper>
																		<RejectInvitation />
																	</SuspenseWrapper>
																}
															/>
															<Route
																path="success"
																element={
																	<SuspenseWrapper>
																		<RejectInvitationSuccess />
																	</SuspenseWrapper>
																}
															/>
														</Route>
														<Route
															path="withdrawn"
															element={
																<SuspenseWrapper>
																	<WithdrawnInvitation />
																</SuspenseWrapper>
															}
														/>
														<Route
															path="expired"
															element={
																<SuspenseWrapper>
																	<ExpiredInvitation />
																</SuspenseWrapper>
															}
														/>
													</Route>
													<Route path="*" element={<NotFound />} />
												</Route>
												<Route path="/documents/requests/:requestId" element={<NoAuthScaffold />}>
													<Route
														index
														element={
															<SuspenseWrapper>
																<DocumentRequest />
															</SuspenseWrapper>
														}
													/>
												</Route>
												<Route
													path="/dxf-preview"
													element={
														<SuspenseWrapper>
															<FullScreenDxfPreview />
														</SuspenseWrapper>
													}
												/>
												<Route
													path="/stl-preview"
													element={
														<SuspenseWrapper>
															<FullScreenStlPreview />
														</SuspenseWrapper>
													}
												/>
												<Route
													path="/icons"
													element={
														<SuspenseWrapper>
															<Icons />
														</SuspenseWrapper>
													}
												/>
											</SentryRoutes>
										</CertiblokMLDialogProvider>
									</ModalNavigatorProvider>
									<SentryUserLogger />
									<ReactQueryDevtools initialIsOpen={false} />
								</QueryClientProvider>
							</BrowserRouter>
						</ThemeProvider>
					</LocalizationProvider>
				</UserContext.Provider>
			</PreflightProvider>
		</ErrorBoundary>
	);
}

export default Sentry.withProfiler(App);
