import { alpha, Box, IconButton, Stack, SxProps, Theme, Typography } from "@mui/material";
import { Notification } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { CSSProperties, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../icons/CertiblokIcon";
import { getNotificationTitle } from "../../../functions/getNotificationTitle";
import { NotificationActions } from "./NotificationAction";
import { StartIcon } from "./NotificationStartIcon";

export type GeneralNotificationProps = {
	notification: Notification;
};

type RowProps = {
	notification: Notification;
	markAsRead: () => void;
	style?: CSSProperties;
	sx?: SxProps<Theme>;
	containerSx?: SxProps<Theme>;
	withCloseButton?: boolean;
	closeButtonAction?: () => void;
};

const NotificationRow = forwardRef<any, RowProps>(
	({ notification, markAsRead, sx, containerSx, withCloseButton, closeButtonAction, ...props }, ref) => {
		const { t } = useTranslation();
		const createdAt = DateTime.fromISO(notification.createdAt);
		const diffNow = createdAt.diffNow(["hours", "minutes"]);

		return (
			<Box sx={{ width: "100%", my: 1, ...containerSx }} ref={ref} {...props}>
				<Stack
					{...(notification.type === "update_document_request" && {
						onClick: markAsRead,
					})}
					component={notification.type === "update_document_request" ? "a" : "div"}
					href={
						notification.type === "update_document_request" ? notification?.href?.replace("?update=true", "") : undefined
					}
					direction="row"
					gap={4}
					sx={{
						textDecoration: "unset",
						color: "black",
						width: "100%",
						backgroundColor: notification.read ? "surface.main" : "background.default",
						padding: 3,
						borderRadius: 3,
						boxSizing: "border-box",
						border: notification.read ? "1px solid rgba(0, 0, 0, 0.12)" : "unset",
						...sx,
					}}
				>
					<StartIcon notification={notification} />
					<Box sx={{ flexGrow: 1, minWidth: 0 }}>
						<Typography
							variant="body2"
							dangerouslySetInnerHTML={{ __html: getNotificationTitle(notification, t) }}
							sx={{
								wordBreak: "break-all",
								"& b": {
									fontWeight: 700,
								},
							}}
						/>
						<Typography
							children={
								createdAt.startOf("day").equals(DateTime.now().startOf("day"))
									? Math.abs(diffNow.hours) === 0
										? `${Math.round(Math.abs(diffNow.minutes))} min`
										: `${Math.round(Math.abs(diffNow.hours))} ${
												Math.round(Math.abs(diffNow.minutes)) > 1 ? t("global.hours") : t("global.hour")
										  } ${t("global.and")} ${Math.round(Math.abs(diffNow.minutes))} min`
									: createdAt.toFormat("dd/MM/yyyy")
							}
							variant="caption"
							component="p"
							sx={{ paddingTop: 4, opacity: 0.38 }}
						/>
					</Box>
					<Stack alignItems={"flex-end"} spacing={2} justifyContent="space-between" sx={{ width: 76, flexShrink: 0 }}>
						{withCloseButton && (
							<IconButton
								size="small"
								onClick={closeButtonAction}
								sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.12) }}
							>
								<CertiblokIcon name="close" size={20} color="#000000BD" />
							</IconButton>
						)}
						<Box sx={{ width: "100%" }} className="flex flex-col gap-1">
							<NotificationActions notification={notification} markAsRead={markAsRead} />
						</Box>
					</Stack>
				</Stack>
			</Box>
		);
	}
);

export default NotificationRow;
