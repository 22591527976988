import {
	alpha,
	Box,
	Collapse,
	Fade,
	Step,
	StepButton,
	stepConnectorClasses,
	StepLabel,
	stepLabelClasses,
	Stepper,
	Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useBetterMediaQuery } from "../../../hooks/useBetterMediaQuery";
import CertiblokIcon from "../../icons/CertiblokIcon";
import { availableUploadDocumentSteps, uploadDocumentStepAtom } from "../atoms/uploadDocumentAtoms";
import { useFilesToUploadCount } from "../stores/filesToUploadStore";

const UploadDocumentHeader = memo(() => {
	const { t } = useTranslation();

	const [selectedStep, setSelectedStep] = useAtom(uploadDocumentStepAtom);

	const activeStep = availableUploadDocumentSteps.findIndex((elem) => elem === selectedStep);

	const switchLabelTitle = (label: (typeof availableUploadDocumentSteps)[number]) => {
		switch (label) {
			case "chooseFolder":
				return t("global.selectFolder");
			case "uploadDocuments":
				return t("global.uploadDocuments");
			case "recap":
				return t("global.assignDocuments");
		}
	};

	const isMd = useBetterMediaQuery("md");

	const filesToUploadCount = useFilesToUploadCount();

	return (
		<Stepper
			nonLinear
			activeStep={activeStep}
			sx={{
				[`& .${stepConnectorClasses.line}`]: {
					transition: (theme) => theme.transitions.create("border-color"),
				},
				[`& .${stepConnectorClasses.active}`]: {
					[`& .${stepConnectorClasses.line}`]: {
						borderColor: "success.main",
					},
				},
			}}
		>
			{availableUploadDocumentSteps.map((label, index) => {
				// if (isEditRoom && label === "recap") {
				// 	return null;
				// }

				return (
					<Step key={label} completed={index < activeStep} sx={{ flexShrink: 0 }}>
						<StepButton
							disableRipple
							color="inherit"
							disabled={index > activeStep || filesToUploadCount > 0}
							onClick={() => {
								setSelectedStep(label);
							}}
							sx={{
								p: { xs: 2, md: 4 },
								margin: 0,
								left: 0,
								boxSizing: "border-box",
								borderRadius: 3,
								transition: (theme) => theme.transitions.create("background-color"),
								"&:focus-visible": {
									backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.12),
								},
							}}
						>
							<StepLabel
								sx={{
									[`& .${stepLabelClasses.iconContainer}`]: {
										paddingRight: 0,
									},
								}}
								StepIconComponent={({ active, completed, error }) => {
									return (
										<Box
											className="w-6 h-6 md:w-8 md:h-8 rounded-full box-border relative flex items-center justify-center flex-shrink-0"
											sx={{
												borderWidth: 1,
												borderColor: active ? "transparent" : completed ? "success.main" : alpha("#000000", 0.38),
												backgroundColor: active ? "blue.main" : "transparent",
												borderStyle: "solid",
												transition: (theme) => theme.transitions.create(["background-color", "border-color"]),
											}}
										>
											<Fade in={!completed}>
												<Typography
													children={index + 1}
													variant="subtitle1"
													sx={{
														position: "absolute",
														color: activeStep === index ? "white" : alpha("#000000", 0.38),
														transition: (theme) => theme.transitions.create(["color"]),
													}}
												/>
											</Fade>
											<Fade in={completed}>
												<CertiblokIcon size={isMd ? 24 : 18} color="success" name={"success"} />
											</Fade>
										</Box>
									);
								}}
							>
								<Collapse appear={false} orientation="horizontal" in={isMd || activeStep === index}>
									<Typography
										children={switchLabelTitle(label)}
										variant="subtitle1"
										className="pl-2 whitespace-nowrap md:whitespace-normal"
									/>
								</Collapse>
							</StepLabel>
						</StepButton>
					</Step>
				);
			})}
		</Stepper>
	);
});
export default UploadDocumentHeader;
