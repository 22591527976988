import { alpha, Tooltip, Typography, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import { forwardRef, memo } from "react";
import { useTranslation } from "react-i18next";
import { useBetterMediaQuery } from "../../../../hooks/useBetterMediaQuery";
import CertiblokIcon from "../../../icons/CertiblokIcon";
import { selectedFolderAtom } from "../../atoms/uploadDocumentAtoms";
import { useUploadDocumentContext } from "../../contexts/UploadDocumentContext";
import { useEditRoomFolderDetails, useFolderDetails } from "../../queries/uploadDocumentQueries";
import { useUploadedFileIds } from "../../stores/uploadedFilesStore";
import UploadedDocumentDetails from "./components/UploadedDocumentDetails";

type UploadedDocumentRecapProps = {};

const UploadedDocumentRecap = forwardRef<any, UploadedDocumentRecapProps>(({ ...props }, ref) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isMd = useBetterMediaQuery("md");
	const selectedFolderId = useAtomValue(selectedFolderAtom);

	const { type } = useUploadDocumentContext();
	const isEditRoom = type === "uploadEditRoomDocument";

	const { data: folder } = useFolderDetails(selectedFolderId, isEditRoom);
	const { data: editRoomFolder } = useEditRoomFolderDetails(selectedFolderId, isEditRoom);

	const uploadFolder = isEditRoom ? editRoomFolder : folder;

	const uploadedDocumentIds = useUploadedFileIds();

	return (
		<div className="px-5 md:px-12" {...props} ref={ref}>
			<div className="grid grid-cols-1 sm:![grid-template-columns:auto_1fr] md:![grid-template-columns:repeat(2,auto)_minmax(20%,min-content)] lg:![grid-template-columns:repeat(2,auto)_min-content] gap-3 items-center">
				<div className="items-center flex gap-3">
					<CertiblokIcon
						size={isMd ? 24 : 20}
						color="primary"
						name={"success"}
						iconCss={{
							backgroundColor: alpha(theme.palette.success.main, 0.12),
							borderRadius: "50%",
							...(isMd
								? {
										padding: 12,
								  }
								: { padding: 2 }),
						}}
					/>
					<Typography
						children={t("uploadDocument.uploadedDocuments")}
						sx={{
							typography: {
								xs: "subtitle2",
								md: "subtitle1",
							},
						}}
					/>
				</div>
				<Typography
					children={t("uploadDocument.assignedToFolder")}
					className="sm:text-end"
					sx={{
						typography: {
							xs: "subtitle2",
							md: "subtitle1",
						},
					}}
				/>
				<div className="sm:col-span-2 md:col-auto">
					<Tooltip title={uploadFolder?.data?.name ?? ""}>
						<div
							className="flex items-center md:max-lg:w-full lg:max-w-xs w-fit max-w-full gap-2 rounded-full px-2 py-1 box-border min-h-[32px]"
							style={{
								backgroundColor: alpha(uploadFolder?.data?.color ?? "rgba(0,0,0,0)", 0.12),
							}}
						>
							<CertiblokIcon size={20} color={uploadFolder?.data?.color} name={"folder_bold"} />
							<Typography
								children={uploadFolder?.data?.name}
								noWrap
								sx={{
									opacity: 0.6,
									textAlign: "start",
									typography: {
										xs: "label",
										md: "body2",
									},
								}}
							/>
						</div>
					</Tooltip>
				</div>
			</div>
			<div className="pt-6 flex flex-col gap-2 box-border w-full">
				{uploadedDocumentIds.map((id) => (
					<UploadedDocumentDetails key={id} documentId={id} />
				))}
			</div>
		</div>
	);
});
export default memo(UploadedDocumentRecap);
