/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import { GlobalCalendarContext, useGlobalCalendarContext } from "../globalCalendar/controllers/GlobalCalendarContext";
import AppBar from "./AppBar";
import Body from "./Body";
import SendRequestModal from "./components/SendRequestModal";
import SendVideoConferenceModal from "./components/SendVideoConferenceModal";
import { ScaffoldContext, useScaffoldContext } from "./controllers/ScaffoldContext";
import Drawer from "./components/Drawer";

type Props = {};

const Scaffold: React.FC<Props> = () => {
	const scaffoldContext = useScaffoldContext();
	const globalCalendarContext = useGlobalCalendarContext();
	return (
		<GlobalCalendarContext.Provider value={globalCalendarContext}>
			<ScaffoldContext.Provider value={scaffoldContext}>
				<Box
					sx={{
						backgroundColor: "background.default",
						overflow: "hidden",
						width: "100%",
						height: "100%",
						position: "relative",
					}}
				>
					<Drawer />
					<AppBar />
					<Body />
					<SendRequestModal
						open={scaffoldContext.sendRequestModalOpen}
						toggleOpen={scaffoldContext.toggleSendRequestModalOpen}
					/>
					<SendVideoConferenceModal
						open={scaffoldContext.sendVideoConferenceModalOpen}
						toggleOpen={scaffoldContext.toggleSendVideoConferenceModalOpen}
					/>
				</Box>
			</ScaffoldContext.Provider>
		</GlobalCalendarContext.Provider>
	);
};

export default Scaffold;
