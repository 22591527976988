import { Box, ButtonBase, Skeleton, Tooltip, Typography } from "@mui/material";
import { CalendarEvent } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AvailableFolderColors } from "../../../../pages/Folders/constants/AvailableFolderColors";
import { useModalNavigator } from "../../ModalNavigator/ModalNavigator";
import CertiblokIcon, { CertiblokIconNames } from "../../icons/CertiblokIcon";
import { GlobalCalendarContext } from "../controllers/GlobalCalendarContext";
import { AuditRoomDetailsModal } from "./EventsDetailsModals/AuditRoomDetailsModal";
import { DocumentDetailsModal } from "./EventsDetailsModals/DocumentDetailsModal";
import { EventDetailsModal } from "./EventsDetailsModals/EventDetailsModal";
import { checkAllDayLongEvent } from "./EventsDetailsModals/functions/checkAllDayLongEvent";

type Props = {
	event: CalendarEvent;
	disabled?: boolean;
	showDocumentExpireDate?: boolean;
	isLast?: boolean;
};

const SingleEvent: React.FC<Props> = ({ event, disabled = false, showDocumentExpireDate = false, isLast = false }) => {
	const { t } = useTranslation();
	const { setCalendarOpen, revalidateEvents } = useContext(GlobalCalendarContext);
	const { pushModal } = useModalNavigator();

	const isLongEvent = event.typeOfEvent === "event" && (event.daysAmount ?? 0) > 0;
	const isAllDayLong = checkAllDayLongEvent(event.startTime, event.endTime, event.daysAmount);

	const formattedStartEndDate = `${DateTime.fromISO(event?.startDate || "").toFormat("dd.MM.yyyy")} - ${DateTime.fromISO(
		event.endDate
	).toFormat("dd.MM.yyyy")}`;
	const formattedStartEndTime = isAllDayLong ? t("events.isAllDay") : `${event.startTime} - ${event.endTime}`;

	const chipData = useMemo(() => {
		let icon: CertiblokIconNames = "clock_outline_02";
		let formattedValue = "";

		if (event.typeOfEvent === "document") {
			icon = "comunication_outline_01";
			formattedValue = t("singleEvent.documentExpiration");
		} else if (event.typeOfEvent === "audit_room" || isLongEvent) {
			icon = "calendar_outline";
			formattedValue = formattedStartEndDate;
		} else if (event.startTime && event.endTime) {
			formattedValue = formattedStartEndTime;
		}

		return { icon, formattedValue };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [event]);

	return (
		<ButtonBase
			onClick={() => {
				pushModal(
					event.typeOfEvent === "event"
						? EventDetailsModal
						: event.typeOfEvent === "audit_room"
						? AuditRoomDetailsModal
						: DocumentDetailsModal,
					{
						eventId: event.elementId,
						...(event.typeOfEvent === "event" && {
							onDelete: () => {
								revalidateEvents();
							},
						}),
						...(event.typeOfEvent !== "event" && { onFullDetailClick: () => setCalendarOpen(false) }),
					}
				);
			}}
			disableRipple
			sx={{ textDecoration: "none", color: "unset", width: "100%" }}
		>
			<Box
				sx={{
					width: "100%",
					marginBottom: isLast ? 0 : 3,
					borderRadius: 3,
					overflow: "hidden",
					opacity: disabled ? 0.38 : 1,
				}}
			>
				<Tooltip title={event.name} enterDelay={500}>
					<Box
						sx={{
							width: "100%",
							padding: 2,
							boxSizing: "border-box",
							height: 40,
							backgroundColor: event.color,
							display: "flex",
							gap: 2,
							alignItems: "center",
						}}
					>
						<CertiblokIcon
							size={24}
							color="white"
							name={
								event.typeOfEvent === "audit_room"
									? "profile_bold_01"
									: event.typeOfEvent === "document"
									? "paper_bold_02"
									: "calendar_bold"
							}
						/>
						<Typography children={event.name} variant="subtitle1" noWrap sx={{ color: "white" }} />
					</Box>
				</Tooltip>
				<Box
					sx={{
						width: "100%",
						padding: 2,
						boxSizing: "border-box",
						// height: event.isDocument && showDocumentExpireDate ? 72 : 40,
						backgroundColor: "white",
						display: "flex",
						flexDirection: "column",
						gap: 2,
						justifyContent: "center",
						color: "rgba(0, 0, 0, 0.6)",
					}}
				>
					{event.typeOfEvent !== "audit_room" && !isLongEvent && showDocumentExpireDate && (
						<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
							<CertiblokIcon size={24} color="inherit" name={"calendar_outline"} />
							<Typography children={`${DateTime.fromISO(event.endDate).toFormat("dd.MM.yyyy")}`} variant="body2" />
						</Box>
					)}

					<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
						<CertiblokIcon size={24} color="inherit" name={chipData.icon} />
						<Typography children={chipData.formattedValue} variant="body2" />
					</Box>
				</Box>
			</Box>
		</ButtonBase>
	);
};

export const SingleEventLoading = () => {
	return (
		<Box
			sx={{
				width: "100%",
				marginBottom: 3,
				borderRadius: 3,
				overflow: "hidden",
			}}
		>
			<Box
				sx={{
					width: "100%",
					padding: 2,
					boxSizing: "border-box",
					height: 40,
					backgroundColor: AvailableFolderColors[0],
					display: "flex",
					gap: 2,
					alignItems: "center",
				}}
			>
				<Skeleton variant="circular" width={24} height={24} />
				<Skeleton variant="text" width={200} />
			</Box>

			<Box
				sx={{
					width: "100%",
					padding: 2,
					boxSizing: "border-box",
					height: 40,
					backgroundColor: "white",
					display: "flex",
					flexDirection: "column",
					gap: 2,
					justifyContent: "center",
					color: "rgba(0, 0, 0, 0.6)",
				}}
			>
				<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
					<Skeleton variant="circular" width={24} height={24} />
					<Skeleton variant="text" width={200} />
				</Box>
			</Box>
		</Box>
	);
};

export default SingleEvent;
