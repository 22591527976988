"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./api/admin-user-api"), exports);
__exportStar(require("./api/audit-api"), exports);
__exportStar(require("./api/audit-document-review-api"), exports);
__exportStar(require("./api/auditor-api"), exports);
__exportStar(require("./api/auth-api"), exports);
__exportStar(require("./api/calendar-api"), exports);
__exportStar(require("./api/calender-api"), exports);
__exportStar(require("./api/company-api"), exports);
__exportStar(require("./api/contact-api"), exports);
__exportStar(require("./api/document-api"), exports);
__exportStar(require("./api/document-request-api"), exports);
__exportStar(require("./api/documentmanager-api"), exports);
__exportStar(require("./api/drive-api"), exports);
__exportStar(require("./api/dropbox-api"), exports);
__exportStar(require("./api/edit-room-api"), exports);
__exportStar(require("./api/event-api"), exports);
__exportStar(require("./api/expansions-api"), exports);
__exportStar(require("./api/favorite-api"), exports);
__exportStar(require("./api/folder-api"), exports);
__exportStar(require("./api/health-check-api"), exports);
__exportStar(require("./api/invitation-api"), exports);
__exportStar(require("./api/models-api"), exports);
__exportStar(require("./api/notification-api"), exports);
__exportStar(require("./api/onedrive-api"), exports);
__exportStar(require("./api/plan-api"), exports);
__exportStar(require("./api/qrcode-api"), exports);
__exportStar(require("./api/search-api"), exports);
__exportStar(require("./api/sharenote-api"), exports);
__exportStar(require("./api/sharing-group-api"), exports);
__exportStar(require("./api/tags-api"), exports);
__exportStar(require("./api/teams-api"), exports);
__exportStar(require("./api/token-api"), exports);
__exportStar(require("./api/trash-api"), exports);
__exportStar(require("./api/user-api"), exports);
__exportStar(require("./api/video-call-api"), exports);
__exportStar(require("./api/weavy-api"), exports);
