import { Notification, NotificationApi } from "certiblok-api-manager";
import React, { useCallback, useState } from "react";
import { apiErrorParser, useApi, useFetch } from "../../../hooks/api";
import { useAbility } from "../../../hooks/useAbilityRules";
import { useModal } from "../../../hooks/useModal";
import useSocket from "../../../hooks/useSocket";
import MLDialog from "../../poppers";
import { defaultQueryFilter, transformToStringFrom } from "../../tables/AdminTable";
import { useShowNotification } from "../components/notifications/hooks/useShowNotification";

export const useScaffoldContext = () => {
	//* APIS
	const notificationApi = useApi(NotificationApi);
	const { showNotification } = useShowNotification();

	const [open, setOpen] = useState(() => typeof window !== "undefined" && window.innerWidth >= 900);
	// const [open, setOpen] = useState(false);
	const { open: sendRequestModalOpen, toggleOpen: toggleSendRequestModalOpen } = useModal();
	const { open: sendVideoConferenceModalOpen, toggleOpen: toggleSendVideoConferenceModalOpen } = useModal();

	const { ability } = useAbility();

	const {
		headers,
		loading: loadingTotalCount,
		revalidate: revalidateNotificationTotalCount,
	} = useFetch(
		notificationApi.listNotifications,
		transformToStringFrom({ ...defaultQueryFilter, perPage: 1, filter: [{ read: { $ne: true } }] }),
		{ skip: ability.cannot("readAll", "Company") }
	);

	const notificationCount = parseInt(headers?.count || "0");

	const markNotificationAsRead = useCallback(
		(notificationId: string) => {
			return notificationApi.readSingleNotification(notificationId).then(() => {
				revalidateNotificationTotalCount();
			});
		},
		[notificationApi, revalidateNotificationTotalCount]
	);

	const notificationCallback = useCallback(
		(notification: Notification) => {
			showNotification(notification, () => markNotificationAsRead(notification.id));

			revalidateNotificationTotalCount();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[showNotification]
	);

	useSocket("newNotification", notificationCallback);

	const markAllNotificationAsRead = useCallback(() => {
		return notificationApi
			.readAllNotifications()
			.then(() => {
				revalidateNotificationTotalCount();
			})
			.catch((err) => {
				MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
			});
	}, [notificationApi, revalidateNotificationTotalCount]);

	return {
		open,
		setOpen,
		sendRequestModalOpen,
		toggleSendRequestModalOpen,
		sendVideoConferenceModalOpen,
		toggleSendVideoConferenceModalOpen,
		notificationCount,
		loadingTotalCount,
		revalidateNotificationTotalCount,
		markNotificationAsRead,
		markAllNotificationAsRead,
	};
};

type ScaffoldContextType = ReturnType<typeof useScaffoldContext>;

export const ScaffoldContext = React.createContext<ScaffoldContextType>({} as ScaffoldContextType);
