import { Box, IconButton, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import CertiblokTextInput from "../../../utils/components/CertiblokTextInput";
import { pxToRem } from "../../../utils/functions/theme";
import type { CertiblokTextInputProps } from "../../../utils/interfaces/CertiblokTextInputProps";
import type { TextFieldStatus } from "../../../utils/interfaces/TextFieldStatus";
import { AvailableFolderColors } from "../constants/AvailableFolderColors";

type Props = {
	folderName: string;
	updateFolderName: (value: string) => void;
	selectedColor: string;
	updateColor: (value: string) => void;
	textInputProps?: CertiblokTextInputProps;
	ColorPickerProps?: { className?: string };
	description?: string;
	isAuditRoom?: boolean;
};

const FolderOrAuditRoomNamePicker: React.FC<Props> = ({
	folderName,
	updateFolderName,
	selectedColor,
	updateColor,
	textInputProps = {},
	description,
	isAuditRoom = false,
	ColorPickerProps,
}) => {
	const theme = useTheme();
	const [folderNameStatus, setFolderNameStatus] = useState<{ status: TextFieldStatus; message?: string | undefined }>({
		status: undefined,
		message: undefined,
	});

	const { t } = useTranslation();

	return (
		<Fragment>
			<CertiblokTextInput
				autoFocus
				value={folderName}
				onChange={(event) => {
					updateFolderName(event.target.value);
				}}
				status={folderNameStatus.status}
				helperText={folderNameStatus.message}
				onFocus={() => {
					setFolderNameStatus({ status: undefined, message: undefined });
				}}
				onBlur={() => {
					if (folderName.length === 0) {
						setFolderNameStatus({
							status: "error",
							message: isAuditRoom ? t("auditRooms.insertName") : t("folders.insertName"),
						});
					}
				}}
				label={isAuditRoom ? t("auditRooms.name") : t("folders.name")}
				fullWidth
				color="secondary"
				sx={{ backgroundColor: "white" }}
				{...textInputProps}
			/>
			{description && (
				<Typography
					children={description}
					variant="caption"
					component={"p"}
					sx={{
						fontSize: pxToRem(10),
						lineHeight: "12px",
						letterSpacing: 0.75,
						opacity: 0.38,
						marginTop: 7,
					}}
				/>
			)}
			<Typography component="p" children={t("global.color")} variant="caption" sx={{ opacity: 0.54, paddingTop: 3 }} />
			<Box
				sx={{ width: "100%", display: "flex", overflowX: "auto" }}
				className={clsx("horizontal-scroll", ColorPickerProps?.className)}
			>
				{AvailableFolderColors.map((elem, index) => {
					return (
						<IconButton
							key={index}
							onClick={() => {
								updateColor(elem);
							}}
							sx={{
								color: elem,
								width: 48,
								height: 48,
							}}
						>
							<Box
								sx={{
									width: 24,
									height: 24,
									borderRadius: 4,
									backgroundColor: elem,
									borderWidth: elem === selectedColor ? 4 : 2,
									borderStyle: "solid",
									borderColor: elem === selectedColor ? theme.palette.secondary.main : "#FFFFFF",
									transition: theme.transitions.create(["border-width", "border-color"]),
								}}
							></Box>
						</IconButton>
					);
				})}
			</Box>
		</Fragment>
	);
};

export default FolderOrAuditRoomNamePicker;
