import { Avatar, Box, SxProps, Theme, Typography } from "@mui/material";
import { backendMediaUrlParser } from "..";

type Props = {
	name: string;
	surname?: string;
	profileUrl?: string;
	size?: "small" | "medium";
	variant?:
		| "h1"
		| "h2"
		| "h3"
		| "h4"
		| "h5"
		| "h6"
		| "subtitle1"
		| "subtitle2"
		| "body1"
		| "body2"
		| "caption"
		| "button"
		| "overline"
		| "link"
		| "label"
		| "inherit"
		| "display1"
		| "display2"
		| undefined;
	typographySx?: SxProps<Theme> | undefined;
	sx?: SxProps<Theme> | undefined;
};

const ContactWithName: React.FC<Props> = ({
	name,
	surname = "",
	profileUrl,
	size = "small",
	variant = "caption",
	typographySx = {},
	sx = {},
}) => {
	return (
		<Box sx={{ display: "flex", gap: 2, alignItems: "center", ...sx }}>
			<Avatar
				sx={{
					width: size === "small" ? 20 : 24,
					height: size === "small" ? 20 : 24,
					typography: "caption",
					color: "white",
					backgroundColor: "orange.main",
				}}
				src={backendMediaUrlParser(profileUrl) ?? undefined}
				children={profileUrl === undefined ? name.charAt(0).toUpperCase() + surname?.charAt(0).toUpperCase() : undefined}
			/>
			<Typography children={`${name} ${surname}`} variant={variant} sx={{ opacity: 0.6, ...typographySx }} />
		</Box>
	);
};

export default ContactWithName;
