import { alpha, Box, Button, Fade, IconButton, LinearProgress, Typography, useTheme } from "@mui/material";
import { DocumentApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import { SwitchTransition } from "react-transition-group";
import { apiErrorParser, instanceApi } from "../../../hooks/api";
import { useBetterMediaQuery } from "../../../hooks/useBetterMediaQuery";
import LoadingIconButton from "../../buttons/LoadingIconButton";
import CertiblokIcon from "../../icons/CertiblokIcon";
import FileIcon from "../../icons/FileIcon";
import MLDialog from "../../poppers";
import { useFileToUploadById, useRemoveFileToUpload } from "../stores/filesToUploadStore";
import { useRemoveUploadedFile } from "../stores/uploadedFilesStore";

type DocumentToUploadProps = {
	fileId: string;
};
const DocumentToUpload: React.FC<DocumentToUploadProps> = ({ fileId }) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");

	const removeFile = useRemoveFileToUpload();
	const removeUploadedFile = useRemoveUploadedFile();

	const file = useFileToUploadById(fileId);

	if (!file) return null;

	return (
		<div
			className="grid grid-cols-2 gap-2 w-full p-1 md:p-2 box-border min-h-[42px] md:min-h-[52px] rounded-xl mb-2 items-center"
			style={{
				border: "1px solid rgba(0, 0, 0, 0.12)",
				borderColor: file.uploaded
					? theme.palette.success.main
					: file.error
					? theme.palette.error.main
					: file.loading.isLoading
					? "transparent"
					: "rgba(0, 0, 0, 0.12)",
				backgroundColor:
					file.error || file.uploaded || !file.loading.isLoading ? "transparent" : alpha(theme.palette.secondary.main, 0.06),
			}}
		>
			<Button
				href={file.dataUrl}
				component="a"
				target="_blank"
				rel="noopener noreferrer"
				color={file.uploaded ? "success" : "secondary"}
				disabled={file.loading.isLoading || file.error !== undefined}
				fullWidth
				sx={{
					width: "100%",
					boxSizing: "border-box",
					flexShrink: 0,
					padding: 1,
					justifyContent: "flex-start",
					"&.Mui-disabled": {
						opacity: 1,
					},
				}}
			>
				<FileIcon file={file.file} dataUrl={file.dataUrl} />
				<Typography
					children={file?.file?.name}
					noWrap
					sx={{
						typography: {
							xs: "label",
							md: "body1",
						},
						color: "black",
						textAlign: "start",
					}}
				/>
			</Button>

			<div className="w-full flex items-center justify-between gap-2">
				<SwitchTransition>
					<Fade key={file.loading.isLoading ? "loading" : file.error ? "error" : file.uploaded ? "uploaded" : "idle"}>
						{file.loading.isLoading ? (
							<div className="flex flex-grow items-center gap-3">
								<Box sx={{ flexGrow: 1 }}>
									{isMd && (
										<LinearProgress
											variant="determinate"
											color="secondary"
											value={file.loading.percentage ?? 0}
											sx={{ backgroundColor: alpha("#000000", 0.06), height: 2 }}
										/>
									)}
								</Box>

								<Box sx={{ minWidth: 35 }}>
									<Typography
										variant="body2"
										color="text.secondary"
										sx={{
											typography: {
												xs: "label",
												md: "body1",
											},
										}}
									>
										{t("uploadDocument.percentageCompleted", { percentage: Math.round(file.loading.percentage ?? 0) })}
									</Typography>
								</Box>
							</div>
						) : file.error ? (
							<div className="flex items-center gap-2">
								<CertiblokIcon
									size={isMd ? 24 : 16}
									color="error"
									name={"comunication_outline_03"}
									iconCss={{ backgroundColor: alpha(theme.palette.error.main, 0.06), padding: 6, borderRadius: 18 }}
								/>
								<Typography
									children={isMd ? t("uploadDocument.uploadingError") : t("global.error")}
									sx={{
										typography: {
											xs: "label",
											md: "body2",
										},
										color: (theme) => `${theme.palette.error.main} !important}`,
									}}
								/>
							</div>
						) : file.uploaded ? (
							<div className="flex flex-1 min-w-0 box-border items-center bg-success-a06 h-[24px] md:h-[36px] rounded-lg md:rounded-3xl p-[6px] max-w-[110px] md:max-w-[218px] gap-1 ">
								<CertiblokIcon size={16} color="success" name={"success"} />
								<Typography
									children={isMd ? t("uploadDocument.uploadCompleted") : t("global.completed")}
									noWrap
									sx={{
										color: (theme) => `${theme.palette.success.main} !important`,
										typography: {
											xs: "label",
											md: "body2",
										},
									}}
								/>
							</div>
						) : (
							<div />
						)}
					</Fade>
				</SwitchTransition>
				<SwitchTransition>
					<Fade
						key={
							file.uploaded || !(file.loading.isLoading || (file.error && isMd))
								? "icon"
								: file.error && isMd
								? "remove"
								: "cancel"
						}
					>
						{!(file.loading.isLoading || (file.error && isMd)) ? (
							<IconButton
								color={file.uploaded ? "success" : file.error ? "error" : "secondary"}
								onClick={() => {
									if (file.uploaded) {
										MLDialog.showModal(t("global.attention"), t("uploadDocument.deleteUploadedDocument"), {
											negativeText: t("global.cancel"),
											positiveText: t("global.delete"),
											negativeButton: (
												<Button
													color="primary"
													size="large"
													onClick={() => {
														MLDialog.hideModal();
													}}
												>
													{t("global.cancel")}
												</Button>
											),
											onPositiveClick: async () => {
												try {
													if (file.documentId) {
														await instanceApi(DocumentApi).deleteOneOrMoreDocuments({
															toDeleteIds: [file.documentId],
														});
														removeUploadedFile(file.documentId);
														removeFile(fileId);
													}
												} catch (e) {
													MLDialog.showSnackbar(apiErrorParser(e), { variant: "error" });
												}
											},
											positiveButton: (onClick, loading) => (
												<LoadingIconButton
													loading={loading}
													color="error"
													size="large"
													variant="contained"
													onClick={() => {
														onClick();
													}}
													icon={<CertiblokIcon size={24} color="inherit" name={"delete_bold"} />}
												>
													{t("global.delete")}
												</LoadingIconButton>
											),
											contentCentered: true,
											icon: (
												<CertiblokIcon
													size={48}
													color="orange"
													name={"comunication_outline_01"}
													iconCss={{ padding: 12, backgroundColor: alpha(theme.palette.orange.main, 0.12), borderRadius: 36 }}
												/>
											),
										});
									} else {
										removeFile(fileId);
									}
								}}
								className={"z-10 flex-shrink-0"}
							>
								<CertiblokIcon size={24} color="inherit" name={file.error ? "delete_bold" : "delete_outline"} />
							</IconButton>
						) : file.error && isMd ? (
							<Button
								color="error"
								variant="outlined"
								size="small"
								onClick={(event) => {
									event.stopPropagation();
									event.preventDefault();
									removeFile(fileId);
								}}
								sx={{ flexShrink: 0 }}
							>
								<CertiblokIcon size={24} color="error" name={"delete_bold"} />
								{t("global.remove")}
							</Button>
						) : (
							<Button
								color="secondary"
								onClick={() => {
									file.cancelToken?.cancel("canceled");
								}}
								variant={file.sendingChunks ? "outlined" : "text"}
								disabled={!file.sendingChunks}
								size="small"
								sx={{ minWidth: { xs: 0, md: 95 }, opacity: "1 !important" }}
							>
								{file.sendingChunks ? (
									<>
										{isMd && <CertiblokIcon size={24} color="inherit" name={"close"} />}
										{t("global.cancel")}
									</>
								) : (
									<CertiblokIcon size={24} color="secondary" name={"spinner"} />
								)}
							</Button>
						)}
					</Fade>
				</SwitchTransition>
			</div>
		</div>
	);
};
export default DocumentToUpload;
