import { useCallback, useMemo } from "react";

type FeatureName = "editRoom" | "qrCode" | "guestInvites";

type FeatureAbility = {
	[feature in FeatureName]: boolean;
};

export const useFeatureFlags = () => {
	const featuresAbilitation: FeatureAbility = useMemo(() => ({ editRoom: false, qrCode: true, guestInvites: true }), []);

	const isFeatureEnabled = useCallback(
		(feature: FeatureName) => {
			return featuresAbilitation[feature];
		},
		[featuresAbilitation]
	);

	return { featuresAbilitation, isFeatureEnabled };
};
