/** @jsxImportSource @emotion/react */
import { Box, Button, alpha, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LogoutModalWithNiceModal } from "../../../../../pages/Profile/components/LogoutModal";
import { LG_DRAWER_CLOSED_WIDTH, LG_DRAWER_OPEN_WIDTH } from "../../../../constants/DrawerConstants";
import { useModalNavigator } from "../../../ModalNavigator/ModalNavigator";
import StyledDrawer from "../../../StyledDrawer";
import { default as CertiblokIcon, CertiblokIconNames } from "../../../icons/CertiblokIcon";
import { ScaffoldContext } from "../../controllers/ScaffoldContext";
import { CollapsableImage } from "../CollapsableImage";
import CollapsableButton from "./components/CollapsableButton";
import { DrawerUserCard } from "./components/DrawerUserCard";
import { RouteButton } from "./components/RouteButton";
import SideBarUsage from "./components/SidebarUsage";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";

export type RouteInfos = {
	title: string;
	iconName?: CertiblokIconNames;
} & ({ subroutes: RouteInfos[]; path?: never } | { path: string; subroutes?: never });

const Drawer = () => {
	const { t } = useTranslation();
	const { open, setOpen, toggleSendVideoConferenceModalOpen } = useContext(ScaffoldContext);
	const { pushModal } = useModalNavigator();
	const location = useLocation();
	const theme = useTheme();

	const { isFeatureEnabled } = useFeatureFlags();
	const isEditRoomEnabled = isFeatureEnabled("editRoom");

	const availableSections: RouteInfos[] = [
		{
			iconName: "home_outline",
			title: t("scaffold.dashboard"),
			path: "/",
		},
		{
			iconName: "folder_outline",
			title: t("folders.myFolders"),
			path: "/folders",
		},
		{
			title: t("global.documents"),
			iconName: "paper_outline_02",
			subroutes: [
				{
					title: t("dashboard.inboxDocuments"),
					path: "/inbox",
				},
				{
					title: t("global.allDocuments"),
					path: "/all_documents",
				},
				{
					title: t("favorites.favorites"),
					path: "/favorites",
				},
				{
					title: t("scaffold.requests"),
					path: "/requests",
				},
			],
		},
		{
			title: t("documents.auditRoom"),
			iconName: "profile_outline_01",
			subroutes: [
				{
					title: t("auditRooms.auditRoomList"),
					path: "/audit_rooms",
				},
				{
					title: t("auditRooms.archive"),
					path: "/audit_rooms/archive",
				},
			],
		},
		...(isEditRoomEnabled
			? [
					{
						title: "Edit Room",
						path: "/edit_rooms",
						iconName: "edit-room_outline" as CertiblokIconNames,
					},
			  ]
			: []),
		{
			iconName: "call_outline_03",
			title: t("contacts.list"),
			path: "/contacts",
		},
		{
			iconName: "delete_outline",
			title: t("scaffold.bin"),
			path: "/bin",
		},
	];

	const isMd = useMediaQuery(theme.breakpoints.up("md"));
	const isXl = useMediaQuery(theme.breakpoints.up("xl"));

	const [expandedIndex, setExpandedIndex] = useState<number | undefined>(undefined);

	const closeDrawer = () => {
		if (!isXl) {
			setOpen(false);
			setExpandedIndex(undefined);
		}
	};

	const drawerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (open) drawerRef.current?.firstElementChild?.scrollTo({ top: 0 });
	}, [open]);

	return (
		<StyledDrawer
			ref={drawerRef}
			open={open}
			variant="permanent"
			anchor="left"
			sx={{
				width: { xs: "100vw", md: open ? LG_DRAWER_OPEN_WIDTH : LG_DRAWER_CLOSED_WIDTH },
				"& .MuiPaper-root": {
					zIndex: 1100,
					height: "100%",
					padding: 4,
					boxSizing: "border-box",
					py: 3,
					gap: { xs: 3, md: 4 },
					width: {
						xs: "100vw",
						md: open ? LG_DRAWER_OPEN_WIDTH : LG_DRAWER_CLOSED_WIDTH,
					},
				},
			}}
		>
			<Box
				sx={{
					boxSizing: "border-box",
					display: "flex",
					flexDirection: "column",
					alignItems: "start",
					gap: { xs: 5, lg: 9 },
					paddingX: 2,
				}}
			>
				<Box sx={{ height: 48, visibility: { xs: "hidden", md: "unset" }, paddingLeft: 1 }}>
					<CollapsableImage open={isMd ? open : true} />
				</Box>
			</Box>

			<div className="overflow-y-scroll overflow-x-visible flex-1 flex flex-col justify-between gap-3">
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						overflowX: "visible",
						flexGrow: 1,
						flexShrink: 1,
						alignItems: "center",
						gap: 1,
						"@media (min-height: 800px)": {
							gap: 3,
						},
					}}
				>
					{availableSections.map((section, i) => (
						<RouteButton
							key={section.title}
							expanded={i === expandedIndex}
							onExpand={() => (expandedIndex === i ? setExpandedIndex(undefined) : setExpandedIndex(i))}
							onClick={() => {
								closeDrawer();
							}}
							open={isMd ? open : true}
							selected={
								section?.subroutes
									? section.subroutes.map((route) => route?.path).some((v) => v && location.pathname.includes(v))
									: section.path === "/"
									? location.pathname === section.path
									: location.pathname.includes(section.path)
							}
							{...section}
						/>
					))}

					<CollapsableButton
						open={isMd ? open : true}
						icon={<CertiblokIcon size={24} color="white" name={"video_outline"} />}
						children={t("variousComponents.videoConference")}
						variant="contained"
						{...((open || !isMd) && { fullWidth: true })}
						sx={{
							gap: 2,
							height: 40,
							flexShrink: 0,
							marginTop: 8,
						}}
						size="medium"
						onClick={() => {
							toggleSendVideoConferenceModalOpen();
						}}
					/>
					<SideBarUsage open={isMd ? open : true} />
				</Box>
				<DrawerUserCard onClick={closeDrawer} />
				{!isMd && (
					<Button
						className="mt-2"
						fullWidth
						variant="text"
						sx={{
							backgroundColor: alpha(theme.palette.primary.main, 0.12),
						}}
						onClick={() => {
							pushModal(LogoutModalWithNiceModal);
						}}
					>
						Logout
						<CertiblokIcon name="log_outline_01" color="inherit" />
					</Button>
				)}
			</div>
		</StyledDrawer>
	);
};

export default Drawer;
