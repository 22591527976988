import { useMutation, useQuery } from "@tanstack/react-query";
import { DocumentApi, EditRoomApi, FolderApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import { isUserOrContact } from "../../../../pages/Folders/components/UploadDocument/functions/isUser";
import { chunkRequests } from "../../../functions/chunkRequests";
import { apiErrorParser, instanceApi } from "../../../hooks/api";
import MLDialog from "../../poppers";
import { useUploadDocumentContext } from "../contexts/UploadDocumentContext";
import { getAllUploadedFiles } from "../stores/uploadedFilesStore";

export const uploadDocumentQueryKeys = {
	folderDetails: (folderId: string) => ["folder", folderId],
	editRoomFolderDetails: (folderId: string) => ["editRoomFolder", folderId],
};

export function useFolderDetails(folderId: string | undefined, isEditRoom: boolean = false) {
	return useQuery({
		queryKey: uploadDocumentQueryKeys.folderDetails(folderId ?? ""),
		queryFn: () => {
			return instanceApi(FolderApi).getPlainFolder(folderId ?? "");
		},
		enabled: !!folderId && !isEditRoom,
	});
}

export function useEditRoomFolderDetails(folderId: string | undefined, isEditRoom: boolean = false) {
	return useQuery({
		queryKey: uploadDocumentQueryKeys.editRoomFolderDetails(folderId ?? ""),
		queryFn: () => {
			return instanceApi(EditRoomApi).getPlainEditRoomFolder(folderId ?? "");
		},
		enabled: !!folderId && isEditRoom,
	});
}

export function useSaveUploadedDocumentEdits() {
	const { t } = useTranslation();

	const { type } = useUploadDocumentContext();
	const isEditRoom = type === "uploadEditRoomDocument";

	return useMutation({
		mutationFn: async (params: {}) => {
			const allUploadedFiles = getAllUploadedFiles();
			await chunkRequests(
				10,
				(file) => {
					const promises = [];

					promises.push(
						isEditRoom
							? instanceApi(EditRoomApi)
									.updateEditRoomDocument(file.document.id, {
										noExpiration: file.draft.expirationDate === null,
										...(file.draft.expirationDate && { expireDate: file.draft.expirationDate }),
										name: file.draft.documentName,
									})
									.catch((e) => {
										MLDialog.showSnackbar(apiErrorParser(e), { variant: "error" });
									})
							: instanceApi(DocumentApi)
									.editDocument(file.document.id, {
										noExpiration: file.draft.expirationDate === null,
										...(file.draft.expirationDate && { expireDate: file.draft.expirationDate }),
										name: file.draft.documentName,
									})
									.catch((e) => {
										MLDialog.showSnackbar(apiErrorParser(e), { variant: "error" });
									})
					);
					if (file.draft.auditRooms) {
						promises.push(
							instanceApi(DocumentApi)
								.assignToMultipleAudits(file.document.id, {
									auditIds: file.draft.auditRooms.map((audit) => audit.id),
								})
								.then((res) => {
									res.data.error.forEach((failedAuditRoomId) => {
										MLDialog.showSnackbar(
											t("uploadDocument.failedAssignmentToAuditRooms", {
												documentName: file.document.name,
												auditRoomName: file.draft.auditRooms?.find((auditRoom) => auditRoom.id === failedAuditRoomId)?.name ?? "",
											}),
											{ variant: "error" }
										);
									});
								})
								.catch((e) => {
									MLDialog.showSnackbar(apiErrorParser(e), { variant: "error" });
								})
						);
					}
					if (file.draft.shares) {
						promises.push(
							instanceApi(DocumentApi)
								.shareDocumentsWithUsers({
									documents: [{ documentId: file.document.id, versionFileId: file.document.lastVersionFile[0].id }],
									shareUserIdsOrEmails: file.draft.shares.usersToShareWith.map((share) =>
										isUserOrContact(share) ? share.id : share?.toLowerCase()
									),
									note: file.draft.shares.note,
									language: file.draft.shares.language,
								})
								.then((res) => {
									res.data.failed.forEach((fail) => {
										MLDialog.showSnackbar(fail.message ?? "", { variant: "error" });
									});
								})
								.catch((e) => {
									MLDialog.showSnackbar(apiErrorParser(e), { variant: "error" });
								})
						);
					}
					return Promise.all(promises);
				},
				allUploadedFiles
			);
		},
	});
}
