import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useModalNavigator } from "../../../../utils/components/ModalNavigator/ModalNavigator";
import SendRequestModal from "../../../../utils/components/scaffold/components/SendRequestModal";
import UploadDocument from "../../../../utils/components/UploadDocument/UploadDocument";
import { useModal } from "../../../../utils/hooks/useModal";

import { useGetDocumentRequestsCount } from "../../hooks/useGetDocumentRequestsCount";
import { expiringDocumentsCountQueryKey, useGetExpiringDocumentsCount } from "../../hooks/useGetExpiringDocumentsCount";
import { useGetInboxDocumentsCount } from "../../hooks/useGetInboxDocumentsCount";
import ExpireDocumentModal from "../ExpireDocumentsModal";
import DashboardSmallCard from "./DashboardSmallCard";

export const SmallCardsGrid = () => {
	const { t } = useTranslation();
	const { pushBottomSheet, pushModal } = useModalNavigator();
	const queryClient = useQueryClient();

	const { data: documentRequestsCount, isLoading: isDocumentRequestCountLoading } = useGetDocumentRequestsCount();

	const { data: inboxDocumentsCount, isLoading: isInboxDocumentsCountLoading } = useGetInboxDocumentsCount();

	const { data: expiringDocumentsCount, isLoading: isExpiringDocumentsCountLoading } = useGetExpiringDocumentsCount();

	const { open: sendRequestOpen, toggleOpen: toggleSendRequestOpen } = useModal();

	return (
		<>
			<div className="grid sm:grid-cols-4 grid-cols-2 items-stretch gap-x-3 sm:gap-y-8 gap-y-4 sm:mb-8 mb-4">
				<DashboardSmallCard icon="send_outline" href="/all_documents">
					{t("dashboard.shareDocuments")}
				</DashboardSmallCard>
				<DashboardSmallCard icon="document_outline" href="/all_documents?section=shared">
					{t("documents.sharedDocuments")}
				</DashboardSmallCard>
				<DashboardSmallCard icon="paper_outline_07" color="primary" onClick={() => toggleSendRequestOpen()}>
					{t("dashboard.requestDocuments")}
				</DashboardSmallCard>
				<DashboardSmallCard
					icon="paper_outline_04"
					onClick={() => {
						pushBottomSheet({ component: UploadDocument, props: { currentFolder: undefined }, ref: null }).catch(() => {});
					}}
				>
					{t("global.uploadNewDocuments")}
				</DashboardSmallCard>
				<DashboardSmallCard
					icon="paper_outline_05"
					color="secondary"
					href="/inbox"
					count={inboxDocumentsCount}
					isCountLoading={isInboxDocumentsCountLoading}
				>
					{t("dashboard.inboxDocuments")}
				</DashboardSmallCard>
				<DashboardSmallCard
					icon="paper_outline_05"
					color="secondary"
					count={expiringDocumentsCount}
					isCountLoading={isExpiringDocumentsCountLoading}
					onClick={() =>
						pushModal(ExpireDocumentModal, {
							revalidateTotalCount: () => queryClient.invalidateQueries(expiringDocumentsCountQueryKey),
						})
					}
				>
					{t("dashboard.expiringDocuments")}
				</DashboardSmallCard>
				<DashboardSmallCard
					icon="bell_outline"
					color="secondary"
					href="/requests?section=received"
					count={documentRequestsCount}
					isCountLoading={isDocumentRequestCountLoading}
				>
					{t("dashboard.unreadRequests")}
				</DashboardSmallCard>
				<DashboardSmallCard icon="folder_outline" href="/folders" color="secondary">
					{t("folders.myFolders")}
				</DashboardSmallCard>
			</div>

			<SendRequestModal open={sendRequestOpen} toggleOpen={toggleSendRequestOpen} />
		</>
	);
};

export default SmallCardsGrid;
