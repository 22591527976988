import { alpha, Box, Breadcrumbs, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import type { Folder } from "certiblok-api-manager";
import { useBetterMediaQuery } from "../../../hooks/useBetterMediaQuery";
import { default as CertiblokIcon } from "../../icons/CertiblokIcon";

type FolderBreadcrumbChipProps = {
	folder: Folder;
	onDelete?: () => void;
	isDisabled?: boolean;
};

type SelectedFoldersBreadcrumbsProps = {
	folderHistory: Folder[];
	onFolderDelete: (folder: Folder) => void;
	isLoading?: boolean;
	hasMoreSubfolders?: boolean;
};

const FolderBreadcrumbChip = ({ folder, onDelete, isDisabled }: FolderBreadcrumbChipProps) => {
	return (
		<Tooltip key={folder.id} title={folder.name} enterDelay={500}>
			<Chip
				label={
					<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
						<CertiblokIcon size={20} color={folder.color} name={"folder_bold"} />
						<Typography children={folder.name} variant="body2" noWrap sx={{ opacity: 0.6 }} />
					</Box>
				}
				onDelete={() => {
					onDelete?.();
				}}
				sx={{ backgroundColor: alpha(folder.color ?? "rgba(0,0,0,0)", 0.12), maxWidth: 154, marginTop: 1 }}
				disabled={isDisabled}
			/>
		</Tooltip>
	);
};

const SelectedFoldersBreadcrumbs: React.FC<SelectedFoldersBreadcrumbsProps> = ({
	folderHistory,
	onFolderDelete,
	isLoading,
	hasMoreSubfolders,
}) => {
	const isMd = useBetterMediaQuery("md");

	return (
		<>
			{isMd ? (
				<Breadcrumbs
					maxItems={100}
					separator={
						<CertiblokIcon
							size={24}
							color="rgba(0, 0, 0, 0.38)"
							name={"arrow_mini_outline_right"}
							iconCss={{ marginTop: 4 }}
						/>
					}
				>
					{folderHistory.map((folder) => (
						<FolderBreadcrumbChip folder={folder} isDisabled={isLoading} onDelete={() => onFolderDelete(folder)} />
					))}

					{!isLoading && hasMoreSubfolders && <div />}
				</Breadcrumbs>
			) : (
				<div className="flex gap-1 items-center">
					{folderHistory.length === 0 ? (
						<div />
					) : (
						<>
							{folderHistory.length > 1 && (
								<>
									<IconButton
										size="small"
										color="secondary"
										className="mt-1 flex-shrink-0"
										onClick={() => onFolderDelete(folderHistory[0])}
									>
										<CertiblokIcon size={20} name="home_bold" color="rgba(0,0,0,0.6)" />
									</IconButton>
									<CertiblokIcon size={20} name="arrow_mini_outline_right" color="rgba(0,0,0,0.24)" className="mt-1" />
									{folderHistory.length > 2 && (
										<>
											<CertiblokIcon
												size={20}
												name="more"
												className="origin-center rotate-90 bg-black/[0.06] py-1 rounded-md mt-1"
												color="rgba(0,0,0,0.24)"
											/>
											<CertiblokIcon size={20} name="arrow_mini_outline_right" color="rgba(0,0,0,0.24)" className="mt-1" />
										</>
									)}
								</>
							)}
							<FolderBreadcrumbChip
								folder={folderHistory?.[folderHistory.length - 1]}
								isDisabled={isLoading}
								onDelete={() => onFolderDelete(folderHistory[folderHistory.length - 1])}
							/>
						</>
					)}
				</div>
			)}
		</>
	);
};
export default SelectedFoldersBreadcrumbs;
