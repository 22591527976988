"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentApi = exports.DocumentApiFactory = exports.DocumentApiFp = exports.DocumentApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * DocumentApi - axios parameter creator
 * @export
 */
const DocumentApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"update\", \"Audit\"]
         * @summary Assign to multiple audits | Permission required: [\"update\", \"Audit\"]
         * @param {string} id
         * @param {InputAssignToMultipleAudits} [inputAssignToMultipleAudits]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignToMultipleAudits: (id, inputAssignToMultipleAudits, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('assignToMultipleAudits', 'id', id);
            const localVarPath = `/document/{id}/audit/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputAssignToMultipleAudits, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"create\", \"Folder\"]
         * @summary Create document | Permission required: [\"create\", \"Folder\"]
         * @param {string} parentFolderId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createDocument: (parentFolderId, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'parentFolderId' is not null or undefined
            (0, common_1.assertParamExists)('createDocument', 'parentFolderId', parentFolderId);
            // verify required parameter 'file' is not null or undefined
            (0, common_1.assertParamExists)('createDocument', 'file', file);
            const localVarPath = `/document/createDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (parentFolderId !== undefined) {
                localVarFormParams.append('parentFolderId', parentFolderId);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"create\", \"Folder\"]
         * @summary Create document limitless | Permission required: [\"create\", \"Folder\"]
         * @param {InputCreateDocumentLimitless} [inputCreateDocumentLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentLimitless: (inputCreateDocumentLimitless, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/createDocumentLimitless`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateDocumentLimitless, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete one or more documents
         * @param {InputDeleteOneOrMoreDocuments} [inputDeleteOneOrMoreDocuments]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneOrMoreDocuments: (inputDeleteOneOrMoreDocuments, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/deleteOneOrMoreDocuments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputDeleteOneOrMoreDocuments, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete version of document
         * @param {string} id
         * @param {InputDeleteVersionOfDocument} [inputDeleteVersionOfDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVersionOfDocument: (id, inputDeleteVersionOfDocument, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('deleteVersionOfDocument', 'id', id);
            const localVarPath = `/document/{id}/file/deleteVersion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputDeleteVersionOfDocument, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Download document logs
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentLogs: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('downloadDocumentLogs', 'id', id);
            const localVarPath = `/document/{id}/log/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Download document qr
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentQr: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('downloadDocumentQr', 'id', id);
            const localVarPath = `/document/{id}/qr/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit document
         * @param {string} id
         * @param {InputEditDocument} [inputEditDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocument: (id, inputEditDocument, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('editDocument', 'id', id);
            const localVarPath = `/document/{id}/editDocument`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditDocument, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit document qr
         * @param {string} id
         * @param {QrCodeInput} [qrCodeInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocumentQr: (id, qrCodeInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('editDocumentQr', 'id', id);
            const localVarPath = `/document/{id}/qr/edit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(qrCodeInput, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Generate multiple download link
         * @param {InputGenerateMultipleDownloadLink} [inputGenerateMultipleDownloadLink]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMultipleDownloadLink: (inputGenerateMultipleDownloadLink, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/multiple/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputGenerateMultipleDownloadLink, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Generate qr code
         * @param {string} id
         * @param {QrCodeInput} [qrCodeInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateQrCode: (id, qrCodeInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('generateQrCode', 'id', id);
            const localVarPath = `/document/{id}/qr/generate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(qrCodeInput, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all document share notes
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentShareNotes: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getAllDocumentShareNotes', 'id', id);
            const localVarPath = `/document/{id}/sharenote/list`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all linked audit rooms
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLinkedAuditRooms: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getAllLinkedAuditRooms', 'id', id);
            const localVarPath = `/document/{id}/audit/linked`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get document log
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentLog: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getDocumentLog', 'id', id);
            const localVarPath = `/document/{id}/log/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get document qr
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentQr: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getDocumentQr', 'id', id);
            const localVarPath = `/document/{id}/qr/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get document qr image
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentQrImage: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getDocumentQrImage', 'id', id);
            const localVarPath = `/document/{id}/qr/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get document qr logs
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentQrLogs: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getDocumentQrLogs', 'id', id);
            const localVarPath = `/document/{id}/qr/log`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"CBDocument\"]
         * @summary Get documents last changes since | Permission required: [\"readAll\", \"CBDocument\"]
         * @param {string} since
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsLastChangesSince: (since, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'since' is not null or undefined
            (0, common_1.assertParamExists)('getDocumentsLastChangesSince', 'since', since);
            const localVarPath = `/document/getLastChangesSince`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get preview for file
         * @param {string} id
         * @param {string} [fileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewForFile: (id, fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getPreviewForFile', 'id', id);
            const localVarPath = `/document/{id}/file/getPreview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get single document
         * @param {string} id
         * @param {string} [preferredVersionFileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleDocument: (id, preferredVersionFileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getSingleDocument', 'id', id);
            const localVarPath = `/document/{id}/getSingleDocument`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (preferredVersionFileId !== undefined) {
                localVarQueryParameter['preferredVersionFileId'] = preferredVersionFileId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary List all documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllDocuments: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary List by tags
         * @param {string} tagIds
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listByTags: (tagIds, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tagIds' is not null or undefined
            (0, common_1.assertParamExists)('listByTags', 'tagIds', tagIds);
            const localVarPath = `/document/list-by-tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (tagIds !== undefined) {
                localVarQueryParameter['tagIds'] = tagIds;
            }
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary List expiring documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExpiringDocuments: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/list-documents-expiring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary List inbox documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInboxDocuments: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/list-inbox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary List shared documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSharedDocuments: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/list-shared`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Move to folder and replace file
         * @param {string} id
         * @param {InputMoveToFolderAndReplaceFile} [inputMoveToFolderAndReplaceFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToFolderAndReplaceFile: (id, inputMoveToFolderAndReplaceFile, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('moveToFolderAndReplaceFile', 'id', id);
            const localVarPath = `/document/{id}/moveToFolderAndReplaceFile`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputMoveToFolderAndReplaceFile, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary New version file of document
         * @param {string} id
         * @param {any} file
         * @param {string} expireDate
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        newVersionFileOfDocument: (id, file, expireDate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('newVersionFileOfDocument', 'id', id);
            // verify required parameter 'file' is not null or undefined
            (0, common_1.assertParamExists)('newVersionFileOfDocument', 'file', file);
            // verify required parameter 'expireDate' is not null or undefined
            (0, common_1.assertParamExists)('newVersionFileOfDocument', 'expireDate', expireDate);
            const localVarPath = `/document/{id}/file/newVersion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            if (expireDate !== undefined) {
                localVarFormParams.append('expireDate', expireDate);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary New version file of document limitless
         * @param {string} id
         * @param {InputNewVersionFileOfDocumentLimitless} [inputNewVersionFileOfDocumentLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newVersionFileOfDocumentLimitless: (id, inputNewVersionFileOfDocumentLimitless, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('newVersionFileOfDocumentLimitless', 'id', id);
            const localVarPath = `/document/{id}/file/newVersionLimitless`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputNewVersionFileOfDocumentLimitless, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Request document update
         * @param {string} id
         * @param {InputRequestDocumentUpdate} [inputRequestDocumentUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestDocumentUpdate: (id, inputRequestDocumentUpdate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('requestDocumentUpdate', 'id', id);
            const localVarPath = `/document/{id}/requestFileUpdate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputRequestDocumentUpdate, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Send request
         * @param {InputSendRequest} [inputSendRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRequest: (inputSendRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/send-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputSendRequest, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Share document with user
         * @param {string} id
         * @param {InputShareDocumentWithUser} [inputShareDocumentWithUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDocumentWithUser: (id, inputShareDocumentWithUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('shareDocumentWithUser', 'id', id);
            const localVarPath = `/document/{id}/shareDocuments/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputShareDocumentWithUser, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Share documents with users
         * @param {InputShareDocumentsWithUsers} [inputShareDocumentsWithUsers]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDocumentsWithUsers: (inputShareDocumentsWithUsers, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/shareDocuments/multiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputShareDocumentsWithUsers, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Unshare document with user
         * @param {string} id
         * @param {InputUnshareDocumentWithUser} [inputUnshareDocumentWithUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareDocumentWithUser: (id, inputUnshareDocumentWithUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('unshareDocumentWithUser', 'id', id);
            const localVarPath = `/document/{id}/unshareDocuments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputUnshareDocumentWithUser, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Unshare documents with sharing group
         * @param {string} id
         * @param {InputUnshareDocumentsWithSharingGroup} [inputUnshareDocumentsWithSharingGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareDocumentsWithSharingGroup: (id, inputUnshareDocumentsWithSharingGroup, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('unshareDocumentsWithSharingGroup', 'id', id);
            const localVarPath = `/document/{id}/sharingGroup/unshare`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputUnshareDocumentsWithSharingGroup, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update document qr
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentQr: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('updateDocumentQr', 'id', id);
            const localVarPath = `/document/{id}/qr/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DocumentApiAxiosParamCreator = DocumentApiAxiosParamCreator;
/**
 * DocumentApi - functional programming interface
 * @export
 */
const DocumentApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.DocumentApiAxiosParamCreator)(configuration);
    return {
        /**
         *  **Permission required**: [\"update\", \"Audit\"]
         * @summary Assign to multiple audits | Permission required: [\"update\", \"Audit\"]
         * @param {string} id
         * @param {InputAssignToMultipleAudits} [inputAssignToMultipleAudits]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignToMultipleAudits(id, inputAssignToMultipleAudits, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.assignToMultipleAudits(id, inputAssignToMultipleAudits, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"create\", \"Folder\"]
         * @summary Create document | Permission required: [\"create\", \"Folder\"]
         * @param {string} parentFolderId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createDocument(parentFolderId, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createDocument(parentFolderId, file, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"create\", \"Folder\"]
         * @summary Create document limitless | Permission required: [\"create\", \"Folder\"]
         * @param {InputCreateDocumentLimitless} [inputCreateDocumentLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentLimitless(inputCreateDocumentLimitless, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createDocumentLimitless(inputCreateDocumentLimitless, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete one or more documents
         * @param {InputDeleteOneOrMoreDocuments} [inputDeleteOneOrMoreDocuments]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneOrMoreDocuments(inputDeleteOneOrMoreDocuments, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteOneOrMoreDocuments(inputDeleteOneOrMoreDocuments, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete version of document
         * @param {string} id
         * @param {InputDeleteVersionOfDocument} [inputDeleteVersionOfDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVersionOfDocument(id, inputDeleteVersionOfDocument, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteVersionOfDocument(id, inputDeleteVersionOfDocument, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Download document logs
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentLogs(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.downloadDocumentLogs(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Download document qr
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentQr(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.downloadDocumentQr(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit document
         * @param {string} id
         * @param {InputEditDocument} [inputEditDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocument(id, inputEditDocument, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editDocument(id, inputEditDocument, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit document qr
         * @param {string} id
         * @param {QrCodeInput} [qrCodeInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocumentQr(id, qrCodeInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editDocumentQr(id, qrCodeInput, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Generate multiple download link
         * @param {InputGenerateMultipleDownloadLink} [inputGenerateMultipleDownloadLink]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMultipleDownloadLink(inputGenerateMultipleDownloadLink, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateMultipleDownloadLink(inputGenerateMultipleDownloadLink, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Generate qr code
         * @param {string} id
         * @param {QrCodeInput} [qrCodeInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateQrCode(id, qrCodeInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateQrCode(id, qrCodeInput, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all document share notes
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentShareNotes(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllDocumentShareNotes(id, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all linked audit rooms
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLinkedAuditRooms(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllLinkedAuditRooms(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get document log
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentLog(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDocumentLog(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get document qr
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentQr(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDocumentQr(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get document qr image
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentQrImage(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDocumentQrImage(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get document qr logs
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentQrLogs(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDocumentQrLogs(id, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"CBDocument\"]
         * @summary Get documents last changes since | Permission required: [\"readAll\", \"CBDocument\"]
         * @param {string} since
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsLastChangesSince(since, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDocumentsLastChangesSince(since, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get preview for file
         * @param {string} id
         * @param {string} [fileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewForFile(id, fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPreviewForFile(id, fileId, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get single document
         * @param {string} id
         * @param {string} [preferredVersionFileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleDocument(id, preferredVersionFileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSingleDocument(id, preferredVersionFileId, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary List all documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllDocuments(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listAllDocuments(filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary List by tags
         * @param {string} tagIds
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listByTags(tagIds, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listByTags(tagIds, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary List expiring documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExpiringDocuments(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listExpiringDocuments(filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary List inbox documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInboxDocuments(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listInboxDocuments(filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary List shared documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSharedDocuments(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listSharedDocuments(filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Move to folder and replace file
         * @param {string} id
         * @param {InputMoveToFolderAndReplaceFile} [inputMoveToFolderAndReplaceFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToFolderAndReplaceFile(id, inputMoveToFolderAndReplaceFile, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.moveToFolderAndReplaceFile(id, inputMoveToFolderAndReplaceFile, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary New version file of document
         * @param {string} id
         * @param {any} file
         * @param {string} expireDate
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        newVersionFileOfDocument(id, file, expireDate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.newVersionFileOfDocument(id, file, expireDate, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary New version file of document limitless
         * @param {string} id
         * @param {InputNewVersionFileOfDocumentLimitless} [inputNewVersionFileOfDocumentLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newVersionFileOfDocumentLimitless(id, inputNewVersionFileOfDocumentLimitless, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.newVersionFileOfDocumentLimitless(id, inputNewVersionFileOfDocumentLimitless, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Request document update
         * @param {string} id
         * @param {InputRequestDocumentUpdate} [inputRequestDocumentUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestDocumentUpdate(id, inputRequestDocumentUpdate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.requestDocumentUpdate(id, inputRequestDocumentUpdate, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Send request
         * @param {InputSendRequest} [inputSendRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRequest(inputSendRequest, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sendRequest(inputSendRequest, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Share document with user
         * @param {string} id
         * @param {InputShareDocumentWithUser} [inputShareDocumentWithUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDocumentWithUser(id, inputShareDocumentWithUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.shareDocumentWithUser(id, inputShareDocumentWithUser, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Share documents with users
         * @param {InputShareDocumentsWithUsers} [inputShareDocumentsWithUsers]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDocumentsWithUsers(inputShareDocumentsWithUsers, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.shareDocumentsWithUsers(inputShareDocumentsWithUsers, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Unshare document with user
         * @param {string} id
         * @param {InputUnshareDocumentWithUser} [inputUnshareDocumentWithUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareDocumentWithUser(id, inputUnshareDocumentWithUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unshareDocumentWithUser(id, inputUnshareDocumentWithUser, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Unshare documents with sharing group
         * @param {string} id
         * @param {InputUnshareDocumentsWithSharingGroup} [inputUnshareDocumentsWithSharingGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareDocumentsWithSharingGroup(id, inputUnshareDocumentsWithSharingGroup, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unshareDocumentsWithSharingGroup(id, inputUnshareDocumentsWithSharingGroup, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Update document qr
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentQr(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateDocumentQr(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.DocumentApiFp = DocumentApiFp;
/**
 * DocumentApi - factory interface
 * @export
 */
const DocumentApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.DocumentApiFp)(configuration);
    return {
        /**
         *  **Permission required**: [\"update\", \"Audit\"]
         * @summary Assign to multiple audits | Permission required: [\"update\", \"Audit\"]
         * @param {string} id
         * @param {InputAssignToMultipleAudits} [inputAssignToMultipleAudits]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignToMultipleAudits(id, inputAssignToMultipleAudits, options) {
            return localVarFp.assignToMultipleAudits(id, inputAssignToMultipleAudits, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"create\", \"Folder\"]
         * @summary Create document | Permission required: [\"create\", \"Folder\"]
         * @param {string} parentFolderId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createDocument(parentFolderId, file, options) {
            return localVarFp.createDocument(parentFolderId, file, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"create\", \"Folder\"]
         * @summary Create document limitless | Permission required: [\"create\", \"Folder\"]
         * @param {InputCreateDocumentLimitless} [inputCreateDocumentLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentLimitless(inputCreateDocumentLimitless, options) {
            return localVarFp.createDocumentLimitless(inputCreateDocumentLimitless, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete one or more documents
         * @param {InputDeleteOneOrMoreDocuments} [inputDeleteOneOrMoreDocuments]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneOrMoreDocuments(inputDeleteOneOrMoreDocuments, options) {
            return localVarFp.deleteOneOrMoreDocuments(inputDeleteOneOrMoreDocuments, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete version of document
         * @param {string} id
         * @param {InputDeleteVersionOfDocument} [inputDeleteVersionOfDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVersionOfDocument(id, inputDeleteVersionOfDocument, options) {
            return localVarFp.deleteVersionOfDocument(id, inputDeleteVersionOfDocument, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Download document logs
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentLogs(id, options) {
            return localVarFp.downloadDocumentLogs(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Download document qr
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentQr(id, options) {
            return localVarFp.downloadDocumentQr(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit document
         * @param {string} id
         * @param {InputEditDocument} [inputEditDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocument(id, inputEditDocument, options) {
            return localVarFp.editDocument(id, inputEditDocument, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit document qr
         * @param {string} id
         * @param {QrCodeInput} [qrCodeInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocumentQr(id, qrCodeInput, options) {
            return localVarFp.editDocumentQr(id, qrCodeInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Generate multiple download link
         * @param {InputGenerateMultipleDownloadLink} [inputGenerateMultipleDownloadLink]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMultipleDownloadLink(inputGenerateMultipleDownloadLink, options) {
            return localVarFp.generateMultipleDownloadLink(inputGenerateMultipleDownloadLink, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Generate qr code
         * @param {string} id
         * @param {QrCodeInput} [qrCodeInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateQrCode(id, qrCodeInput, options) {
            return localVarFp.generateQrCode(id, qrCodeInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all document share notes
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentShareNotes(id, filter, options) {
            return localVarFp.getAllDocumentShareNotes(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all linked audit rooms
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLinkedAuditRooms(id, options) {
            return localVarFp.getAllLinkedAuditRooms(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get document log
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentLog(id, options) {
            return localVarFp.getDocumentLog(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get document qr
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentQr(id, options) {
            return localVarFp.getDocumentQr(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get document qr image
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentQrImage(id, options) {
            return localVarFp.getDocumentQrImage(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get document qr logs
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentQrLogs(id, filter, options) {
            return localVarFp.getDocumentQrLogs(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"CBDocument\"]
         * @summary Get documents last changes since | Permission required: [\"readAll\", \"CBDocument\"]
         * @param {string} since
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsLastChangesSince(since, options) {
            return localVarFp.getDocumentsLastChangesSince(since, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get preview for file
         * @param {string} id
         * @param {string} [fileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewForFile(id, fileId, options) {
            return localVarFp.getPreviewForFile(id, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get single document
         * @param {string} id
         * @param {string} [preferredVersionFileId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleDocument(id, preferredVersionFileId, options) {
            return localVarFp.getSingleDocument(id, preferredVersionFileId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List all documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllDocuments(filter, options) {
            return localVarFp.listAllDocuments(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List by tags
         * @param {string} tagIds
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listByTags(tagIds, filter, options) {
            return localVarFp.listByTags(tagIds, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List expiring documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExpiringDocuments(filter, options) {
            return localVarFp.listExpiringDocuments(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List inbox documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInboxDocuments(filter, options) {
            return localVarFp.listInboxDocuments(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List shared documents
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSharedDocuments(filter, options) {
            return localVarFp.listSharedDocuments(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Move to folder and replace file
         * @param {string} id
         * @param {InputMoveToFolderAndReplaceFile} [inputMoveToFolderAndReplaceFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToFolderAndReplaceFile(id, inputMoveToFolderAndReplaceFile, options) {
            return localVarFp.moveToFolderAndReplaceFile(id, inputMoveToFolderAndReplaceFile, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary New version file of document
         * @param {string} id
         * @param {any} file
         * @param {string} expireDate
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        newVersionFileOfDocument(id, file, expireDate, options) {
            return localVarFp.newVersionFileOfDocument(id, file, expireDate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary New version file of document limitless
         * @param {string} id
         * @param {InputNewVersionFileOfDocumentLimitless} [inputNewVersionFileOfDocumentLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newVersionFileOfDocumentLimitless(id, inputNewVersionFileOfDocumentLimitless, options) {
            return localVarFp.newVersionFileOfDocumentLimitless(id, inputNewVersionFileOfDocumentLimitless, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Request document update
         * @param {string} id
         * @param {InputRequestDocumentUpdate} [inputRequestDocumentUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestDocumentUpdate(id, inputRequestDocumentUpdate, options) {
            return localVarFp.requestDocumentUpdate(id, inputRequestDocumentUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Send request
         * @param {InputSendRequest} [inputSendRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRequest(inputSendRequest, options) {
            return localVarFp.sendRequest(inputSendRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Share document with user
         * @param {string} id
         * @param {InputShareDocumentWithUser} [inputShareDocumentWithUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDocumentWithUser(id, inputShareDocumentWithUser, options) {
            return localVarFp.shareDocumentWithUser(id, inputShareDocumentWithUser, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Share documents with users
         * @param {InputShareDocumentsWithUsers} [inputShareDocumentsWithUsers]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDocumentsWithUsers(inputShareDocumentsWithUsers, options) {
            return localVarFp.shareDocumentsWithUsers(inputShareDocumentsWithUsers, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Unshare document with user
         * @param {string} id
         * @param {InputUnshareDocumentWithUser} [inputUnshareDocumentWithUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareDocumentWithUser(id, inputUnshareDocumentWithUser, options) {
            return localVarFp.unshareDocumentWithUser(id, inputUnshareDocumentWithUser, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Unshare documents with sharing group
         * @param {string} id
         * @param {InputUnshareDocumentsWithSharingGroup} [inputUnshareDocumentsWithSharingGroup]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareDocumentsWithSharingGroup(id, inputUnshareDocumentsWithSharingGroup, options) {
            return localVarFp.unshareDocumentsWithSharingGroup(id, inputUnshareDocumentsWithSharingGroup, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update document qr
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentQr(id, options) {
            return localVarFp.updateDocumentQr(id, options).then((request) => request(axios, basePath));
        },
    };
};
exports.DocumentApiFactory = DocumentApiFactory;
/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
class DocumentApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"update\", \"Audit\"]
     * @summary Assign to multiple audits | Permission required: [\"update\", \"Audit\"]
     * @param {string} id
     * @param {InputAssignToMultipleAudits} [inputAssignToMultipleAudits]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    assignToMultipleAudits(id, inputAssignToMultipleAudits, options) {
        return (0, exports.DocumentApiFp)(this.configuration).assignToMultipleAudits(id, inputAssignToMultipleAudits, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"create\", \"Folder\"]
     * @summary Create document | Permission required: [\"create\", \"Folder\"]
     * @param {string} parentFolderId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    createDocument(parentFolderId, file, options) {
        return (0, exports.DocumentApiFp)(this.configuration).createDocument(parentFolderId, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"create\", \"Folder\"]
     * @summary Create document limitless | Permission required: [\"create\", \"Folder\"]
     * @param {InputCreateDocumentLimitless} [inputCreateDocumentLimitless]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    createDocumentLimitless(inputCreateDocumentLimitless, options) {
        return (0, exports.DocumentApiFp)(this.configuration).createDocumentLimitless(inputCreateDocumentLimitless, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete one or more documents
     * @param {InputDeleteOneOrMoreDocuments} [inputDeleteOneOrMoreDocuments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    deleteOneOrMoreDocuments(inputDeleteOneOrMoreDocuments, options) {
        return (0, exports.DocumentApiFp)(this.configuration).deleteOneOrMoreDocuments(inputDeleteOneOrMoreDocuments, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete version of document
     * @param {string} id
     * @param {InputDeleteVersionOfDocument} [inputDeleteVersionOfDocument]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    deleteVersionOfDocument(id, inputDeleteVersionOfDocument, options) {
        return (0, exports.DocumentApiFp)(this.configuration).deleteVersionOfDocument(id, inputDeleteVersionOfDocument, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Download document logs
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    downloadDocumentLogs(id, options) {
        return (0, exports.DocumentApiFp)(this.configuration).downloadDocumentLogs(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Download document qr
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    downloadDocumentQr(id, options) {
        return (0, exports.DocumentApiFp)(this.configuration).downloadDocumentQr(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit document
     * @param {string} id
     * @param {InputEditDocument} [inputEditDocument]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    editDocument(id, inputEditDocument, options) {
        return (0, exports.DocumentApiFp)(this.configuration).editDocument(id, inputEditDocument, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit document qr
     * @param {string} id
     * @param {QrCodeInput} [qrCodeInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    editDocumentQr(id, qrCodeInput, options) {
        return (0, exports.DocumentApiFp)(this.configuration).editDocumentQr(id, qrCodeInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Generate multiple download link
     * @param {InputGenerateMultipleDownloadLink} [inputGenerateMultipleDownloadLink]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    generateMultipleDownloadLink(inputGenerateMultipleDownloadLink, options) {
        return (0, exports.DocumentApiFp)(this.configuration).generateMultipleDownloadLink(inputGenerateMultipleDownloadLink, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Generate qr code
     * @param {string} id
     * @param {QrCodeInput} [qrCodeInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    generateQrCode(id, qrCodeInput, options) {
        return (0, exports.DocumentApiFp)(this.configuration).generateQrCode(id, qrCodeInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all document share notes
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    getAllDocumentShareNotes(id, filter, options) {
        return (0, exports.DocumentApiFp)(this.configuration).getAllDocumentShareNotes(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all linked audit rooms
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    getAllLinkedAuditRooms(id, options) {
        return (0, exports.DocumentApiFp)(this.configuration).getAllLinkedAuditRooms(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get document log
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    getDocumentLog(id, options) {
        return (0, exports.DocumentApiFp)(this.configuration).getDocumentLog(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get document qr
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    getDocumentQr(id, options) {
        return (0, exports.DocumentApiFp)(this.configuration).getDocumentQr(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get document qr image
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    getDocumentQrImage(id, options) {
        return (0, exports.DocumentApiFp)(this.configuration).getDocumentQrImage(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get document qr logs
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    getDocumentQrLogs(id, filter, options) {
        return (0, exports.DocumentApiFp)(this.configuration).getDocumentQrLogs(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"CBDocument\"]
     * @summary Get documents last changes since | Permission required: [\"readAll\", \"CBDocument\"]
     * @param {string} since
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    getDocumentsLastChangesSince(since, options) {
        return (0, exports.DocumentApiFp)(this.configuration).getDocumentsLastChangesSince(since, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get preview for file
     * @param {string} id
     * @param {string} [fileId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    getPreviewForFile(id, fileId, options) {
        return (0, exports.DocumentApiFp)(this.configuration).getPreviewForFile(id, fileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get single document
     * @param {string} id
     * @param {string} [preferredVersionFileId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    getSingleDocument(id, preferredVersionFileId, options) {
        return (0, exports.DocumentApiFp)(this.configuration).getSingleDocument(id, preferredVersionFileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary List all documents
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    listAllDocuments(filter, options) {
        return (0, exports.DocumentApiFp)(this.configuration).listAllDocuments(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary List by tags
     * @param {string} tagIds
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    listByTags(tagIds, filter, options) {
        return (0, exports.DocumentApiFp)(this.configuration).listByTags(tagIds, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary List expiring documents
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    listExpiringDocuments(filter, options) {
        return (0, exports.DocumentApiFp)(this.configuration).listExpiringDocuments(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary List inbox documents
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    listInboxDocuments(filter, options) {
        return (0, exports.DocumentApiFp)(this.configuration).listInboxDocuments(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary List shared documents
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    listSharedDocuments(filter, options) {
        return (0, exports.DocumentApiFp)(this.configuration).listSharedDocuments(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Move to folder and replace file
     * @param {string} id
     * @param {InputMoveToFolderAndReplaceFile} [inputMoveToFolderAndReplaceFile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    moveToFolderAndReplaceFile(id, inputMoveToFolderAndReplaceFile, options) {
        return (0, exports.DocumentApiFp)(this.configuration).moveToFolderAndReplaceFile(id, inputMoveToFolderAndReplaceFile, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary New version file of document
     * @param {string} id
     * @param {any} file
     * @param {string} expireDate
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    newVersionFileOfDocument(id, file, expireDate, options) {
        return (0, exports.DocumentApiFp)(this.configuration).newVersionFileOfDocument(id, file, expireDate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary New version file of document limitless
     * @param {string} id
     * @param {InputNewVersionFileOfDocumentLimitless} [inputNewVersionFileOfDocumentLimitless]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    newVersionFileOfDocumentLimitless(id, inputNewVersionFileOfDocumentLimitless, options) {
        return (0, exports.DocumentApiFp)(this.configuration).newVersionFileOfDocumentLimitless(id, inputNewVersionFileOfDocumentLimitless, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Request document update
     * @param {string} id
     * @param {InputRequestDocumentUpdate} [inputRequestDocumentUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    requestDocumentUpdate(id, inputRequestDocumentUpdate, options) {
        return (0, exports.DocumentApiFp)(this.configuration).requestDocumentUpdate(id, inputRequestDocumentUpdate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Send request
     * @param {InputSendRequest} [inputSendRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    sendRequest(inputSendRequest, options) {
        return (0, exports.DocumentApiFp)(this.configuration).sendRequest(inputSendRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Share document with user
     * @param {string} id
     * @param {InputShareDocumentWithUser} [inputShareDocumentWithUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    shareDocumentWithUser(id, inputShareDocumentWithUser, options) {
        return (0, exports.DocumentApiFp)(this.configuration).shareDocumentWithUser(id, inputShareDocumentWithUser, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Share documents with users
     * @param {InputShareDocumentsWithUsers} [inputShareDocumentsWithUsers]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    shareDocumentsWithUsers(inputShareDocumentsWithUsers, options) {
        return (0, exports.DocumentApiFp)(this.configuration).shareDocumentsWithUsers(inputShareDocumentsWithUsers, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Unshare document with user
     * @param {string} id
     * @param {InputUnshareDocumentWithUser} [inputUnshareDocumentWithUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    unshareDocumentWithUser(id, inputUnshareDocumentWithUser, options) {
        return (0, exports.DocumentApiFp)(this.configuration).unshareDocumentWithUser(id, inputUnshareDocumentWithUser, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Unshare documents with sharing group
     * @param {string} id
     * @param {InputUnshareDocumentsWithSharingGroup} [inputUnshareDocumentsWithSharingGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    unshareDocumentsWithSharingGroup(id, inputUnshareDocumentsWithSharingGroup, options) {
        return (0, exports.DocumentApiFp)(this.configuration).unshareDocumentsWithSharingGroup(id, inputUnshareDocumentsWithSharingGroup, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update document qr
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    updateDocumentQr(id, options) {
        return (0, exports.DocumentApiFp)(this.configuration).updateDocumentQr(id, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DocumentApi = DocumentApi;
