import type { Audit } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { AuditStatuses, AvailableAuditStatuses } from "../interfaces/AuditRoom";
import type { CertiblokDocumentReview } from "../interfaces/Document";

export function isAuditRoomClosed(expireDate?: string, status?: AuditStatuses): boolean {
	return (
		(status !== undefined &&
			AvailableAuditStatuses.filter((elem) => elem !== "waiting" && elem !== "revision").includes(status)) ||
		(expireDate !== undefined && DateTime.now() > DateTime.fromISO(expireDate))
	);
}

export function getAuditRoomStatus(auditRoom: Audit): AuditStatuses | undefined {
	if (auditRoom.status) return auditRoom.status;
	if (auditRoom.startDate && DateTime.now() < DateTime.fromISO(auditRoom.startDate)) return "waiting";
	if (auditRoom.startDate === undefined || auditRoom.endDate === undefined) return undefined;
	return "revision";
}

export function getReviewStatus(review: CertiblokDocumentReview | undefined): AuditStatuses | undefined {
	if (review === undefined) return undefined;
	if (review.status) return review.status;
	if (review.audit?.startDate && DateTime.now() < DateTime.fromISO(review.audit.startDate)) return "waiting";
	if (review.audit?.startDate === undefined || review.audit.endDate === undefined) return undefined;
	return "revision";
}
