import { Badge, Button, Collapse, Typography } from "@mui/material";
import { Attendee } from "certiblok-api-manager";
import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import UserAvatar from "../../../../UserAvatar";
import CertiblokIcon from "../../../../icons/CertiblokIcon";

export const SingleParticipant = ({
	participant,
	className,
	ownerId,
}: {
	participant: Attendee;
	className?: string;
	ownerId?: string;
}) => {
	const { t } = useTranslation();

	const isOwner = participant.userId === ownerId;

	const hasAccepterParticipation = participant.responseStatus === "accepted" || isOwner;

	return (
		<div className={twMerge(clsx("flex justify-between w-full items-center", className))}>
			<div className="flex items-center gap-2 my-2">
				<Badge
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					className="[&_.MuiBadge-badge]:-top-[2px] [&_.MuiBadge-badge]:right-[2px]"
					badgeContent={
						hasAccepterParticipation ? (
							<div className="rounded-full w-3 h-3 flex justify-center items-center bg-[rgb(174,233,189)]">
								<CertiblokIcon name="success" className="text-black" size={8} />
							</div>
						) : participant.responseStatus === "declined" || participant.responseStatus === "deleted" ? (
							<div className="rounded-full w-3 h-3 flex justify-center items-center bg-[rgb(255,177,172)]">
								<CertiblokIcon name="close" className="text-black" size={8} />
							</div>
						) : null
					}
				>
					<UserAvatar className="w-6 h-6" user={participant.user} />
				</Badge>
				<Typography>
					{participant.user?.name} {participant.user?.surname}
				</Typography>
			</div>
			<Typography variant="caption" className="text-black/60 mr-3">
				{t(`event.roles.${participant.role}`)}
			</Typography>
		</div>
	);
};

export const ParticipantsSection = ({
	participants,
	isLoading,
}: {
	participants: Attendee[];
	isLoading: boolean;
	isOwner?: boolean;
}) => {
	const { t } = useTranslation();
	const [allParticipantsVisible, setAllParticipantsVisible] = useState(false);

	const nResidualParticipants = participants.length - 3;
	const upToThreeParticipants = participants.filter((p, i) => i < 3);
	const overThreeParticipants = participants.filter((p, i) => i >= 3);

	const ownerId = participants.find((p) => p.role === "owner")?.userId;

	return (
		<>
			{isLoading ? (
				<div className="mb-5">
					<div className={"-mt-[2px] bg-gray-200 animate-pulse rounded-md w-[170px] h-6"} />
					<div className="flex gap-3 mt-2 items-center">
						<div className="w-5 h-5 rounded-full bg-gray-200 animate-pulse" />
						<div className={"-mt-[2px] bg-gray-200 animate-pulse rounded-md w-[170px] h-5"} />
					</div>
					<div className="flex gap-3 mt-2 items-center">
						<div className="w-5 h-5 rounded-full bg-gray-200 animate-pulse" />
						<div className={"-mt-[2px] bg-gray-200 animate-pulse rounded-md w-[180px] h-5"} />
					</div>
				</div>
			) : (
				<div className={participants.length > 3 ? "-mt-[8px]" : "mt-[2px]"}>
					<div className="flex w-full justify-between items-center">
						<Typography>
							{participants?.length} {t("singleEvent.participants", { count: participants?.length ?? 0 })}
						</Typography>
						{participants.length > 3 && (
							<Button variant="text" color="secondary" onClick={() => setAllParticipantsVisible((v) => !v)}>
								{allParticipantsVisible ? t("singleEvent.hideList") : t("singleEvent.seeCompleteList")}
							</Button>
						)}
					</div>
					<div className="flex flex-col gap-3 mt-2">
						{upToThreeParticipants.map((participant, i) => (
							<SingleParticipant ownerId={ownerId} participant={participant} key={participant.userId + (i ?? "")} />
						))}
						<Collapse in={allParticipantsVisible}>
							{overThreeParticipants.map((participant, i) => (
								<SingleParticipant ownerId={ownerId} participant={participant} key={participant.userId + (i ?? "")} />
							))}
						</Collapse>
						<Collapse in={nResidualParticipants > 0 && !allParticipantsVisible}>
							<div className="px-6 py-2 bg-backgroundSecondary w-fit rounded-xl mb-4">
								<Typography variant="subtitle2" className="text-secondary">
									+{nResidualParticipants}
								</Typography>
							</div>
						</Collapse>
					</div>
				</div>
			)}
		</>
	);
};
