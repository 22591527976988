/** @jsxImportSource @emotion/react */
import { Ability } from "@casl/ability";
import { alpha, Box, IconButton, Link, Stack, Typography, useTheme } from "@mui/material";
import { UserApi } from "certiblok-api-manager";
import Cookies from "js-cookie";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import loginIllustration from "../../assets/main_illustrations/auth/login.png";
import { AuthContainer } from "../../utils/components/AuthScaffold";
import CertiblokTextInput from "../../utils/components/CertiblokTextInput";
import CertiblokIcon from "../../utils/components/icons/CertiblokIcon";
import LoadingTextButton from "../../utils/components/LoadingTextButton";
import { useApi } from "../../utils/hooks/api";
import { useABACHandler } from "../../utils/hooks/useABACHandler";
import { useBetterMediaQuery } from "../../utils/hooks/useBetterMediaQuery";
import { useRegisterCompanyCheck } from "../../utils/hooks/useRegisterCompanyCheck";
import type { TextFieldStatus } from "../../utils/interfaces/TextFieldStatus";

type Props = {};

const Login: React.FC<Props> = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const userApi = useApi(UserApi);

	const location = useLocation();

	const from = (location.state as any)?.from?.pathname || "/";

	const [passwordVisible, setPasswordVisible] = useState(false);
	const [mail, setMail] = useState("");
	const [mailStatus, setMailStatus] = useState<TextFieldStatus>();
	const [mailHelperText, setMailHelperText] = useState("");

	const [password, setPassword] = useState("");
	const [passwordStatus, setPasswordStatus] = useState<TextFieldStatus>();
	const [passwordHelperText, setPasswordHelperText] = useState("");

	const { loadABACPermissionAndNavigateTo } = useABACHandler();

	useRegisterCompanyCheck();

	const [loading, setLoading] = useState(false);

	const submit = useCallback(() => {
		if (isEmail(mail) && password.length > 0) {
			setLoading(true);
			localStorage.clear();
			Cookies.remove("CertiblokAccessToken", { domain: "mabiloft.com" });
			Cookies.remove("CertiblokAuthOverride", { domain: "mabiloft.com" });
			userApi
				.login({ email: mail, password })
				.then((res) => {
					setLoading(false);
					//@ts-expect-error
					const loginAbility = new Ability(res.data.abilityRules);
					const needTwoFA = loginAbility.can("manage", "2FA");
					if (needTwoFA) {
						loadABACPermissionAndNavigateTo(res.data, `/login/two-fa`, { from });
					} else {
						loadABACPermissionAndNavigateTo(res.data, from);
					}
				})
				.catch((err) => {
					setLoading(false);
					setPasswordStatus("error");
					setPasswordHelperText(t("login.wrongCredentials"));
				});
		} else {
			if (!isEmail(mail)) {
				setMailHelperText(t("login.insertValidMail"));
				setMailStatus("error");
			}
			if (password.length === 0) {
				setPasswordStatus("error");
				setPasswordHelperText(t("login.insertPassword"));
			}
		}
	}, [from, loadABACPermissionAndNavigateTo, mail, password, t, userApi]);

	const isMd = useBetterMediaQuery("md");

	return (
		<React.Fragment>
			<img
				src={loginIllustration}
				alt={t("login.loginIllustration")}
				css={{
					position: "absolute",
					left: 0,
					alignSelf: "center",
					maxHeight: "max(100%, 836px)",
					[theme.breakpoints.up("xl")]: {
						width: "clamp(1256px, 50%, 1793px)",
						objectFit: "cover",
					},
				}}
			/>

			<AuthContainer>
				<Stack
					justifyContent={isMd ? "space-between" : "flex-start"}
					spacing={isMd ? 3 : 9}
					sx={{ width: "100%", height: "100%" }}
				>
					<Box>
						<Typography
							children={"Login"}
							variant={isMd ? "h5" : "h6"}
							fontWeight={800}
							color="primary"
							sx={{
								textAlign: {
									xs: "center",
									md: "start",
								},
							}}
						/>
						<Typography
							children={t("login.description")}
							variant={isMd ? "h6" : "body2"}
							sx={{
								opacity: 0.6,
								pt: 3,
								textAlign: {
									xs: "center",
									md: "start",
								},
							}}
						/>
					</Box>
					<Stack spacing={6} sx={{ flexGrow: 1, pt: 4 }}>
						<CertiblokTextInput
							autoFocus
							value={mail}
							onChange={(event) => {
								setMail(event.target.value);
							}}
							onFocus={() => {
								setMailHelperText("");
								setMailStatus(undefined);
							}}
							onBlur={() => {
								if (!isEmail(mail)) {
									setMailHelperText(t("login.insertValidMail"));
									setMailStatus("error");
								}
							}}
							type="email"
							status={mailStatus}
							helperText={mailHelperText}
							label={t("forgotPassword.insertMail")}
							placeholder={t("forgotPassword.insertMail")}
							startAdornment={<CertiblokIcon size={16} color="inherit" name={"mail_outline"} iconCss={{ opacity: 0.6 }} />}
							fullWidth
							size="medium"
							css={{ backgroundColor: theme.palette.background.default }}
						/>
						<CertiblokTextInput
							value={password}
							onChange={(event) => {
								setPassword(event.target.value);
							}}
							onFocus={() => {
								setPasswordHelperText("");
								setPasswordStatus(undefined);
							}}
							onBlur={() => {
								if (password.length === 0) {
									setPasswordStatus("error");
									setPasswordHelperText(t("login.insertPassword"));
								}
							}}
							autoCorrect="off"
							autoCapitalize="none"
							status={passwordStatus}
							helperText={passwordHelperText}
							label={t("login.insertYourPassword")}
							placeholder={t("login.insertYourPassword")}
							type={passwordVisible ? "text" : "password"}
							onSubmit={submit}
							startAdornment={<CertiblokIcon size={16} color="inherit" name={"key_outline"} iconCss={{ opacity: 0.6 }} />}
							endAdornment={
								<IconButton
									tabIndex={-1}
									onClick={(ev) => {
										ev.stopPropagation();
										ev.preventDefault();
										setPasswordVisible(!passwordVisible);
									}}
									onTouchEnd={(ev) => {
										ev.preventDefault();
										setPasswordVisible(!passwordVisible);
									}}
								>
									{passwordVisible ? (
										<CertiblokIcon size={16} color="inherit" name={"eye_outline_02"} iconCss={{ opacity: 0.6 }} />
									) : (
										<CertiblokIcon size={16} color="inherit" name={"eye_outline_01"} iconCss={{ opacity: 0.6 }} />
									)}
								</IconButton>
							}
							fullWidth
							size="medium"
							css={{ backgroundColor: theme.palette.background.default }}
						/>
						<Link
							href={mail.length > 0 ? `/forgot?email=${mail}` : "/forgot"}
							children={t("forgotPassword.recoverPassword")}
							color="secondary.dark"
							sx={{ textDecoration: "none", typography: "body2", paddingLeft: 0, zIndex: 1 }}
						/>
					</Stack>
					<Box>
						<LoadingTextButton
							variant="contained"
							children={t("login.login")}
							size="large"
							fullWidth
							css={{ height: 48 }}
							onClick={submit}
							loading={loading}
						/>
						<Stack
							flexDirection={"row"}
							alignItems="center"
							justifyContent={isMd ? "flex-start" : "center"}
							sx={{ paddingTop: 3 }}
						>
							<Typography variant="body1" sx={{ color: alpha("#000000", 0.6) }}>
								{t("login.notRegisteredToCertiblok")}
							</Typography>
							<Link
								children={t("global.register")}
								href="/register"
								sx={{ typography: "link", color: alpha("#000000", 0.74), paddingLeft: 1 }}
							/>
						</Stack>
					</Box>
				</Stack>
			</AuthContainer>
		</React.Fragment>
	);
};

export default Login;
