import { useCallback, useState } from "react";
import type { ChunkError } from "../interfaces/ChunkErrors";

export function sliceArrayIntoChunks<T>(arr: T[], chunkSize: number): T[][] {
	const res = [];
	for (let i = 0; i < arr.length; i += chunkSize) {
		const chunk = arr.slice(i, i + chunkSize);
		res.push(chunk);
	}
	return res;
}

export function useChunkingRequests<T, X extends any[]>(
	chunkSize: number,
	request: (item: T, ...args: X) => Promise<any>
): {
	loading: boolean;
	errors: ChunkError<T>[];
	totalChunked: number;
	totalCount: number;
	totalSuccess: number;
	submitRequest: (dataToChunk: T[], ...args: X) => Promise<any>;
} {
	const [loading, setLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [totalChunked, setTotalChunked] = useState(0);
	const [errors, setErrors] = useState<ChunkError<T>[]>([]);

	const submitRequest = useCallback(
		async (dataToChunk: T[], ...args: X) => {
			setLoading(true);
			setTotalCount(dataToChunk.length);
			setTotalChunked(0);
			setErrors([]);

			const chunkedData = sliceArrayIntoChunks(dataToChunk, chunkSize);

			for (let data of chunkedData) {
				const chunkErrors: ChunkError<T>[] = [];
				await Promise.allSettled(
					data.map((item) =>
						request(item, ...args).catch((err) => {
							chunkErrors.push({ data: item, error: err });
						})
					)
				);
				setErrors((prev) => prev.concat(chunkErrors));
				setTotalChunked((prev) => prev + data.length);
			}

			setLoading(false);
		},
		[chunkSize, request]
	);

	return {
		loading,
		totalCount,
		totalChunked,
		totalSuccess: totalChunked - errors.length,
		errors,
		submitRequest,
	};
}
