import { LinearProgress, LinearProgressProps, Stack, SxProps, Theme, Typography, TypographyProps } from "@mui/material";

type LinearProgressWithPercentageProps = {
	percentage: number;
	containerSx?: SxProps<Theme>;
	typographyProps?: Omit<TypographyProps, "children">;
} & LinearProgressProps;

const LinearProgressWithPercentage: React.FC<LinearProgressWithPercentageProps> = ({
	percentage,
	sx,
	containerSx,
	typographyProps,
	...props
}) => {
	return (
		<Stack direction={"row"} spacing={2} alignItems="center" sx={containerSx}>
			<LinearProgress
				value={percentage}
				variant="determinate"
				sx={{
					height: 6,
					borderRadius: 1,
					flexGrow: 1,
					...sx,
				}}
				{...props}
			/>
			<Typography children={`${percentage}%`} variant={"label"} {...typographyProps} />
		</Stack>
	);
};
export default LinearProgressWithPercentage;
