"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditorApi = exports.AuditorApiFactory = exports.AuditorApiFp = exports.AuditorApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AuditorApi - axios parameter creator
 * @export
 */
const AuditorApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Close audit room
         * @param {string} id
         * @param {InputCloseAuditRoom} [inputCloseAuditRoom]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeAuditRoom: (id, inputCloseAuditRoom, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('closeAuditRoom', 'id', id);
            const localVarPath = `/audit/{id}/auditor/close`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCloseAuditRoom, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"Audit\"]
         * @summary Delete auditor file from audit room | Permission required: [\"delete\", \"Audit\"]
         * @param {string} id
         * @param {string} auditorFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditorFileFromAuditRoom: (id, auditorFileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('deleteAuditorFileFromAuditRoom', 'id', id);
            // verify required parameter 'auditorFileId' is not null or undefined
            (0, common_1.assertParamExists)('deleteAuditorFileFromAuditRoom', 'auditorFileId', auditorFileId);
            const localVarPath = `/audit/{id}/auditor/file/{auditorFileId}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"auditorFileId"}}`, encodeURIComponent(String(auditorFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit note of audit room
         * @param {string} id
         * @param {InputEditNoteOfAuditRoom} [inputEditNoteOfAuditRoom]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editNoteOfAuditRoom: (id, inputEditNoteOfAuditRoom, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('editNoteOfAuditRoom', 'id', id);
            const localVarPath = `/audit/{id}/auditor/note`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditNoteOfAuditRoom, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"create\", \"CBDocument\"]
         * @summary Upload auditor file to audit room | Permission required: [\"create\", \"CBDocument\"]
         * @param {string} id
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        uploadAuditorFileToAuditRoom: (id, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('uploadAuditorFileToAuditRoom', 'id', id);
            // verify required parameter 'file' is not null or undefined
            (0, common_1.assertParamExists)('uploadAuditorFileToAuditRoom', 'file', file);
            const localVarPath = `/audit/{id}/auditor/file/upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"create\", \"CBDocument\"]
         * @summary Upload auditor file to audit room limitless | Permission required: [\"create\", \"CBDocument\"]
         * @param {string} id
         * @param {InputUploadAuditorFileToAuditRoomLimitless} [inputUploadAuditorFileToAuditRoomLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAuditorFileToAuditRoomLimitless: (id, inputUploadAuditorFileToAuditRoomLimitless, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('uploadAuditorFileToAuditRoomLimitless', 'id', id);
            const localVarPath = `/audit/{id}/auditor/file/uploadLimitless`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputUploadAuditorFileToAuditRoomLimitless, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.AuditorApiAxiosParamCreator = AuditorApiAxiosParamCreator;
/**
 * AuditorApi - functional programming interface
 * @export
 */
const AuditorApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.AuditorApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Close audit room
         * @param {string} id
         * @param {InputCloseAuditRoom} [inputCloseAuditRoom]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeAuditRoom(id, inputCloseAuditRoom, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.closeAuditRoom(id, inputCloseAuditRoom, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"Audit\"]
         * @summary Delete auditor file from audit room | Permission required: [\"delete\", \"Audit\"]
         * @param {string} id
         * @param {string} auditorFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditorFileFromAuditRoom(id, auditorFileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAuditorFileFromAuditRoom(id, auditorFileId, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit note of audit room
         * @param {string} id
         * @param {InputEditNoteOfAuditRoom} [inputEditNoteOfAuditRoom]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editNoteOfAuditRoom(id, inputEditNoteOfAuditRoom, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editNoteOfAuditRoom(id, inputEditNoteOfAuditRoom, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"create\", \"CBDocument\"]
         * @summary Upload auditor file to audit room | Permission required: [\"create\", \"CBDocument\"]
         * @param {string} id
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        uploadAuditorFileToAuditRoom(id, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.uploadAuditorFileToAuditRoom(id, file, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"create\", \"CBDocument\"]
         * @summary Upload auditor file to audit room limitless | Permission required: [\"create\", \"CBDocument\"]
         * @param {string} id
         * @param {InputUploadAuditorFileToAuditRoomLimitless} [inputUploadAuditorFileToAuditRoomLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAuditorFileToAuditRoomLimitless(id, inputUploadAuditorFileToAuditRoomLimitless, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.uploadAuditorFileToAuditRoomLimitless(id, inputUploadAuditorFileToAuditRoomLimitless, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.AuditorApiFp = AuditorApiFp;
/**
 * AuditorApi - factory interface
 * @export
 */
const AuditorApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.AuditorApiFp)(configuration);
    return {
        /**
         *
         * @summary Close audit room
         * @param {string} id
         * @param {InputCloseAuditRoom} [inputCloseAuditRoom]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeAuditRoom(id, inputCloseAuditRoom, options) {
            return localVarFp.closeAuditRoom(id, inputCloseAuditRoom, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"Audit\"]
         * @summary Delete auditor file from audit room | Permission required: [\"delete\", \"Audit\"]
         * @param {string} id
         * @param {string} auditorFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditorFileFromAuditRoom(id, auditorFileId, options) {
            return localVarFp.deleteAuditorFileFromAuditRoom(id, auditorFileId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit note of audit room
         * @param {string} id
         * @param {InputEditNoteOfAuditRoom} [inputEditNoteOfAuditRoom]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editNoteOfAuditRoom(id, inputEditNoteOfAuditRoom, options) {
            return localVarFp.editNoteOfAuditRoom(id, inputEditNoteOfAuditRoom, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"create\", \"CBDocument\"]
         * @summary Upload auditor file to audit room | Permission required: [\"create\", \"CBDocument\"]
         * @param {string} id
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        uploadAuditorFileToAuditRoom(id, file, options) {
            return localVarFp.uploadAuditorFileToAuditRoom(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"create\", \"CBDocument\"]
         * @summary Upload auditor file to audit room limitless | Permission required: [\"create\", \"CBDocument\"]
         * @param {string} id
         * @param {InputUploadAuditorFileToAuditRoomLimitless} [inputUploadAuditorFileToAuditRoomLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAuditorFileToAuditRoomLimitless(id, inputUploadAuditorFileToAuditRoomLimitless, options) {
            return localVarFp.uploadAuditorFileToAuditRoomLimitless(id, inputUploadAuditorFileToAuditRoomLimitless, options).then((request) => request(axios, basePath));
        },
    };
};
exports.AuditorApiFactory = AuditorApiFactory;
/**
 * AuditorApi - object-oriented interface
 * @export
 * @class AuditorApi
 * @extends {BaseAPI}
 */
class AuditorApi extends base_1.BaseAPI {
    /**
     *
     * @summary Close audit room
     * @param {string} id
     * @param {InputCloseAuditRoom} [inputCloseAuditRoom]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditorApi
     */
    closeAuditRoom(id, inputCloseAuditRoom, options) {
        return (0, exports.AuditorApiFp)(this.configuration).closeAuditRoom(id, inputCloseAuditRoom, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"Audit\"]
     * @summary Delete auditor file from audit room | Permission required: [\"delete\", \"Audit\"]
     * @param {string} id
     * @param {string} auditorFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditorApi
     */
    deleteAuditorFileFromAuditRoom(id, auditorFileId, options) {
        return (0, exports.AuditorApiFp)(this.configuration).deleteAuditorFileFromAuditRoom(id, auditorFileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit note of audit room
     * @param {string} id
     * @param {InputEditNoteOfAuditRoom} [inputEditNoteOfAuditRoom]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditorApi
     */
    editNoteOfAuditRoom(id, inputEditNoteOfAuditRoom, options) {
        return (0, exports.AuditorApiFp)(this.configuration).editNoteOfAuditRoom(id, inputEditNoteOfAuditRoom, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"create\", \"CBDocument\"]
     * @summary Upload auditor file to audit room | Permission required: [\"create\", \"CBDocument\"]
     * @param {string} id
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AuditorApi
     */
    uploadAuditorFileToAuditRoom(id, file, options) {
        return (0, exports.AuditorApiFp)(this.configuration).uploadAuditorFileToAuditRoom(id, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"create\", \"CBDocument\"]
     * @summary Upload auditor file to audit room limitless | Permission required: [\"create\", \"CBDocument\"]
     * @param {string} id
     * @param {InputUploadAuditorFileToAuditRoomLimitless} [inputUploadAuditorFileToAuditRoomLimitless]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditorApi
     */
    uploadAuditorFileToAuditRoomLimitless(id, inputUploadAuditorFileToAuditRoomLimitless, options) {
        return (0, exports.AuditorApiFp)(this.configuration).uploadAuditorFileToAuditRoomLimitless(id, inputUploadAuditorFileToAuditRoomLimitless, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AuditorApi = AuditorApi;
