import { Avatar, Badge, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import avatarPlaceholder from "../../../../../../assets/avatar-placeholder.png";
import { isUserOrContact } from "../../../../../../pages/Folders/components/UploadDocument/functions/isUser";
import { useModalNavigator } from "../../../../ModalNavigator/ModalNavigator";
import UserChip from "../../../../UserChip";
import { useUploadDocumentContext } from "../../../contexts/UploadDocumentContext";
import { useEditUploadedFileDraft, useUploadedFileDraftById } from "../../../stores/uploadedFilesStore";
import ChipsGrid from "../../ChipsGrid";
import ShareUploadedDocument from "../../ShareUploadedDocument/ShareUploadedDocument";
import { TitleWithTextField } from "./TitleWithTextField";

type UploadedDocumentSharesProps = {
	documentId: string;
};
const UploadedDocumentShares: React.FC<UploadedDocumentSharesProps> = ({ documentId }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const editUploadedDocumentDraft = useEditUploadedFileDraft();
	const { pushBottomSheet } = useModalNavigator();
	const { mainBottomSheetRef } = useUploadDocumentContext();

	const uploadedDocumentDraft = useUploadedFileDraftById(documentId);
	const shares = uploadedDocumentDraft.shares?.usersToShareWith ?? [];

	return (
		<div className="">
			<TitleWithTextField
				title={t("uploadDocument.shareWithContacts")}
				placeholder="mariorossi@certiblok.com"
				onClick={() => {
					pushBottomSheet({
						component: ShareUploadedDocument,
						ref: mainBottomSheetRef.current,
						props: {
							documentId,
						},
					});
				}}
			/>
			{shares.length > 0 && (
				<ChipsGrid
					data={shares}
					renderChip={(elem) => (
						<div key={isUserOrContact(elem) ? elem.id : elem} className="max-w-full overflow-hidden">
							<UserChip
								deletable
								user={elem}
								onDelete={() => {
									editUploadedDocumentDraft(documentId, {
										shares: {
											...uploadedDocumentDraft.shares!,
											usersToShareWith: shares.filter((e) =>
												isUserOrContact(e) && isUserOrContact(elem) ? e.id !== elem.id : e !== elem
											),
										},
									});
								}}
							/>
						</div>
					)}
					renderMoreLabel={(count) => (
						<Badge
							badgeContent={`+${count}`}
							color="secondary"
							overlap="circular"
							sx={{
								pr: 2,
								"& .MuiBadge-badge": {
									right: 4,
									top: 16,
									border: `2px solid ${theme.palette.background.paper}`,
									padding: "0 4px",
								},
							}}
						>
							<Avatar alt={`avatar`} src={avatarPlaceholder} sx={{ width: 32, height: 32 }} />
						</Badge>
					)}
				/>
			)}
		</div>
	);
};
export default UploadedDocumentShares;
