import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PlanApi, PlanUsage } from "certiblok-api-manager";
import { useCallback } from "react";
import { plansQueryKeys } from "../../../../../../../../pages/Profile/components/admin/queries/plansQueries";
import { apiErrorParser, useApi } from "../../../../../../../hooks/api";
import { useAbility } from "../../../../../../../hooks/useAbilityRules";
import MLDialog from "../../../../../../poppers";
import type { AxiosResponse } from "axios";

export function usePlanUsage() {
	const planApi = useApi(PlanApi);
	const { ability } = useAbility();
	const canViewUsage = ability.can("update", "Company");
	const canReadCompany = ability.can("readAll", "Company");

	const queryClient = useQueryClient();

	const {
		data: planUsage,
		isLoading: planUsageLoading,
		refetch: revalidatePlanUsage,
	} = useQuery({
		queryKey: plansQueryKeys.usage(),
		queryFn: () => planApi.getCurrentUsage(),
		enabled: canReadCompany,
		select: (data) => data?.data,
		onError: (err: any) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});

	const mutatePlanUsageOptimistic = useCallback(
		(newUsage: Partial<PlanUsage>) => {
			queryClient.setQueryData(plansQueryKeys.usage(), (oldUsage: AxiosResponse<PlanUsage> | undefined) => {
				if (!oldUsage) return undefined;
				return { ...oldUsage, data: { ...(oldUsage?.data ?? {}), ...newUsage } };
			});
		},
		[queryClient]
	);

	return { canViewUsage, planUsage, planUsageLoading, mutatePlanUsageOptimistic, revalidatePlanUsage };
}
