"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvitationApi = exports.InvitationApiFactory = exports.InvitationApiFp = exports.InvitationApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * InvitationApi - axios parameter creator
 * @export
 */
const InvitationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get all company invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyInvitations: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/invitation/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get member invitation
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberInvitation: (token, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'token' is not null or undefined
            (0, common_1.assertParamExists)('getMemberInvitation', 'token', token);
            const localVarPath = `/invitation/handle/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Review invitation
         * @param {InputReviewInvitation} [inputReviewInvitation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewInvitation: (inputReviewInvitation, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/invitation/handle/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputReviewInvitation, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Send invitation
         * @param {InvitationCreateEditInput} [invitationCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvitation: (invitationCreateEditInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/invitation/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(invitationCreateEditInput, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Withdraw invitation
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawInvitation: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('withdrawInvitation', 'id', id);
            const localVarPath = `/invitation/{id}/withdraw`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.InvitationApiAxiosParamCreator = InvitationApiAxiosParamCreator;
/**
 * InvitationApi - functional programming interface
 * @export
 */
const InvitationApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.InvitationApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get all company invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyInvitations(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllCompanyInvitations(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get member invitation
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberInvitation(token, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMemberInvitation(token, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Review invitation
         * @param {InputReviewInvitation} [inputReviewInvitation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewInvitation(inputReviewInvitation, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.reviewInvitation(inputReviewInvitation, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Send invitation
         * @param {InvitationCreateEditInput} [invitationCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvitation(invitationCreateEditInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sendInvitation(invitationCreateEditInput, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Withdraw invitation
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawInvitation(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.withdrawInvitation(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.InvitationApiFp = InvitationApiFp;
/**
 * InvitationApi - factory interface
 * @export
 */
const InvitationApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.InvitationApiFp)(configuration);
    return {
        /**
         *
         * @summary Get all company invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyInvitations(options) {
            return localVarFp.getAllCompanyInvitations(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get member invitation
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberInvitation(token, options) {
            return localVarFp.getMemberInvitation(token, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Review invitation
         * @param {InputReviewInvitation} [inputReviewInvitation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewInvitation(inputReviewInvitation, options) {
            return localVarFp.reviewInvitation(inputReviewInvitation, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Send invitation
         * @param {InvitationCreateEditInput} [invitationCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvitation(invitationCreateEditInput, options) {
            return localVarFp.sendInvitation(invitationCreateEditInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Withdraw invitation
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawInvitation(id, options) {
            return localVarFp.withdrawInvitation(id, options).then((request) => request(axios, basePath));
        },
    };
};
exports.InvitationApiFactory = InvitationApiFactory;
/**
 * InvitationApi - object-oriented interface
 * @export
 * @class InvitationApi
 * @extends {BaseAPI}
 */
class InvitationApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get all company invitations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    getAllCompanyInvitations(options) {
        return (0, exports.InvitationApiFp)(this.configuration).getAllCompanyInvitations(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get member invitation
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    getMemberInvitation(token, options) {
        return (0, exports.InvitationApiFp)(this.configuration).getMemberInvitation(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Review invitation
     * @param {InputReviewInvitation} [inputReviewInvitation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    reviewInvitation(inputReviewInvitation, options) {
        return (0, exports.InvitationApiFp)(this.configuration).reviewInvitation(inputReviewInvitation, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Send invitation
     * @param {InvitationCreateEditInput} [invitationCreateEditInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    sendInvitation(invitationCreateEditInput, options) {
        return (0, exports.InvitationApiFp)(this.configuration).sendInvitation(invitationCreateEditInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Withdraw invitation
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    withdrawInvitation(id, options) {
        return (0, exports.InvitationApiFp)(this.configuration).withdrawInvitation(id, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.InvitationApi = InvitationApi;
