import { alpha, Box, IconButton, Stack, SxProps, Theme, Typography } from "@mui/material";
import { Team } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";

type TeamInFolderProps = {
	team: Team;
	endIcon?: "delete" | "eye";
	onEndIconClick?: () => void;
	sx?: SxProps<Theme>;
	excludedFromParent?: boolean;
};

const TeamInFolder: React.FC<TeamInFolderProps> = ({ team, sx, excludedFromParent, endIcon, onEndIconClick }) => {
	const { t } = useTranslation();

	return (
		<Stack
			direction={"row"}
			alignItems={"center"}
			gap={2}
			sx={{
				backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.06),
				px: 3,
				py: 1,
				borderRadius: 3,
				minHeight: 48,
				...sx,
			}}
		>
			<Box
				sx={{
					width: 24,
					height: 24,
					...(excludedFromParent && {
						opacity: 0.38,
					}),
					borderRadius: "100%",
					backgroundColor: team.color,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexShrink: 0,
				}}
			>
				<CertiblokIcon name="profile_outline_01" color="white" size={20} />
			</Box>
			<Typography
				children={team.name}
				variant="body1"
				sx={{
					marginRight: "auto",
					...(excludedFromParent && {
						opacity: 0.38,
					}),
				}}
			/>

			<Typography
				children={t("teamGroups.nMembers", { count: team?.userIds?.length ?? 0 })}
				variant="label"
				color="secondary"
				noWrap
				sx={{
					...(excludedFromParent && {
						opacity: 0.38,
					}),
				}}
			/>
			{endIcon ? (
				<IconButton color="secondary" onClick={onEndIconClick}>
					<CertiblokIcon
						name={endIcon === "delete" ? "canc" : !excludedFromParent ? "eye_bold_02" : "eye_bold_01"}
						color="inherit"
					/>
				</IconButton>
			) : (
				<Box sx={{ width: 40, height: 40, flexShrink: 0 }} />
			)}
		</Stack>
	);
};

export default TeamInFolder;
