import type { User } from "certiblok-api-manager";
import { useEffect, useRef } from "react";
import io from "socket.io-client";
import MLDialog from "../components/poppers";
import { useMe } from "./useMe";

const mainUrl = process.env.REACT_APP_BACKEND_BASE_URL as string;

let socket: ReturnType<typeof io>;

export default function useSocket(eventName: string, cb: any, fallbackMe?: User) {
	const { me } = useMe();

	const userId = me?.id ?? fallbackMe?.id;

	const isDisconnectedRef = useRef(false);
	const timeout = useRef<NodeJS.Timeout | undefined>();

	const disconnectEvent = () => {
		timeout.current = setTimeout(() => {
			if (socket?.disconnected) {
				isDisconnectedRef.current = true;
				MLDialog.showSnackbar("You are offline! Please try again when you are online", {
					variant: "error",
					persist: true,
					key: "disconnect_snackbar",
				});
			}
		}, 10000);
	};
	const connectEvent = () => {
		clearTimeout(timeout.current);
		if (isDisconnectedRef.current) {
			window.location.reload();
			MLDialog.hideSnackbar("disconnect_snackbar");
		}
	};

	useEffect(() => {
		if (userId && !socket) {
			socket = io(mainUrl, { path: "/socket", auth: { id: userId } });

			socket.on("connect", connectEvent);
			socket.on("disconnect", disconnectEvent);
		}

		return () => {
			socket?.off("connect", connectEvent);
			socket?.off("disconnect", disconnectEvent);
		};
	}, [userId]);

	useEffect(() => {
		if (eventName && socket && cb) {
			socket.on(eventName, cb);
		}
		return () => {
			socket?.off(eventName, cb);
		};
	}, [eventName, cb]);

	return socket;
}
