import { Button, ButtonProps, Fade } from "@mui/material";
import { Box } from "@mui/system";
import type React from "react";

interface Props extends ButtonProps {
	open: boolean;
	icon: React.ReactNode;
}

const CollapsableButton: React.FC<Props> = (props) => {
	const { open, icon, children, ...otherProps } = { ...props };

	return (
		<Button {...otherProps}>
			<Box
				sx={{
					position: "absolute",
					width: "100%",
					height: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: 2,
				}}
			>
				{icon}
				<Fade in={open} mountOnEnter unmountOnExit exit={false}>
					<div>{children}</div>
				</Fade>
			</Box>
		</Button>
	);
};

export default CollapsableButton;
