import {
	alpha,
	Box,
	CircularProgress,
	circularProgressClasses,
	CircularProgressProps,
	Stack,
	SxProps,
	Theme,
	Typography,
} from "@mui/material";
import { theme } from "../../theme";

type CircularProgessWithPercentageProps = {
	percentage: number;
	editRoomPercentage?: number;
	containerSx?: SxProps<Theme>;
} & CircularProgressProps;
const CircularProgessWithPercentage: React.FC<CircularProgessWithPercentageProps> = ({
	percentage,
	editRoomPercentage,
	containerSx,
	sx,
	...props
}) => {
	return (
		<Box sx={{ position: "relative", height: props.size, width: props.size, ...containerSx }}>
			<Stack alignItems={"center"} sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
				<CircularProgress
					variant="determinate"
					sx={{
						color: alpha("#000000", 0.06),
					}}
					{...props}
					value={100}
				/>
				<CircularProgress
					variant="determinate"
					value={percentage - (editRoomPercentage ?? 0)}
					sx={{
						position: "absolute",
						[`& .${circularProgressClasses.circle}`]: {
							strokeLinecap: "round",
						},
						zIndex: 2,
						...sx,
					}}
					{...props}
				/>
				{Boolean(editRoomPercentage) && (
					<CircularProgress
						variant="determinate"
						value={percentage}
						sx={{
							position: "absolute",
							[`& .${circularProgressClasses.circle}`]: {
								strokeLinecap: "round",
							},
							...sx,
							zIndex: 1,
							color: theme.palette.orange.main,
						}}
						{...props}
					/>
				)}
			</Stack>
			<Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "100%", height: "100%" }}>
				<Typography variant="body2">{`${Math.round(percentage)}%`}</Typography>
			</Stack>
		</Box>
	);
};
export default CircularProgessWithPercentage;
