/** @jsxImportSource @emotion/react */
import { Button, Typography } from "@mui/material";
import { SearchResultTypeEnum } from "certiblok-api-manager";
import { Fragment, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import CustomAutocomplete from "../../CustomAutocomplete";
import CertiblokIcon, { CertiblokIconNames } from "../../icons/CertiblokIcon";
import LinkBehavior from "../../LinkBehavior";
import NoContents from "../../NoContents";
import { useGlobalAutocomplete } from "../controllers/useGlobalAutocomplete";
import GlobalAutocompleteItem from "./GlobalAutocompleteItem";

const MoreButton: React.FC<{
	type: SearchResultTypeEnum;
	count: number;
	currentInput: string;
}> = ({ type, count, currentInput }) => {
	const { t } = useTranslation();
	let icon: CertiblokIconNames;
	let hrefSection = "";
	switch (type) {
		case "document":
			icon = "paper_outline_02";
			hrefSection = "section=document";
			break;
		case "folder":
			icon = "folder_outline";
			hrefSection = "section=folder";
			break;
		case "audit_room":
			icon = "profile_outline_01";
			hrefSection = "section=audit_room";
			break;
		case "contact":
			icon = "call_outline_03";
			hrefSection = "section=contact";
			break;
		case "tag":
			icon = "tickets_outline_02";
			hrefSection = "section=tag";
	}

	return (
		<Button
			color="secondary"
			fullWidth
			component={LinkBehavior}
			href={`search?filter=${currentInput}&${hrefSection}`}
			sx={{ width: "100%", justifyContent: "space-between" }}
		>
			{/* @ts-ignore */}
			<CertiblokIcon size={24} color={"secondary"} name={icon} iconCss={{ width: 24, flexShrink: 0 }} />
			<Typography
				children={`${type === "audit_room" ? t("global.seeOthersFemale") : t("global.seeOthersMale")} ${count}`}
				noWrap
				variant="body2"
				sx={{ width: `calc(100% - ${24 * 2 + 8 * 2}px)`, flexShrink: 0 }}
			/>
			<CertiblokIcon
				size={24}
				color="secondary"
				name={"arrow_mini_outline_right"}
				iconCss={{ width: 24, flexShrink: 0 }}
			/>
		</Button>
	);
};

const GlobalAutocomplete = () => {
	const globalAutocomplete = useGlobalAutocomplete();

	const itemsCount = useMemo(() => {
		return globalAutocomplete.items.reduce((acc, curr) => {
			return acc + curr.items.length + (curr.others > 0 ? 1 : 0);
		}, 0);
	}, [globalAutocomplete.items]);

	const currentSelectedIndex = useRef(-1);

	const arrowEventListener = useCallback(
		(e: KeyboardEvent) => {
			if (e.code === "ArrowUp" || e.code === "ArrowDown") {
				if (e.code === "ArrowUp") {
					if (currentSelectedIndex.current === 0) {
						currentSelectedIndex.current = itemsCount - 1;
					} else {
						currentSelectedIndex.current--;
					}
				} else {
					if (currentSelectedIndex.current === itemsCount - 1) {
						currentSelectedIndex.current = 0;
					} else {
						currentSelectedIndex.current++;
					}
				}
				const currentButton = document
					.getElementById(`global-autocomplete-${currentSelectedIndex.current}`)
					?.children.item(0);
				if (currentButton) {
					const currentHtmlButton = currentButton as HTMLElement;
					currentHtmlButton.focus();
				}
			}
		},
		[itemsCount]
	);

	useEffect(() => {
		const currentButton = document.getElementById(`global-autocomplete-${currentSelectedIndex.current}`);
		currentButton?.firstElementChild?.classList.remove("Mui-focusVisible");
		currentSelectedIndex.current = -1;
		if (globalAutocomplete.items.length > 0) {
			window.addEventListener("keydown", arrowEventListener);
		} else {
			window.removeEventListener("keydown", arrowEventListener);
		}

		return () => {
			window.removeEventListener("keydown", arrowEventListener);
		};
	}, [arrowEventListener, globalAutocomplete.items]);

	const { t } = useTranslation();

	return (
		<CustomAutocomplete
			deletable
			hasBackdrop
			isDummyTextField
			size="small"
			options={globalAutocomplete.items}
			label={""}
			id={"title"}
			getOptionLabel={(elem) => elem.title ?? ""}
			customRenderOption={(props, option, state) => {
				return (
					<Fragment key={option.title}>
						<li>
							<Typography children={option.title} variant="overline" sx={{ opacity: 0.38, mx: 1, color: "black" }} />
						</li>
						{option.items.length > 0 ? (
							option.items.map((elem, index) => {
								const currentSectionIndex = globalAutocomplete.items.findIndex((elem) => elem.title === option.title);
								const prevSectionsCount = globalAutocomplete.items.reduce((acc, elem, currentIndex) => {
									if (currentIndex >= currentSectionIndex) return acc;
									return acc + elem.items.length + (elem.others > 0 ? 1 : 0);
								}, 0);
								const currentIndex = prevSectionsCount + index;
								return (
									<Fragment key={`${option.title}-${elem?.id}-${index}`}>
										<li
											role="option"
											aria-disabled="false"
											aria-selected="false"
											tabIndex={0}
											id={`global-autocomplete-${currentIndex}`}
										>
											{/* @ts-ignore */}
											<GlobalAutocompleteItem type={option.type} item={elem} />
										</li>
										{option.others > 0 && index === option.items.length - 1 && (
											<li
												role="option"
												aria-disabled="false"
												aria-selected="false"
												tabIndex={0}
												id={`global-autocomplete-${currentIndex + 1}`}
											>
												<MoreButton type={option.type} count={option.others} currentInput={globalAutocomplete.currentInput} />
											</li>
										)}
										{/* {index === option.items.length - 1 && currentSectionIndex < globalAutocomplete.items.length - 1 && (
											<Divider sx={{ marginTop: 3, marginX: 1 }} />
										)} */}
									</Fragment>
								);
							})
						) : (
							<NoContents
								sx={{ flexDirection: "row", alignItems: "center", gap: 2, py: 1 }}
								variant={"paper"}
								title={t("global.noResult")}
								iconCss={{ height: 24, width: 24 }}
								typographyProps={{ variant: "caption" }}
							/>
						)}
					</Fragment>
				);
			}}
			onInputChange={globalAutocomplete.onInputChange}
			loading={globalAutocomplete.loading}
			containerSx={{ flexGrow: 1, maxWidth: 360 }}
			externalTextInputProps={{
				sx: { backgroundColor: "white" },
				endAdornment: <CertiblokIcon size={16} color="black" name={"search_outline"} />,
			}}
			onEnterPress={(ev) => {
				ev.stopPropagation();
				const currentButton = document
					.getElementById(`global-autocomplete-${currentSelectedIndex.current}`)
					?.children.item(0);
				if (currentButton) {
					const currentHtmlButton = currentButton as HTMLElement;
					currentHtmlButton.click();
				}
			}}
		/>
	);
};

export default GlobalAutocomplete;
