/** @jsxImportSource @emotion/react */

import { Box, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import Calendar, { Detail } from "react-calendar";
import { pxToRem } from "../../functions/theme";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import { useSelectedLanguage } from "../../hooks/useLanguageSwitcher";
import CertiblokIcon from "../icons/CertiblokIcon";

type MobileCalendarProps = {
	date?: DateTime;
	disabledBefore?: DateTime;
	prevSelectedDate?: DateTime;
	onDateChange: (date: DateTime) => void;
};

function switchViewType(view: Detail): "day" | "month" | "year" | undefined {
	switch (view) {
		case "year":
			return "month";
		case "month":
			return "day";
		case "decade":
			return "year";
		default:
			return undefined;
	}
}

const MobileCalendar: React.FC<MobileCalendarProps> = ({ date, disabledBefore, prevSelectedDate, onDateChange }) => {
	const theme = useTheme();

	const isSm = useBetterMediaQuery("sm");

	const selectedLocale = useSelectedLanguage();

	return (
		<Calendar
			locale={selectedLocale}
			value={date?.toJSDate()}
			onClickDay={(date) => {
				const dateTimeDate = DateTime.fromJSDate(date);
				onDateChange(dateTimeDate);
			}}
			tileDisabled={(date) => {
				const startOf = switchViewType(date.view);
				if (disabledBefore && startOf) {
					return DateTime.fromJSDate(date.date).startOf(startOf) < disabledBefore.startOf(startOf);
				}
				return false;
			}}
			next2Label={null}
			prev2Label={null}
			navigationLabel={(props) => {
				return (
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
						<Typography children={props.label.capitalize()} variant="body2" />
						<CertiblokIcon size={12} color="black" name={"arrow_mini_outline_down"} />
					</Box>
				);
			}}
			prevLabel={<CertiblokIcon size={24} color="inherit" name={"arrow_mini_outline_left"} />}
			nextLabel={<CertiblokIcon size={24} color="inherit" name={"arrow_mini_outline_right"} />}
			tileClassName={(tile) => {
				const startOf = switchViewType(tile.view);
				return disabledBefore &&
					startOf &&
					DateTime.fromJSDate(tile.date).startOf(startOf) < disabledBefore.startOf(startOf)
					? "react-calendar__tile--disabled"
					: "react-calendar__tile";
			}}
			css={{
				borderRadius: 16,
				margin: "24px 0px",
				padding: 16,
				boxShadow: "0px 1px 12px 0px #0000001F;",
				minHeight: 300,
				width: 268,
				...(!isSm && {
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}),
				"& .react-calendar__navigation": {
					display: "flex",
					paddingBottom: 10,
					alignItems: "center",
					width: "100%",
				},
				"& .react-calendar__viewContainer": {
					// width: 285,
				},
				"& .react-calendar__navigation__label": {
					backgroundColor: "unset",
					border: "unset",
					cursor: "pointer",
					textAlign: "start",
					padding: 3,
					paddingLeft: 0,
					color: "black",
				},
				"& .react-calendar__navigation__arrow": {
					color: "black",
					backgroundColor: "unset",
					border: "unset",
					cursor: "pointer",
					padding: 3,
					height: 30,
					borderRadius: 15,
					transition: theme.transitions.create(["background-color", "border-color", "color"]),
					"&:hover": {
						color: "white",
						backgroundColor: "#3B95A8",
					},
				},
				"& .react-calendar__tile": {
					color: "black",
					padding: 0,
					flex: "unset !important",
					margin: 1,
					width: 36,
					height: 36,
					textAlign: "center",
					fontWeight: 400,
					fontSize: pxToRem(20),
					borderRadius: 8,
					borderStyle: "solid",
					backgroundColor: "unset",
					borderColor: "transparent",
					boxSizing: "border-box",
					cursor: "pointer",
					transition: theme.transitions.create(["background-color", "border-color", "color"]),
					"&--disabled": {
						color: "rgba(0, 0, 0, 0.12) !important",
						backgroundColor: "unset !important",
						cursor: "initial",
						"&:hover": {
							color: "rgba(0, 0, 0, 0.12)  !important",
						},
					},
					"&:hover": {
						backgroundColor: "#3B95A8",
					},
					"&--active": {
						color: "white !important",
						backgroundColor: `${theme.palette.secondary.main} !important`,
						borderColor: "rgba(255, 255, 255, 0.38)",
					},
					"&--now": {
						backgroundColor: "white",
						color: theme.palette.magenta.main,
						borderColor: theme.palette.secondary.main,
					},
				},
				"& .react-calendar__tile__with__events": {
					position: "relative",
					overflow: "unset !important",
					"::after": {
						content: '""',
						width: 30,
						height: 1,
						backgroundColor: theme.palette.magenta.main,
						position: "absolute",
						bottom: -4,
						left: 0,
					},
				},
				"& .react-calendar__month-view__days__day--neighboringMonth": {
					color: "rgba(0, 0, 0, 0.38)",
					backgroundColor: "transparent",
					borderColor: "transparent",
				},
				"& .react-calendar__month-view__weekdays": {
					paddingBottom: 10,
					alignItems: "center",
				},
				"& .react-calendar__month-view__days": {
					gap: "8px 0px",
					alignItems: "center",
				},
				"& .react-calendar__month-view__weekdays__weekday": {
					fontFamily: theme.typography.body2.fontFamily,
					flex: "unset !important",
					width: 38,
					textAlign: "center",
					textTransform: "capitalize",
					fontWeight: 400,
					fontSize: pxToRem(14),
					color: "rgba(0, 0, 0, 0.54)",
					"abbr[title]": {
						textDecoration: "none",
					},
				},
				"& .react-calendar__year-view__months, .react-calendar__decade-view__years, .react-calendar__century-view": {
					"& .react-calendar__tile": {
						width: "unset",
						flexBasis: "50% !important",
						borderRadius: 20,
						margin: 0,
						// borderColor: theme.palette.secondary.main,
						"&::after": {
							width: 0,
							height: 0,
						},
						"&:hover": {
							color: "white !important",
						},
						"&--now": {
							color: `${theme.palette.magenta.main} !important`,
						},
					},
				},
			}}
		/>
	);
};
export default MobileCalendar;
