import { Button, Tooltip, Typography } from "@mui/material";
import type { Folder, Tag } from "certiblok-api-manager";
import { DateTime } from "luxon";
import React from "react";
import type { Contact } from "../../../../pages/Contacts/interfaces/Contact";
import type { AuditRoom } from "../../../interfaces/AuditRoom";
import type { CertiblokDocument } from "../../../interfaces/Document";
import ContactWithName from "../../ContactWithName";
import DocumentWithIcon from "../../DocumentWithIcon";
import CertiblokIcon from "../../icons/CertiblokIcon";
import LinkBehavior from "../../LinkBehavior";

type Props =
	| { type: "document"; item: CertiblokDocument }
	| { type: "folder"; item: Folder }
	| { type: "audit_room"; item: AuditRoom }
	| { type: "contact"; item: Contact }
	| { type: "tag"; item: Tag };

const GlobalAutocompleteItem = React.forwardRef<any, Props>((props, ref) => {
	switch (props.type) {
		case "document":
			return (
				<Tooltip title={props.item.name} enterDelay={500}>
					<Button
						color="secondary"
						component={LinkBehavior}
						href={`folders/${props.item.parentFolderId}/document/${props.item.id}`}
						sx={{ width: "100%", justifyContent: "space-between" }}
					>
						<DocumentWithIcon
							name={props.item.name}
							document={props.item.lastVersionFile?.[0]}
							sx={{ color: "black", width: `calc(100% - ${24 + 8}px)`, flexShrink: 0, gap: 2 }}
						/>
						<CertiblokIcon size={24} color="black" name={"arrow_mini_outline_right"} iconCss={{ width: 24, flexShrink: 0 }} />
					</Button>
				</Tooltip>
			);
		case "folder":
			return (
				<Tooltip title={props.item.name} enterDelay={500}>
					<Button
						color="secondary"
						component={LinkBehavior}
						href={`folders/${props.item.id}`}
						sx={{ width: "100%", justifyContent: "space-between" }}
					>
						<CertiblokIcon size={24} color={props.item.color} name={"folder_bold"} iconCss={{ width: 24, flexShrink: 0 }} />
						<Typography
							children={props.item.name}
							noWrap
							variant="body2"
							sx={{ width: `calc(100% - ${24 * 2 + 8 * 2}px)`, flexShrink: 0, color: props.item.color ?? "black" }}
						/>
						<CertiblokIcon size={24} color="black" name={"arrow_mini_outline_right"} iconCss={{ width: 24, flexShrink: 0 }} />
					</Button>
				</Tooltip>
			);
		case "audit_room":
			return (
				<Tooltip title={props.item.name} enterDelay={500}>
					<Button
						color="secondary"
						component={LinkBehavior}
						href={
							props.item.endDate
								? DateTime.fromISO(props.item.endDate) >= DateTime.now()
									? `audit_rooms/${props.item.id}`
									: `audit_rooms/archive/${props.item.id}`
								: `audit_rooms/${props.item.id}`
						}
						sx={{ width: "100%", justifyContent: "space-between" }}
					>
						<CertiblokIcon
							size={24}
							color={props.item.color}
							name={"profile_bold_01"}
							iconCss={{ width: 24, flexShrink: 0 }}
						/>
						<Typography
							children={props.item.name}
							noWrap
							variant="body2"
							sx={{ width: `calc(100% - ${24 * 2 + 8 * 2}px)`, flexShrink: 0, color: props.item.color ?? "black" }}
						/>
						<CertiblokIcon size={24} color="black" name={"arrow_mini_outline_right"} iconCss={{ width: 24, flexShrink: 0 }} />
					</Button>
				</Tooltip>
			);
		case "contact":
			return (
				<Tooltip title={props.item.name} enterDelay={500}>
					<Button
						color="secondary"
						component={LinkBehavior}
						href={`contacts/${props.item.id}`}
						sx={{ width: "100%", justifyContent: "space-between" }}
					>
						<ContactWithName
							name={props.item.name}
							surname={props.item.surname}
							profileUrl={props.item.profileUrl}
							variant="body2"
							typographySx={{ color: "black" }}
						/>
						<CertiblokIcon size={24} color="black" name={"arrow_mini_outline_right"} iconCss={{ width: 24, flexShrink: 0 }} />
					</Button>
				</Tooltip>
			);
		case "tag":
			return null;
	}
});

export default GlobalAutocompleteItem;
