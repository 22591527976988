/** @jsxImportSource @emotion/react */
import type { CSSObject } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import type { AuditorFile, CBFile } from "certiblok-api-manager";
import mime from "mime";
import excelIcon from "../../../assets/file_icons/excel.png";
import pptIcon from "../../../assets/file_icons/ppt.png";
import wordIcon from "../../../assets/file_icons/word.png";
import CertiblokIcon from "./CertiblokIcon";

type Props = {
	file?: CBFile | File | AuditorFile;
	dataUrl?: string;
	iconCss?: CSSObject;
};

const FileIcon: React.FC<Props> = ({ file, dataUrl, iconCss = {} }) => {
	if (!file) {
		return <CertiblokIcon size={24} color="primary" name={"paper_bold_02"} iconCss={{ ...iconCss }} />;
	}

	const type = file?.type || "";

	if (
		type.includes("vnd.openxmlformats-officedocument.wordprocessingml.document") ||
		type.includes("msword") ||
		type.includes("opendocument.text")
	) {
		return <img src={wordIcon} alt="Word" css={{ width: 24, height: 24 }} />;
	}
	if (
		type.includes("ms-excel") ||
		type.includes("spreadsheetml") ||
		type.includes("application/vnd.oasis.opendocument.spreadsheet")
	) {
		return <img src={excelIcon} alt="Excel" css={{ width: 24, height: 24 }} />;
	}
	if (
		type.includes("presentation") ||
		type.includes("ms-powerpoint") ||
		type.includes("application/vnd.oasis.opendocument.presentation") ||
		type.includes("application/vnd.openxmlformats-officedocument.presentationml.presentation")
	) {
		return <img src={pptIcon} alt="PowerPoint" css={{ width: 24, height: 24 }} />;
	}
	// if (file.type.includes("image")) {
	// 	return <img src={dataUrl} alt="" css={{ width: 24, height: 24, borderRadius: 12, objectFit: "cover" }} />;
	// }
	return (
		<Box sx={{ position: "relative", height: 24, width: 24 }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
				<g>
					<path
						fill={type.includes("pdf") ? "#DD1919" : "#43A9BF"}
						d="M18.817 9.03c.14 0 .323-.002.521-.004.233-.003.486-.006.716-.006.248 0 .446.2.446.45v8.04c0 2.48-1.99 4.49-4.445 4.49H8.173C5.6 22 3.5 19.89 3.5 17.29V6.51C3.5 4.03 5.5 2 7.965 2h5.287c.258 0 .456.21.456.46v3.22c0 1.83 1.495 3.33 3.307 3.34.423 0 .796.003 1.122.006h.001c.254.002.48.004.679.004zm.794-1.464a.477.477 0 00.34-.807l-1.981-2.08-2.005-2.107c-.296-.311-.814-.097-.814.334v2.636c0 1.106.902 2.017 1.997 2.017.69.007 1.65.01 2.463.007z"
					></path>
				</g>
			</svg>
			<Typography
				children={
					mime.getExtension(type)?.toUpperCase() ??
					//@ts-ignore
					(file?.name ?? file?.filename)
						?.split(".")
						.filter((value: string, index: number, array: string[]) => index === array.length - 1)
				}
				sx={{
					width: "100%",
					height: "100%",
					color: "white",
					fontWeight: 700,
					position: "absolute",
					top: 4,
					left: 0,
					fontSize: 7,
					textAlign: "center",
					textTransform: "uppercase",
				}}
			/>
		</Box>
	);
};

export default FileIcon;
