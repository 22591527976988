import { Autocomplete } from "@mabi-ui/autocomplete";
import { IconButton, MenuItem, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AttendeeRoleEnum, SearchApi, UserOrContact } from "certiblok-api-manager";
import clsx from "clsx";
import throttle from "lodash/throttle";
import { useCallback, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import isEmail from "validator/lib/isEmail";
import avatarPlaceholder from "../../../../../assets/avatar-placeholder.png";
import { backendMediaUrlParser, getFullName } from "../../../../../utils";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import MLDialog from "../../../../../utils/components/poppers";
import { instanceApi } from "../../../../../utils/hooks/api";
import { EditCreateEventForm } from "../hooks/useEditCreateEventFormValidator";
import { CustomizationSelect } from "./CustomizationSelect";

function isUserOrContact(value: any): value is UserOrContact {
	return (value as UserOrContact)?.email !== undefined;
}

type EditCreateEventSharedWithProps = {
	className?: string;
};
const EditCreateEventSharedWith: React.FC<EditCreateEventSharedWithProps> = ({ className }) => {
	const { t } = useTranslation();

	const [filter, setFilter] = useState("");
	const [throttledFilter, setThrottledFilter] = useState("");
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const throtthledFilterChange = useCallback(
		throttle(
			(value: string) => {
				setThrottledFilter(value);
			},
			500,
			{ trailing: true, leading: false }
		),
		[]
	);

	const {
		data: availableUsers,
		isFetching: isFetchingAvailableUsers,
		isLoading: isLoadingAvailableUsers,
	} = useQuery({
		queryKey: ["contacts", throttledFilter],
		queryFn: async () => {
			let data: (UserOrContact | { id: string; value: string })[] = [];
			if ((throttledFilter?.length || 0) < 3) return data;
			const fetchedData = (await instanceApi(SearchApi).searchUsersOrContacts(throttledFilter ?? "")).data;
			return fetchedData;
		},
		keepPreviousData: true,
	});

	const { control } = useFormContext<EditCreateEventForm>();

	const { append, fields, update } = useFieldArray({
		control,
		name: "sharedWith",
	});

	return (
		<div className={twMerge(clsx("flex flex-col gap-1 md:h-full md:overflow-hidden px-1 box-border "), className)}>
			<Autocomplete
				label={t("events.shareWith")}
				options={
					availableUsers?.length === 0 && !isFetchingAvailableUsers && filter.length > 0
						? [
								{
									id: filter,
									value: filter,
								},
						  ]
						: availableUsers ?? []
				}
				onInputChange={(value) => {
					setFilter(value);
					throtthledFilterChange(value);
				}}
				emptyListContent={
					<div className="h-[46px] flex items-center justify-start">
						{!isLoadingAvailableUsers && (
							<Typography className="px-[9px] py-[10px] !text-body1">{t("events.noUserFound")}</Typography>
						)}
					</div>
				}
				onChange={(
					value:
						| UserOrContact
						| {
								id: string;
								value: string;
						  }
						| null
				) => {
					if (isUserOrContact(value)) {
						const alreadyAddedValueIndex = fields.findIndex((field) => field.userId === value.id);
						if (alreadyAddedValueIndex === -1) {
							append({
								userId: value.id,
								name: value.name,
								surname: value.surname,
								role: "attendee",
								profilePictureUrl: value.profileUrl,
								hasBeenAdded: true,
							});
							return;
						} else if (fields[alreadyAddedValueIndex].hasBeenDeleted) {
							update(alreadyAddedValueIndex, {
								...fields[alreadyAddedValueIndex],
								hasBeenDeleted: false,
							});

							return;
						}
					} else {
						if (value && isEmail(value.value)) {
							const alreadyAddedValueIndex = fields.findIndex((field) => field.email === value.value);
							if (alreadyAddedValueIndex === -1) {
								append({
									email: value.value,
									role: "attendee",
									hasBeenAdded: true,
								});
							} else if (fields[alreadyAddedValueIndex].hasBeenDeleted) {
								update(alreadyAddedValueIndex, {
									...fields[alreadyAddedValueIndex],
									hasBeenDeleted: false,
								});
							}
							return;
						}
						if (value && !isEmail(value.value)) {
							MLDialog.showSnackbar(t("global.invalidEmail"), { variant: "error" });
						}
					}
				}}
				className={"mb-1"}
				color="secondary"
				nameExtractor={(userOrContact) => ({
					name: isUserOrContact(userOrContact) ? userOrContact?.name ?? "" : userOrContact?.value ?? "",
					children: isUserOrContact(userOrContact) ? (
						<div className="flex items-center justify-start w-full gap-4">
							<img
								src={userOrContact?.profileUrl ? backendMediaUrlParser(userOrContact?.profileUrl) : avatarPlaceholder}
								alt={`${userOrContact?.name} avatar`}
								className="w-6 h-6 rounded-full"
							/>
							<div className="flex flex-col items-start justify-center text-start">
								<Typography
									children={`${userOrContact?.name} ${userOrContact?.surname}`}
									noWrap
									className="text-secondary !text-subtitle2"
									sx={{ width: "100%" }}
								/>
								<Typography
									className="text-secondary !text-caption"
									children={userOrContact?.email}
									noWrap
									sx={{ width: "100%" }}
								/>
							</div>
						</div>
					) : (
						<div className="flex items-center gap-4 text-secondary !text-subtitle2 p-1">
							<CertiblokIcon size={24} color={"secondary"} name={"mail_outline"} />
							{filter}
						</div>
					),
				})}
				placeholder={t("global.userPickerExample")}
				TextFieldProps={{
					className: ({ isFocusVisible, isHovered }) =>
						twMerge(
							clsx(
								"[&_.TextField-inputContainer]:rounded-xl [&_.TextField-inputContainer]:cursor-text [&_.TextField-inputContainer]:border-[1px] [&_.TextField-inputContainer]:border-solid [&_.TextField-inputContainer]:border-transparent [&_.TextField-inputContainer]:bg-[rgba(244,250,251,1)] [&_.TextField-inputContainer]:transition-all [&_.TextField-inputContainer]:h-12 [&_.TextField-inputContainer]:py-[14px] [&_.TextField-inputContainer]:px-3 [&_.TextField-inputContainer]:text-body1 [&_.TextField-inputContainer]:tracking-[0.25px] [&_.TextField-input]:placeholder:text-[rgba(0,0,0,0.87)] [&_.TextField-input]:placeholder:opacity-[0.42]",
								"[&_.TextField-label]:ml-0 [&_.TextField-label]:!text-label [&_.TextField-label]:pb-1 [&_.TextField-label]:leading-[16.37px] [&_.TextField-inputContainer]:transition-all",
								isHovered &&
									"[&_.TextField-inputContainer]:[filter:brightness(0.90)] [&_.TextField-inputContainer]:!shadow-none",
								isFocusVisible &&
									"[&_.TextField-inputContainer]:border-secondary [&_.TextField-inputContainer]:!shadow-[0_0px_0px_2px_rgba(0,0,0,0.3)] [&_.TextField-inputContainer]:!shadow-secondary-a38 [&_.TextField-focusRing]:hidden"
							)
						),
				}}
				OptionsListProps={{
					className: "z-[2000] !p-1 !shadow-8",
				}}
			/>
			<div className="flex-1 md:overflow-auto flex flex-col gap-1">
				{fields.map((field, index) => {
					if (field.hasBeenDeleted) return null;
					return (
						<div
							className="flex w-full box-border overflow-hidden max-w-full items-center gap-2 p-3 rounded-xl bg-secondary-a06"
							key={field.userId ?? field.email}
						>
							<div className="flex flex-col gap-2 md:flex-row flex-1 min-w-0 items-start md:items-center justify-between">
								<div className="flex items-center gap-2 flex-1 min-w-0 w-full">
									{field.userId ? (
										<>
											<img
												src={field?.profilePictureUrl ? backendMediaUrlParser(field?.profilePictureUrl) : avatarPlaceholder}
												alt={`${field?.name} avatar`}
												className="w-6 h-6 rounded-full"
											/>
											<Typography noWrap className="flex-1 min-w-0">
												{getFullName(field)}
											</Typography>
										</>
									) : (
										<>
											<CertiblokIcon size={24} color={"secondary"} name={"mail_outline"} />
											<Typography noWrap className="flex-1 min-w-0">
												{field.email}
											</Typography>
										</>
									)}
								</div>
								<CustomizationSelect
									value={field.role}
									onChange={(ev) => {
										const newValue = ev.target.value as AttendeeRoleEnum;
										update(index, {
											...field,
											role: newValue,
											hasBeenEdited: true,
										});
									}}
									sx={{
										flexShrink: 1,
										// minWidth: 140,
										"& .MuiSelect-select": {
											backgroundColor: "#E6F4F7",
											height: "40px !important",
											border: "unset",
											paddingLeft: 3,
											paddingRight: "40px !important",
										},
									}}
								>
									<MenuItem value={"attendee"}>
										<Typography variant="body2">{t("events.viewer")}</Typography>
									</MenuItem>
									<MenuItem value={"editor"}>
										<Typography variant="body2">{t("events.editor")}</Typography>
									</MenuItem>
								</CustomizationSelect>
							</div>
							<IconButton
								color="secondary"
								onClick={() => {
									update(index, {
										...field,
										hasBeenDeleted: true,
									});
								}}
							>
								<CertiblokIcon size={24} color="inherit" name={"canc"} />
							</IconButton>
						</div>
					);
				})}
			</div>
		</div>
	);
};
export default EditCreateEventSharedWith;
