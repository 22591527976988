/** @jsxImportSource @emotion/react */
import type { CSSObject } from "@emotion/react";
import { alpha, Box, Fade, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { CSSProperties, memo, useCallback, useContext, useEffect, useState } from "react";
import type { CalendarProps, CalendarTileProperties } from "react-calendar";
import Calendar from "react-calendar";
import { areEqual } from "react-window";
import { pxToRem } from "../../functions/theme";
import { useSelectedLanguage } from "../../hooks/useLanguageSwitcher";
import { CalendarContext } from "./controllers/CalendarContext";

interface Props extends CalendarProps {
	outerCss?: CSSObject;
	index?: number;
	style?: CSSProperties;
	disabled?: boolean;
	disabledBefore?: DateTime;
	prevSelectedDate?: DateTime;
}

const SingleCalendar: React.FC<Props> = memo(
	({ outerCss, index, style, disabled = false, disabledBefore, prevSelectedDate, ...props }) => {
		const theme = useTheme();
		const [shouldMount, setShouldMount] = useState(false);

		useEffect(() => {
			const timeout = setTimeout(() => {
				setShouldMount(true);
			}, 100);

			return () => clearTimeout(timeout);
		}, []);

		const calendarContext = useContext(CalendarContext);

		const isTileDisabled = useCallback(
			(tile: CalendarTileProperties) =>
				disabled ||
				(disabledBefore !== undefined
					? Math.ceil(DateTime.fromJSDate(tile.date).diff(disabledBefore, "days").days) < 0
					: Math.ceil(DateTime.fromJSDate(tile.date).diffNow("days").days) < 0),
			[disabledBefore, disabled]
		);

		const selectedLocale = useSelectedLanguage();

		return (
			<Box
				sx={{
					width: 335,
					padding: "0px 20px",
					boxSizing: "border-box",
					paddingTop: 3,
					paddingBottom: 4,
				}}
				style={style}
				css={outerCss}
			>
				<Typography
					children={
						index !== undefined
							? DateTime.now().plus({ months: index }).toFormat("MMMM yyyy", { locale: selectedLocale }).capitalize()
							: undefined
					}
					variant="subtitle1"
					sx={{ px: 1, paddingBottom: 4 }}
				/>

				<Fade in={shouldMount} mountOnEnter unmountOnExit>
					<Box>
						<Calendar
							key={calendarContext.shouldHideSelectedDate ? "hide" : "show"}
							locale={selectedLocale}
							value={calendarContext.shouldHideSelectedDate ? undefined : calendarContext.selectedDate.toJSDate()}
							onClickDay={disabled ? undefined : calendarContext.selectDate}
							tileDisabled={isTileDisabled}
							tileClassName={(tile) => {
								if (
									prevSelectedDate &&
									!isTileDisabled(tile) &&
									DateTime.fromJSDate(tile.date).startOf("day").equals(prevSelectedDate.startOf("day"))
								)
									return "react-calendar__tile__prevselected";
								return !isTileDisabled(tile) ? "react-calendar__tile" : "react-calendar__tile__disabled";
							}}
							showNavigation={false}
							activeStartDate={index !== undefined ? DateTime.now().plus({ months: index }).toJSDate() : undefined}
							css={{
								"& .react-calendar__month-view__days__day--weekend": {
									color: "black",
								},
								"& .react-calendar__tile": {
									padding: 0,
									flex: "unset !important",
									width: 42,
									height: 42,
									textAlign: "center",
									fontWeight: 400,
									fontSize: pxToRem(20),
									borderRadius: 26,
									borderStyle: "solid",
									backgroundColor: "unset",
									borderColor: "transparent",
									boxSizing: "border-box",
									cursor: "pointer",
									transition: theme.transitions.create(["background-color", "border-color", "color"]),
									"&:hover": {
										backgroundColor: "rgba(64, 205, 140, 0.4)",
									},
									"&--active": {
										backgroundColor: "rgba(64, 205, 140, 1) !important",
										color: "white",
										borderColor: "rgba(255, 255, 255, 0.38);",
									},
								},
								"& .react-calendar__tile__prevselected": {
									backgroundColor: theme.palette.secondary.main,
									color: "white",
								},
								"& .react-calendar__tile__disabled": {
									cursor: "unset",
									color: alpha("#000000", 0.4),
									"&:hover": {
										backgroundColor: "transparent",
									},
									"&--active": {
										backgroundColor: "transparent",
										borderColor: "transparend",
									},
								},
								"& .react-calendar__month-view__weekdays__weekday": {
									flex: "unset !important",
									width: 42,
									textAlign: "center",
									textTransform: "uppercase",
									fontWeight: 700,
									fontSize: pxToRem(14),
									color: "rgba(0, 0, 0, 0.38)",
									"abbr[title]": {
										textDecoration: "none",
									},
								},
								"& .react-calendar__month-view__days__day--neighboringMonth": {
									color: "rgba(0, 0, 0, 0.38)",
									backgroundColor: "transparent",
									borderColor: "transparent",
								},
								"& .react-calendar__month-view__weekdays": {
									paddingBottom: 16,
								},
								"& .react-calendar__month-view__days": {
									gap: "4px 0px",
								},
							}}
							showNeighboringMonth={false}
							{...props}
						/>
					</Box>
				</Fade>
			</Box>
		);
	},
	areEqual
);

export default SingleCalendar;
