"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttendeeRoleEnum = exports.AttendeeResponseStatusEnum = void 0;
exports.AttendeeResponseStatusEnum = {
    NeedsAction: 'needsAction',
    Declined: 'declined',
    Accepted: 'accepted',
    Deleted: 'deleted'
};
exports.AttendeeRoleEnum = {
    Owner: 'owner',
    Attendee: 'attendee',
    Editor: 'editor'
};
