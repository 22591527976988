import { cn } from "@mabi-ui/utils";
import { ButtonBase, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import { useBetterMediaQuery } from "../../../../utils/hooks/useBetterMediaQuery";
import { DashboardCardProps } from "../SmallCardsGrid/DashboardSmallCard";

const DashboardBigCard = ({
	children,
	icon,
	onClick,
	isDisabled,
	href,
}: Omit<DashboardCardProps, "color" | "count" | "isCountLoading"> & { isDisabled?: boolean }) => {
	const { t } = useTranslation();
	const isMd = useBetterMediaQuery("md");

	const [showAnimatedTooltip, setShowAnimatedTooltip] = useState(false);
	const [animationProgression, setAnimationProgression] = useState({ value: 0, direction: 1 });

	useEffect(() => {
		const editAnimation = () => {
			setAnimationProgression((prev) => {
				if (prev.value === 100) {
					return { value: 99, direction: -1 };
				}
				if (prev.value === 0) {
					return { value: 1, direction: 1 };
				}
				return { ...prev, value: prev.value + prev.direction * 1 };
			});
		};
		const int = setInterval(editAnimation, 15);

		return () => {
			clearInterval(int);
		};
	}, []);

	return (
		<ButtonBase
			onMouseEnter={() => setShowAnimatedTooltip(true)}
			onMouseLeave={() => setShowAnimatedTooltip(false)}
			className={cn(
				isDisabled
					? "bg-grey opacity-40 cursor-default hover:bg-[rgba(162,164,174,1)]"
					: "bg-secondary hover:bg-secondary/[0.92] active:bg-secondary/[0.86]",
				"group relative transition-all flex-1 self-stretch p-3 md:p-4 flex gap-4 md:rounded-2xl rounded-xl text-left justify-start items-center"
			)}
			onClick={() => {
				onClick?.();
			}}
			href={isDisabled ? "" : href ?? ""}
			disableRipple
		>
			<div className={cn(" p-2 flex items-center justify-center rounded-xl bg-white/[0.12] flex-shrink-0")}>
				<CertiblokIcon
					name={icon}
					className={cn("text-white", isDisabled && "group-hover:blur-[4px]")}
					size={isMd ? 40 : 32}
				/>
			</div>
			{typeof children === "string" ? (
				<Typography variant="subtitle1" className={cn("m-0 text-white flex-1", isDisabled && "group-hover:blur-[4px]")}>
					{children}
				</Typography>
			) : (
				children
			)}
			{isDisabled && (
				<div
					className={cn(
						"w-full text-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity opacity-0 duration-500",
						showAnimatedTooltip && "opacity-100"
					)}
				>
					<Typography
						variant="button"
						className={cn("text-[24px] tracking-[3px] leading-6 m-0  text-white font-bold ")}
						sx={{
							background: `radial-gradient(circle at ${animationProgression.value}%, #c8e8f0, #ffffff)`,
							backgroundClip: "text",
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
							/* animation: `${textEffect} 1s infinite`, */
						}}
					>
						{t("global.comingSoon")}
					</Typography>
				</div>
			)}
		</ButtonBase>
	);
};

export default DashboardBigCard;
