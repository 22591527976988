/** @jsxImportSource @emotion/react */
import type { CSSObject } from "@emotion/react";
import {
	alpha,
	Grid,
	Grow,
	LinearProgress,
	linearProgressClasses,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import queryString from "querystring";
import type React from "react";
import { useTranslation } from "react-i18next";
import { TABLE_TITLE_HEIGHT } from "../../constants/TableTitleHeight";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import BetterCheckbox from "../BetterCheckbox";
import CertiblokIcon from "../icons/CertiblokIcon";
import { useModalNavigator } from "../ModalNavigator/ModalNavigator";
import FullWidthEmptyCell from "./cells/FullWidthEmptyCell";
import SwitchViewMenu from "./cells/SwitchViewMenu";
import TableHeaderCell from "./cells/TableHeaderCell";
import TouchTargetCell from "./cells/TouchTargetCell";

export interface IHeadCellMenuItem {
	label: string;
	onClick: () => void;
}
export interface IHeadCell {
	id: string;
	label: string;
	subtitle?: string;
	notSelectable?: boolean;
	colSpan?: number;
	width?: number;
	menu?: IHeadCellMenuItem[];
}
export type Order = "asc" | "desc";

export interface FilterObject {
	[x: string]: any;
}

export interface IQueryFilter {
	page?: number;
	perPage?: number;
	order?: Order;
	orderBy?: string;
	filter?: FilterObject[];
	// filter?: string;
}

function AdminTable<T>(props: {
	data: T[] | undefined;
	queryFilter: [IQueryFilter, (value: IQueryFilter) => void];
	count: number;
	loading?: boolean;
	fetching?: boolean;
	headCells: IHeadCell[];
	children: (data: T[]) => any;
	occlusionHeight?: number;
	size?: "medium" | "small";
	fullWidth?: boolean;
	disablePagination?: boolean;
	color?: "primary" | "secondary";
	disableAllRowSelectable?: boolean;
	allRowSelected?: boolean;
	onSelectAllRow?: () => void;
	hasOption?: boolean;
	preContentSection?: React.ReactNode | React.ReactNode[];
	noDataComponent?: React.ReactNode;
	paperCss?: CSSObject;
	onPageChange?: (page: number) => void;
	hasSwitchViewMenu?: boolean;
	onSwitchView?: (view: "tree" | "table") => void;
}) {
	const { queryFilter, paperCss, onPageChange, hasSwitchViewMenu, onSwitchView } = props;

	const currentFilter = queryFilter[0];

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up("md"));

	const setFilter = (name: keyof IQueryFilter, value: any) => {
		const newFilter = { ...currentFilter };
		newFilter[name] = value;
		queryFilter[1](newFilter);
	};

	const { t } = useTranslation();

	const isSm = useBetterMediaQuery("sm");

	const { pushModal } = useModalNavigator();

	return (
		<Grow appear={false} in={true}>
			<Paper
				elevation={0}
				css={{
					minWidth: props?.fullWidth ? "100%" : "inherit",
					background: "transparent",
					height: `calc(100% - ${TABLE_TITLE_HEIGHT}px)`,
					...paperCss,
				}}
			>
				<TableContainer
					css={{
						width: "100%",
						overflowY: "auto",
						maxHeight: `calc(100% - ${56}px)`,
					}}
				>
					<Table stickyHeader size={props.size || "medium"} sx={{ tableLayout: "fixed", width: "100%" }}>
						<TableHead
							sx={{
								"& .MuiTableCell-root": {
									py: 2,
									px: { xs: 2, lg: 3 },
									"& .MuiTableCell-paddingCheckbox": {
										px: { xs: 1, lg: 2 },
									},
								},
							}}
						>
							<TableRow
								sx={{
									cursor: "unset",
									"&:hover": {
										backgroundColor: "unset !important",
										cursor: "unset !important",
									},
								}}
							>
								{!props.disableAllRowSelectable && (
									<TouchTargetCell
										css={{
											backgroundColor: "#E6F4F7",
											borderBottomLeftRadius: 16,
											"&.MuiTableCell-paddingCheckbox": {
												padding: "0px 8px",
											},
										}}
									>
										<BetterCheckbox
											checked={props.allRowSelected}
											onClick={() => {
												props.onSelectAllRow?.();
											}}
											size="small"
											sx={{ width: 32, height: 32 }}
											color={props.color ?? "primary"}
										/>
									</TouchTargetCell>
								)}
								{props.headCells?.map((headCell, index) => {
									if (headCell.colSpan === 0) return null; //custom rules to remove head cells
									return (
										<TableHeaderCell
											key={headCell.id}
											headCell={headCell}
											currentFilter={currentFilter}
											queryFilter={queryFilter}
											isBottomLeftRounded={index === 0 && props.disableAllRowSelectable}
											isBottomRightRounded={index === props.headCells.length - 1 && !props.hasOption && !hasSwitchViewMenu}
										/>
									);
								})}
								{props.hasOption && (
									<TableCell
										padding="checkbox"
										css={{
											width: 0,
											backgroundColor: "#E6F4F7",
											borderBottomRightRadius: 16,
										}}
									/>
								)}
								{hasSwitchViewMenu && (
									<TableCell
										padding="checkbox"
										css={{
											backgroundColor: "#E6F4F7",
											borderBottomRightRadius: 16,
										}}
									>
										<div className="flex items-center relative">
											<div
												className="flex items-center gap-1 absolute right-0 cursor-pointer"
												onClick={(ev) => {
													pushModal(SwitchViewMenu, {
														anchorEl: ev.currentTarget,
													}).then((view) => {
														onSwitchView?.(view);
													});
												}}
											>
												<CertiblokIcon size={24} color="black" name={"organize_outline"} />
												<CertiblokIcon size={16} color="black" name={"arrow_mini_outline_down"} />
											</div>
										</div>
									</TableCell>
								)}
							</TableRow>
						</TableHead>
						<TableBody
							sx={{
								"& .MuiTableCell-root": {
									padding: { xs: 2, lg: 3 },
									borderTop: `2px solid ${theme.palette.background.paper}`,
									borderBottom: `2px solid ${theme.palette.background.paper}`,
								},
								"& .MuiTableCell-paddingCheckbox": {
									px: { xs: 1, lg: 2 },
								},
							}}
						>
							{props.preContentSection}
							{(props.data ?? []).length > 0
								? props.children(props?.data || [])
								: !props.loading && (
										<FullWidthEmptyCell
											rowProps={{
												className: "disable-row-hover",
											}}
											children={props.noDataComponent}
											hasOption={props.hasOption ?? props.hasSwitchViewMenu ?? false}
											hasCheckbox={!props.disableAllRowSelectable}
											headCells={props.headCells}
										/>
								  )}
						</TableBody>
					</Table>
				</TableContainer>
				{(props.loading || props.fetching) && (
					<LinearProgress
						sx={{
							[`& .${linearProgressClasses.bar}`]: {
								borderRadius: 5,
								backgroundColor: `${theme.palette[props.color ?? "primary"].main} !important`,
							},
						}}
						color={props.color ?? "primary"}
						css={{ zIndex: 1000, top: 0, left: 0, right: 0 }}
					/>
				)}
				{!props?.disablePagination && (
					<TablePagination
						rowsPerPageOptions={[1, 5, 10, 25, 50]}
						labelRowsPerPage={t("adminTable.rowsPerPage")}
						css={{
							display: "flex",
							justifyContent: "flex-end",
							"& div:first-of-type": {
								maxWidth: "100%",
								flexWrap: "wrap",
							},
							"& .MuiTablePagination-displayedRows": {
								margin: 0,
							},
							"& .MuiTablePagination-selectLabel": {
								marginTop: 7,
								marginBottom: 7,
							},
							"& .MuiTablePagination-actions": {
								marginLeft: isMd ? 20 : "8px !important",
							},
						}}
						nextIconButtonProps={{
							className: "next-action",
							color: props.color || "primary",
							style: {
								background: "rgba(255,255,255, 0.2)",
							},
						}}
						backIconButtonProps={{
							className: "back-action",
							color: props.color || "primary",
							style: {
								background: "rgba(255,255,255, 0.2)",
							},
						}}
						labelDisplayedRows={({ from, to, count, page }) => {
							const rowsPerPage = currentFilter?.perPage || 10;
							const totalPages = Math.ceil(count / rowsPerPage);
							const maxPages = isSm ? 7 : 5;
							let deltaBounds = Math.floor(maxPages / 2);
							if (deltaBounds < 0) deltaBounds = 0;
							const remainingPages = totalPages > maxPages ? maxPages : totalPages;
							let lb = page > deltaBounds ? page - deltaBounds : 0;
							if (lb > totalPages - maxPages) lb = totalPages - maxPages;
							if (lb < 0) lb = 0;
							let ub = lb + remainingPages;
							if (ub > totalPages) ub = totalPages;
							const pages = [];
							for (let i = 0; i < ub; i++) {
								if (i >= lb) pages.push({ index: i, selected: i === page });
							}
							return (
								<span>
									<Grid
										component="span"
										container
										alignItems="center"
										spacing={2}
										css={{ flex: 1 }}
										justifyContent="space-between"
									>
										<Grid component="span" item>
											{isSm
												? t("adminTable.resultsFromTo", { from, to, count })
												: t("adminTable.rowsPerPageXs", { from, to, count })}
										</Grid>
										<Grid component="span" item>
											<Grid
												container
												component="span"
												css={{ height: 28, overflow: "visible", borderRadius: 16 }}
												alignItems="center"
											>
												{pages.map((p) => {
													return (
														<Grid
															component="span"
															key={"page-" + p.index}
															css={{
																marginTop: -2,
																height: 32,
																width: 32,
																borderRadius: 16,
																backgroundColor: p.selected ? theme.palette[props.color || "primary"].main : "transparent",
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
																transition: theme.transitions.create(["background-color", "color", "font-weight"]),
																cursor: p.selected ? "inherit" : "pointer",
																":hover": {
																	backgroundColor: p.selected
																		? theme.palette[props.color || "primary"].main
																		: alpha(theme.palette[props.color || "primary"].main, 0.2),
																},
															}}
															item
															onClick={() => {
																onPageChange?.(p.index);

																setFilter("page", p.index);
															}}
														>
															<Typography
																component="span"
																css={{
																	fontWeight: p.selected ? "bold" : "normal",
																	color: p.selected ? "white" : theme.palette.text.primary,
																	transition: theme.transitions.create(["background-color", "color", "font-weight"]),
																}}
																children={p.index + 1}
															/>
														</Grid>
													);
												})}
											</Grid>
										</Grid>
									</Grid>
								</span>
							);
						}}
						count={props?.count || 0}
						component="div"
						title={t("adminTable.rowsPerPage")}
						lang="it-IT"
						rowsPerPage={currentFilter?.perPage || 10}
						page={currentFilter?.page || 0}
						onPageChange={(event, newPage) => {
							setFilter("page", newPage);
						}}
						onRowsPerPageChange={(event) => {
							const newPerPageValue = parseInt(event.target.value);
							const perPage = currentFilter?.perPage || 10;
							const page = currentFilter?.page || 0;
							const oldIndex = page * perPage; // 10 | 0;
							const newPage = oldIndex / newPerPageValue;
							const newFilter = { ...currentFilter };
							newFilter["page"] = Math.floor(newPage);
							newFilter["perPage"] = newPerPageValue;
							queryFilter[1](newFilter);
						}}
					/>
				)}
			</Paper>
		</Grow>
	);
}

export default AdminTable;

export const defaultQueryFilter: IQueryFilter = {
	page: 0,
	perPage: 10,
	order: "asc",
	orderBy: "createdAt",
};

//! UTILITIES
export function transformToStringFrom(queryFilter: IQueryFilter): string {
	return queryString.stringify({
		...queryFilter,
		filter: JSON.stringify(queryFilter.filter),
	});
}

export function transformToObjectFrom(filter: string): IQueryFilter {
	const test = queryString.parse(filter);

	return {
		...defaultQueryFilter,
		...test,
		filter: JSON.parse((test.filter || "{}") as string),
	};
}

export function parseCountFrom(rawData: any): number {
	return parseInt(rawData?.headers?.count || 0);
}
