"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditApi = exports.AuditApiFactory = exports.AuditApiFp = exports.AuditApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AuditApi - axios parameter creator
 * @export
 */
const AuditApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"create\", \"Audit\"]
         * @summary Create audit room | Permission required: [\"create\", \"Audit\"]
         * @param {InputCreateAudit} [inputCreateAudit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuditRoom: (inputCreateAudit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/audit/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateAudit, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"delete\", \"Audit\"]
         * @summary Delete audit room | Permission required: [\"delete\", \"Audit\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditRoom: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('deleteAuditRoom', 'id', id);
            const localVarPath = `/audit/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"Audit\"]
         * @summary Edit audit room | Permission required: [\"update\", \"Audit\"]
         * @param {string} id
         * @param {InputEditAudit} [inputEditAudit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAuditRoom: (id, inputEditAudit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('editAuditRoom', 'id', id);
            const localVarPath = `/audit/{id}/edit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditAudit, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get documents to link
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsToLink: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/audit/documentsToLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Audit\"]
         * @summary Get documents with history for audit room | Permission required: [\"readOne\", \"Audit\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsWithHistoryForAuditRoom: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getDocumentsWithHistoryForAuditRoom', 'id', id);
            const localVarPath = `/audit/{id}/documentsWithHistory`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Audit\"]
         * @summary Get paginated documents for audit room | Permission required: [\"readOne\", \"Audit\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedDocumentsForAuditRoom: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getPaginatedDocumentsForAuditRoom', 'id', id);
            const localVarPath = `/audit/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List archived audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArchivedAuditRooms: (filter, type, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/audit/archive/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List archived audit rooms with same name | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArchivedAuditRoomsWithSameName: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('listArchivedAuditRoomsWithSameName', 'id', id);
            const localVarPath = `/audit/archive/{id}/by-name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuditRooms: (filter, type, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/audit/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List only owner audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOnlyOwnerAuditRooms: (filter, type, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/audit/list-owner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List opened audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOpenedAuditRooms: (filter, type, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/audit/list-opened`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Notify for video conference
         * @param {string} id
         * @param {InputNotifyForVideoConference} [inputNotifyForVideoConference]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyForVideoConference: (id, inputNotifyForVideoConference, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('notifyForVideoConference', 'id', id);
            const localVarPath = `/audit/{id}/notifyForVideoConference`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputNotifyForVideoConference, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Audit\"]
         * @summary Single audit room | Permission required: [\"readOne\", \"Audit\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleAuditRoom: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('singleAuditRoom', 'id', id);
            const localVarPath = `/audit/{id}/single`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.AuditApiAxiosParamCreator = AuditApiAxiosParamCreator;
/**
 * AuditApi - functional programming interface
 * @export
 */
const AuditApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.AuditApiAxiosParamCreator)(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Audit\"]
         * @summary Create audit room | Permission required: [\"create\", \"Audit\"]
         * @param {InputCreateAudit} [inputCreateAudit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuditRoom(inputCreateAudit, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createAuditRoom(inputCreateAudit, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"delete\", \"Audit\"]
         * @summary Delete audit room | Permission required: [\"delete\", \"Audit\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditRoom(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAuditRoom(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"Audit\"]
         * @summary Edit audit room | Permission required: [\"update\", \"Audit\"]
         * @param {string} id
         * @param {InputEditAudit} [inputEditAudit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAuditRoom(id, inputEditAudit, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editAuditRoom(id, inputEditAudit, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get documents to link
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsToLink(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDocumentsToLink(filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Audit\"]
         * @summary Get documents with history for audit room | Permission required: [\"readOne\", \"Audit\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsWithHistoryForAuditRoom(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDocumentsWithHistoryForAuditRoom(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Audit\"]
         * @summary Get paginated documents for audit room | Permission required: [\"readOne\", \"Audit\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedDocumentsForAuditRoom(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPaginatedDocumentsForAuditRoom(id, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List archived audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArchivedAuditRooms(filter, type, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listArchivedAuditRooms(filter, type, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List archived audit rooms with same name | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArchivedAuditRoomsWithSameName(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listArchivedAuditRoomsWithSameName(id, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuditRooms(filter, type, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listAuditRooms(filter, type, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List only owner audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOnlyOwnerAuditRooms(filter, type, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listOnlyOwnerAuditRooms(filter, type, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List opened audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOpenedAuditRooms(filter, type, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listOpenedAuditRooms(filter, type, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Notify for video conference
         * @param {string} id
         * @param {InputNotifyForVideoConference} [inputNotifyForVideoConference]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyForVideoConference(id, inputNotifyForVideoConference, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.notifyForVideoConference(id, inputNotifyForVideoConference, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Audit\"]
         * @summary Single audit room | Permission required: [\"readOne\", \"Audit\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleAuditRoom(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.singleAuditRoom(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.AuditApiFp = AuditApiFp;
/**
 * AuditApi - factory interface
 * @export
 */
const AuditApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.AuditApiFp)(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Audit\"]
         * @summary Create audit room | Permission required: [\"create\", \"Audit\"]
         * @param {InputCreateAudit} [inputCreateAudit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuditRoom(inputCreateAudit, options) {
            return localVarFp.createAuditRoom(inputCreateAudit, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"delete\", \"Audit\"]
         * @summary Delete audit room | Permission required: [\"delete\", \"Audit\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditRoom(id, options) {
            return localVarFp.deleteAuditRoom(id, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"Audit\"]
         * @summary Edit audit room | Permission required: [\"update\", \"Audit\"]
         * @param {string} id
         * @param {InputEditAudit} [inputEditAudit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAuditRoom(id, inputEditAudit, options) {
            return localVarFp.editAuditRoom(id, inputEditAudit, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get documents to link
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsToLink(filter, options) {
            return localVarFp.getDocumentsToLink(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Audit\"]
         * @summary Get documents with history for audit room | Permission required: [\"readOne\", \"Audit\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsWithHistoryForAuditRoom(id, options) {
            return localVarFp.getDocumentsWithHistoryForAuditRoom(id, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Audit\"]
         * @summary Get paginated documents for audit room | Permission required: [\"readOne\", \"Audit\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedDocumentsForAuditRoom(id, filter, options) {
            return localVarFp.getPaginatedDocumentsForAuditRoom(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List archived audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArchivedAuditRooms(filter, type, options) {
            return localVarFp.listArchivedAuditRooms(filter, type, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List archived audit rooms with same name | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArchivedAuditRoomsWithSameName(id, filter, options) {
            return localVarFp.listArchivedAuditRoomsWithSameName(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuditRooms(filter, type, options) {
            return localVarFp.listAuditRooms(filter, type, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List only owner audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOnlyOwnerAuditRooms(filter, type, options) {
            return localVarFp.listOnlyOwnerAuditRooms(filter, type, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Audit\"]
         * @summary List opened audit rooms | Permission required: [\"readAll\", \"Audit\"]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'internal' | 'external'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOpenedAuditRooms(filter, type, options) {
            return localVarFp.listOpenedAuditRooms(filter, type, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Notify for video conference
         * @param {string} id
         * @param {InputNotifyForVideoConference} [inputNotifyForVideoConference]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyForVideoConference(id, inputNotifyForVideoConference, options) {
            return localVarFp.notifyForVideoConference(id, inputNotifyForVideoConference, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Audit\"]
         * @summary Single audit room | Permission required: [\"readOne\", \"Audit\"]
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singleAuditRoom(id, options) {
            return localVarFp.singleAuditRoom(id, options).then((request) => request(axios, basePath));
        },
    };
};
exports.AuditApiFactory = AuditApiFactory;
/**
 * AuditApi - object-oriented interface
 * @export
 * @class AuditApi
 * @extends {BaseAPI}
 */
class AuditApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"create\", \"Audit\"]
     * @summary Create audit room | Permission required: [\"create\", \"Audit\"]
     * @param {InputCreateAudit} [inputCreateAudit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    createAuditRoom(inputCreateAudit, options) {
        return (0, exports.AuditApiFp)(this.configuration).createAuditRoom(inputCreateAudit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"delete\", \"Audit\"]
     * @summary Delete audit room | Permission required: [\"delete\", \"Audit\"]
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    deleteAuditRoom(id, options) {
        return (0, exports.AuditApiFp)(this.configuration).deleteAuditRoom(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"Audit\"]
     * @summary Edit audit room | Permission required: [\"update\", \"Audit\"]
     * @param {string} id
     * @param {InputEditAudit} [inputEditAudit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    editAuditRoom(id, inputEditAudit, options) {
        return (0, exports.AuditApiFp)(this.configuration).editAuditRoom(id, inputEditAudit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get documents to link
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    getDocumentsToLink(filter, options) {
        return (0, exports.AuditApiFp)(this.configuration).getDocumentsToLink(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Audit\"]
     * @summary Get documents with history for audit room | Permission required: [\"readOne\", \"Audit\"]
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    getDocumentsWithHistoryForAuditRoom(id, options) {
        return (0, exports.AuditApiFp)(this.configuration).getDocumentsWithHistoryForAuditRoom(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Audit\"]
     * @summary Get paginated documents for audit room | Permission required: [\"readOne\", \"Audit\"]
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    getPaginatedDocumentsForAuditRoom(id, filter, options) {
        return (0, exports.AuditApiFp)(this.configuration).getPaginatedDocumentsForAuditRoom(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Audit\"]
     * @summary List archived audit rooms | Permission required: [\"readAll\", \"Audit\"]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {'internal' | 'external'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    listArchivedAuditRooms(filter, type, options) {
        return (0, exports.AuditApiFp)(this.configuration).listArchivedAuditRooms(filter, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Audit\"]
     * @summary List archived audit rooms with same name | Permission required: [\"readAll\", \"Audit\"]
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    listArchivedAuditRoomsWithSameName(id, filter, options) {
        return (0, exports.AuditApiFp)(this.configuration).listArchivedAuditRoomsWithSameName(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Audit\"]
     * @summary List audit rooms | Permission required: [\"readAll\", \"Audit\"]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {'internal' | 'external'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    listAuditRooms(filter, type, options) {
        return (0, exports.AuditApiFp)(this.configuration).listAuditRooms(filter, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Audit\"]
     * @summary List only owner audit rooms | Permission required: [\"readAll\", \"Audit\"]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {'internal' | 'external'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    listOnlyOwnerAuditRooms(filter, type, options) {
        return (0, exports.AuditApiFp)(this.configuration).listOnlyOwnerAuditRooms(filter, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Audit\"]
     * @summary List opened audit rooms | Permission required: [\"readAll\", \"Audit\"]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {'internal' | 'external'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    listOpenedAuditRooms(filter, type, options) {
        return (0, exports.AuditApiFp)(this.configuration).listOpenedAuditRooms(filter, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Notify for video conference
     * @param {string} id
     * @param {InputNotifyForVideoConference} [inputNotifyForVideoConference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    notifyForVideoConference(id, inputNotifyForVideoConference, options) {
        return (0, exports.AuditApiFp)(this.configuration).notifyForVideoConference(id, inputNotifyForVideoConference, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Audit\"]
     * @summary Single audit room | Permission required: [\"readOne\", \"Audit\"]
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    singleAuditRoom(id, options) {
        return (0, exports.AuditApiFp)(this.configuration).singleAuditRoom(id, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AuditApi = AuditApi;
