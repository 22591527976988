import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAbility } from "./useAbilityRules";

export function useRegisterCompanyCheck() {
	const navigate = useNavigate();
	const location = useLocation();
	const { ability } = useAbility();

	const from = (location.state as any)?.from?.pathname || "/";

	const isRegisterButNotCompletely = ability.can("readOne", "User") && ability.cannot("readOne", "Company");
	const isRegisteredAndValid = ability.can("readOne", "User") && ability.can("readOne", "Company");

	const hasAskedParticipationButNotAccepted =
		ability.can("readOne", "ParticipationCompany") && ability.cannot("readOne", "Company");

	useEffect(() => {
		// if (isRegisterButNotCompletely && !location.pathname.includes("company")) {
		// 	navigate(me?.enabledTfa ? "/register/company" : "/register/enable-two-factor", { replace: true });
		// }
		if (isRegisterButNotCompletely) {
			navigate("/register/company", { replace: true });
		}

		if (isRegisteredAndValid) {
			navigate(from, { replace: true });
		}

		if (hasAskedParticipationButNotAccepted) {
			navigate("/register/success", { replace: true });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRegisterButNotCompletely, isRegisteredAndValid, hasAskedParticipationButNotAccepted]);
}
